import React, { useEffect, useState, useCallback } from "react";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { View, Text, ImageBackground, StyleSheet } from "react-native";
import { onSnapshot, doc, getFirestore } from "firebase/firestore";
import CookiePolicyPopup from "../Screens/Helpers/CookiePolicyPopup";
import AnalyticsService from '../services/AnalyticsService';

import Landing from "../Screens/Landing";
import Profile from "../Screens/Profile";
import Hub from "../Screens/Hub";
import Admin from "../Screens/Admin";
import Events from "../Screens/Events";
import Registry from "../Screens/Registry";
import Catalogue from "../Screens/RegistryWidgets/Catalogue";
import Privacy from "../Screens/Helpers/Privacy";
import Terms from "../Screens/Helpers/Terms";
import Checklist from "../Screens/Helpers/Checklist";
import VerifyEmail from "../Screens/VerifyEmail";
import Blog from "../Screens/Blog";
import Tutorials from "../Screens/TutorialsScreen";
import WhyMyTassel from "../Screens/WhyMyTassel";
import ContactUs from "../Screens/ContactUs";

const Stack = createStackNavigator();
const linking = {
	prefixes: ["https://mytassel.com", "mytassel://", "http://localhost:"],
	config: {
		screens: {
			"My Tassel - Welcome": "",
			Hub: "hub",
			Profile: "profile",
			Admin: "admin",
			Blog: "blog",
			Tutorials: "tutorials",
			Catalogue: "catalogue",
			Privacy: "privacy",
			Terms: "terms",
			ContactUs: "contact-us",
			Checklist: {
				path: "checklist/:type",
				parse: {
					type: (type) => `${type}`,
				},
			},
			Announcements: {
				path: "events/:uid",
				parse: {
					uid: (uid) => `${uid}`,
				},
			},
			Registry: {
				path: "registry/:uid",
				parse: {
					uid: (uid) => `${uid}`,
				},
			},
			VerifyEmail: {
				path: "verify-email",
			},
		},
	},
};

const Navigation = () => {
	const [isMaintenance, setIsMaintenance] = useState(false);
	const [maintenanceStartTime, setMaintenanceStartTime] = useState(null);
	const [currentRoute, setCurrentRoute] = useState("My Tassel - Welcome");
	const [isReady, setIsReady] = useState(false);
	const [maintenanceMode, setMaintenanceMode] = useState(false);
	const [disableRegistration, setDisableRegistration] = useState(false);
	const [showCookiePopup, setShowCookiePopup] = useState(false);

	const db = getFirestore();

	const isProduction = process.env.NODE_ENV === 'production';

	// Initialize analytics
	useEffect(() => {
		// Initialize analytics service
		AnalyticsService.initAnalytics();
	}, []);

	// Check for maintenance mode
	useEffect(() => {
		const unsubscribe = onSnapshot(doc(db, "settings", "maintenance"), (doc) => {
			if (doc.exists()) {
				const data = doc.data();
				setMaintenanceMode(data.enabled || false);
				setDisableRegistration(data.disableRegistration || false);
			}
		});

		return () => unsubscribe();
	}, []);

	// Check for cookie consent
	useEffect(() => {
		const cookieConsent = localStorage.getItem("cookieConsent");
		if (!cookieConsent) {
			setShowCookiePopup(true);
		}
	}, []);

	useEffect(() => {
		const statusDocRef = doc(db, "maintenance", "status");
		const unsubscribe = onSnapshot(statusDocRef, (doc) => {
			if (doc.exists()) {
				setIsMaintenance(doc.data().isMaintenanceActive);
				setMaintenanceStartTime(doc.data().startTime);
			}
		});
		return () => unsubscribe();
	}, []);

	useEffect(() => {
		const initializeNavigation = async () => {
			setIsReady(true);
		};

		initializeNavigation();
	}, []);

	const handleStateChange = useCallback((state) => {
		const currentRouteName = state?.routes[state.index]?.name || 'Unknown';
		
		// Track page view
		AnalyticsService.trackPageView(currentRouteName);
		AnalyticsService.incrementPageViewCount();

		if (currentRouteName) {
			setCurrentRoute(currentRouteName);
		}
	}, []);

	if (!isReady) {
		return null;
	}

	return (
		<NavigationContainer linking={linking} onStateChange={handleStateChange}>
			{isProduction && isMaintenance && currentRoute !== "Admin" && (
				<ImageBackground source={require("../assets/Landing Background.png")} style={styles.backgroundImage}>
					<View style={styles.maintenanceMessage}>
						<Text style={styles.maintenanceText}>
							{maintenanceStartTime
								? `The site is currently undergoing a scheduled maintenance as of ${maintenanceStartTime}. Please check back later.`
								: "The site is currently undergoing a scheduled maintenance. Please check back later."
							}
						</Text>
					</View>
				</ImageBackground>
			)}
			<Stack.Navigator
				initialRouteName="My Tassel - Welcome"
				screenOptions={{
					headerShown: false
				}}
			>
				<Stack.Screen name="My Tassel - Welcome" component={Landing} />
	
				{(!isProduction || !isMaintenance) && (
					<>
						<Stack.Screen name="Hub" component={Hub} />
						<Stack.Screen name="Profile" component={Profile} />
						<Stack.Screen 
							name="Announcements" 
							component={Events} 
							initialParams={{ uid: "" }} 
						/>
						<Stack.Screen 
							name="Registry" 
							component={Registry} 
							initialParams={{ uid: "" }} 
						/>
						<Stack.Screen name="Catalogue" component={Catalogue} />
						<Stack.Screen 
							name="Checklist" 
							component={Checklist} 
							initialParams={{ type: undefined }} 
						/>
						<Stack.Screen name="WhyMyTassel" component={WhyMyTassel} />
						<Stack.Screen name="Privacy" component={Privacy} />
						<Stack.Screen name="Terms" component={Terms} />
						<Stack.Screen name="VerifyEmail" component={VerifyEmail} />
						<Stack.Screen name="Tutorials" component={Tutorials} />
						<Stack.Screen name="Blog" component={Blog} />
						<Stack.Screen name="ContactUs" component={ContactUs} />
					</>
				)}
				<Stack.Screen name="Admin" component={Admin} />
			</Stack.Navigator>
			{showCookiePopup && <CookiePolicyPopup onAccept={() => setShowCookiePopup(false)} />}
		</NavigationContainer>
	);
};

const styles = StyleSheet.create({
	backgroundImage: {
		flex: 1,
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		zIndex: 1000,
	},
	maintenanceMessage: {
		padding: 20,
		backgroundColor: 'yellow',
	},
	maintenanceText: {
		fontWeight: 'bold',
	},
});

export default Navigation;
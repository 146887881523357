import React, { useState } from "react";
import { REACT_APP_AWS_SERVER_URL } from '@env';	
import SharedModal from '../Widgets/SharedModal';
import { getFirestore, collection, doc, updateDoc, getDoc, setDoc, arrayUnion } from "firebase/firestore";
import { app } from "../firebase";
import { useUser } from "../Widgets/UserContext";

const db = getFirestore(app);

const Share = ({ pageOwner, pageType }) => {
	const [recipients, setRecipients] = useState("");
	const [isShared, setIsShared] = useState(false);
	const [isSharing, setIsSharing] = useState(false); // To track when sharing is in progress
	const [showShareOptions, setShowShareOptions] = useState(false);
	const [hoveredButton, setHoveredButton] = useState(null);
	const [recipientType, setRecipientType] = useState("email"); // "email" or "phone"
	const [phoneNumbers, setPhoneNumbers] = useState("");
	const [error, setError] = useState("");
	const [isError, setIsError] = useState(false);
	const [showEmailWarning, setShowEmailWarning] = useState(false);
	const { currentUser, currentUserData } = useUser();
	var eventsPageUrl = pageOwner.pages.events;
	var registryPageUrl = pageOwner.pages.registry;

	let LOCAL_SERVER_URL = "http://localhost:3300";
	let AWS_SERVER_URL = REACT_APP_AWS_SERVER_URL;

	const isDevelopment = process.env.NODE_ENV === "development";
	const SERVER_URL = isDevelopment ? LOCAL_SERVER_URL : AWS_SERVER_URL;

	const handleRecipientsChange = (e) => {
		setError(""); // Clear any previous errors
		setIsError(false); 
		if (recipientType === "email") {
			setRecipients(e.target.value);
		} else {
			setPhoneNumbers(e.target.value);
		}
	};

	const handleShare = async () => {
		if (recipientType === "email") {
			setShowEmailWarning(true); // Show warning first
		} else {
			// SMS is coming soon, no action needed
		}
	};

	const trackShare = async (shareData) => {
		try {
			if (!currentUser || !currentUserData) {
				// If no user is logged in, just proceed without tracking
				return;
			}

			// Use email as document ID and include more user information
			const shareAnalyticsRef = doc(db, "shareAnalytics", currentUserData.email.toLowerCase());
			const shareAnalyticsDoc = await getDoc(shareAnalyticsRef);
			
			const newShare = {
				recipient: shareData.recipients.join(", "),
				date: new Date().toISOString(),
				type: shareData.type,
				sharedPageType: pageType || 'announcement',
				pageOwnerUid: pageOwner.uid,
				senderName: `${currentUserData.firstName} ${currentUserData.lastName}`,
				senderEmail: currentUserData.email.toLowerCase()
			};

			if (shareAnalyticsDoc.exists()) {
				const currentData = shareAnalyticsDoc.data();
				await updateDoc(shareAnalyticsRef, {
					totalShares: (currentData.totalShares || 0) + 1,
					[`${shareData.type}Shares`]: arrayUnion(newShare),
					lastShareDate: new Date().toISOString(),
					userId: currentUser.uid,
					userEmail: currentUserData.email.toLowerCase(),
					firstName: currentUserData.firstName,
					lastName: currentUserData.lastName
				});
			} else {
				await setDoc(shareAnalyticsRef, {
					userId: currentUser.uid,
					userEmail: currentUserData.email.toLowerCase(),
					firstName: currentUserData.firstName,
					lastName: currentUserData.lastName,
					totalShares: 1,
					emailShares: shareData.type === 'email' ? [newShare] : [],
					smsShares: shareData.type === 'sms' ? [newShare] : [],
					lastShareDate: new Date().toISOString()
				});
			}

			console.log(`Successfully tracked ${shareData.type} share for ${pageType || 'announcement'} page`);
		} catch (error) {
			console.error("Error tracking share:", error);
		}
	};

	const handleShareViaEmail = async () => {
		setIsSharing(true);
		const recipientEmails = recipients.split(",").map((email) => email.trim());
		try {
			const response = await fetch(`${SERVER_URL}/share-user-pages`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					recipients: recipientEmails,
					senderFirstName: pageOwner.firstName,
					senderLastName: pageOwner.lastName,
					eventsPageUrl,
					registryPageUrl,
					uic: pageOwner.uic,
				}),
			});

			const data = await response.json();

			if (response.ok) {
				// Track share analytics
				await trackShare({
					type: 'email',
					recipients: recipientEmails
				});

				if (data.errors && data.errors.length > 0) {
					// Some emails failed
					if (data.results && data.results.length > 0) {
						setIsShared(true);
						setError(`${data.results.length} email(s) sent successfully, but ${data.errors.length} failed. Please check the email addresses and try again for the failed ones.`);
					} else {
						setError("Failed to send any emails. Please check the addresses and try again.");
					}
					setIsError(true);
				} else {
					// All emails sent successfully
					setIsShared(true);
					setIsError(false);
					setError("");
					setShowShareOptions(false);
					setRecipients("");
					alert("Invitations sent successfully!");
				}
			} else {
				setError(data.error || "Failed to send invitations. Please try again.");
				setIsError(true);
			}
		} catch (error) {
			console.error("Error:", error);
			setError("An error occurred while sending invitations. Please try again.");
			setIsError(true);
		} finally {
			setIsSharing(false);
		}
	};

	const handleShareViaSMS = async (phoneList) => {
		try {
			// Basic phone number validation before sending
			const validPhoneList = phoneList.filter(phone => {
				const cleaned = phone.replace(/\D/g, '');
				return cleaned.length >= 10 && cleaned.length <= 11;
			});

			if (validPhoneList.length === 0) {
				setError("Please enter valid phone numbers (e.g., 123-456-7890)");
				setIsError(true);
				return;
			}

			const response = await fetch(`${SERVER_URL}/share-via-sms`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					recipients: validPhoneList,
					senderFirstName: pageOwner.firstName,
					senderLastName: pageOwner.lastName,
					eventsPageUrl,
					registryPageUrl,
					uic: pageOwner.uic,
				}),
			});

			const data = await response.json();

			if (response.ok) {
				// Track share analytics
				await trackShare({
					type: 'sms',
					recipients: validPhoneList
				});

				if (data.errors && data.errors.length > 0) {
					// Some messages failed
					if (data.results && data.results.length > 0) {
						setIsShared(true);
						setError(`${data.results.length} message(s) sent successfully, but ${data.errors.length} failed. Please check the phone numbers and try again for the failed ones.`);
					} else {
						setError("Failed to send messages. Please check the phone numbers and try again.");
					}
					setIsError(true);
				} else {
					// All messages sent successfully
					setIsShared(true);
					setIsError(false);
					setError("");
					setShowShareOptions(false);
					setPhoneNumbers("");
				}
			} else {
				setError(data.error || "Failed to send SMS invitations. Please try again.");
				setIsError(true);
			}
		} catch (error) {
			console.error("Error:", error);
			setError("An error occurred while sending invitations. Please try again.");
			setIsError(true);
		}
	};

	const handleCopyLink = () => {
		let urlToCopy = pageType === "Registry" ? registryPageUrl : eventsPageUrl;

		if (pageOwner.isFriendsListPrivate) {
			urlToCopy = urlToCopy + (pageOwner.uic ? `?uic=${pageOwner.uic}` : '');
		} 

		navigator.clipboard.writeText(urlToCopy)
			.then(() => alert("Link copied to clipboard!"))
			.catch((err) => console.error("Could not copy link: ", err));

		setShowShareOptions(false); // Hide the share options
	};

	const toggleShareOptions = () => {
		if (!isSharing) { // Prevent toggling while sharing is in progress
			setShowShareOptions(!showShareOptions);
			setIsShared(false); // Reset share status if user wants to share again
		}
	};

	return (
		<div style={{ marginTop: "80px" }}>
			{!showShareOptions && (
				<button 
					onClick={toggleShareOptions} 
					style={{
						...styles.eventsButton,
						...(hoveredButton === 'share' && styles.eventsButtonHover)
					}}
					onMouseEnter={() => setHoveredButton('share')}
					onMouseLeave={() => setHoveredButton(null)}
				>
					<div style={styles.buttonText}>
						<p style={{ margin: 0 }}>Share {pageOwner.firstName}'s</p>
						<p style={{ margin: 0 }}>{pageType} Page</p>
					</div>
				</button>
			)}
			
			{showShareOptions && (
				<>
					{isSharing && <p style={styles.successMessage}>Sending invitations...</p>}
					{isShared && !isError && <p style={styles.successMessage}>Shared successfully!</p>}
					{isError && <p style={styles.errorMessage}>{error}</p>}
					
					<div style={styles.recipientTypeContainer}>
						<button 
							onClick={() => {
								setRecipientType("email");
								setError("");
								setIsError(false);
							}}
							style={{
								...styles.typeButton,
								...(recipientType === "email" ? styles.activeTypeButton : {})
							}}
						>
							Email
						</button>
						<button 
							onClick={() => {
								setRecipientType("phone");
								setError("");
								setIsError(false);
							}}
							style={{
								...styles.typeButton,
								...(recipientType === "phone" ? styles.activeTypeButton : {})
							}}
						>
							SMS
						</button>
					</div>

					{recipientType === "email" ? (
						<>
							<input 
								type="text" 
								placeholder="Enter recipient emails, separated by commas"
								value={recipients}
								onChange={handleRecipientsChange}
								style={{
									...styles.input,
									...(isError && styles.inputError)
								}}
							/>
							<div style={styles.buttonContainer}>
								<button 
									onClick={handleShare} 
									style={styles.button} 
									disabled={isSharing || !recipients.trim()}
								>
									Share via Email
								</button>
								<button onClick={handleCopyLink} style={styles.button}>
									Copy Page Link
								</button>
							</div>
						</>
					) : (
						<>
							<input 
								type="text" 
								placeholder="Enter phone numbers, separated by commas"
								value={phoneNumbers}
								onChange={handleRecipientsChange}
								style={{
									...styles.input,
									...(isError && styles.inputError)
								}}
							/>
							<div style={styles.buttonContainer}>
								<button 
									onClick={() => {
										const phoneList = phoneNumbers.split(',').map(phone => phone.trim());
										handleShareViaSMS(phoneList);
									}} 
									style={styles.button} 
									disabled={isSharing || !phoneNumbers.trim()}
								>
									Share via SMS
								</button>
								<button onClick={handleCopyLink} style={styles.button}>
									Copy Page Link
								</button>
							</div>
						</>
					)}
				</>
			)}

			<SharedModal 
				isVisible={showEmailWarning}
				onClose={() => setShowEmailWarning(false)}
				title="Heads Up! Check That Junk Folder!"
				message="Sometimes our emails like to play hide and seek and might end up in a spam or junk folder! Let your friends and family know to give that folder a quick peek. We promise we're not spam - just here to celebrate your big moment!"
				confirmText="Got it, Send Email"
				onConfirm={() => {
					setShowEmailWarning(false);
					handleShareViaEmail();
				}}
			/>
		</div>
	);
};

// Styles (including your initial styles for eventsButton and eventsButtonText)
const styles = {
	eventsButton: {
		backgroundColor: "#fefde2",
		width: 284,
		height: 55,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		borderRadius: 40,
		border: "none",
		cursor: "pointer",
		transition: 'all 1s ease',
	},
	eventsButtonHover: {
		backgroundColor: "#e6e5cb",
		transform: 'scale(1.025)',
	},
	input: {
		margin: "10px 0",
		padding: "10px",
		borderRadius: "5px",
		border: "1px solid #ccc",
		width: "100%",
		maxWidth: 500,
	},
	buttonContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	button: {
		margin: "5px",
		padding: "10px 20px",
		borderRadius: "5px",
		cursor: "pointer",
		backgroundColor: "#4c956c",
		color: "white",
		border: "none",
	},
	buttonText: {
		fontFamily: "Orpheus Pro",
		fontWeight: "500",
		fontSize: 20,
		lineHeight: "1.2",
		textAlign: "center",
		color: "#4c956c",
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.09)",
	},
	successMessage: {
		color: "#fefde2",
		marginTop: "20px",
	},
	recipientTypeContainer: {
		display: "flex",
		justifyContent: "center",
		gap: "10px",
		marginBottom: "10px",
	},
	typeButton: {
		padding: "8px 16px",
		border: "1px solid #4c956c",
		borderRadius: "20px",
		background: "white",
		color: "#4c956c",
		cursor: "pointer",
	},
	activeTypeButton: {
		background: "#4c956c",
		color: "white",
	},
	errorMessage: {
		color: "#ef4444",
		marginTop: "10px",
		fontSize: "14px",
		textAlign: "center",
	},
	inputError: {
		borderColor: "#ef4444",
		backgroundColor: "#fef2f2",
	},
	comingSoonContainer: {
		textAlign: 'center',
		padding: '20px',
		backgroundColor: '#fefde2',
		borderRadius: '10px',
		margin: '20px 0',
	},
	comingSoonText: {
		fontSize: '24px',
		color: '#4c956c',
		fontWeight: 'bold',
		marginBottom: '10px',
	},
	comingSoonSubtext: {
		fontSize: '16px',
		color: '#666',
	},
};

export default Share;

import { getFirestore, collection, addDoc, query, where, getDocs, orderBy, limit, Timestamp, doc, getDoc, setDoc, updateDoc, increment } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { logAnalyticsEvent, setAnalyticsUserId } from '../firebase';

const db = getFirestore();
const auth = getAuth();

// Collection names
const ANALYTICS_COLLECTION = 'analytics';
const SESSIONS_COLLECTION = 'sessions';
const PAGE_VIEWS_COLLECTION = 'pageViews';
const REGISTRY_ITEMS_COLLECTION = 'registryItems';

// Event types
export const EVENT_TYPES = {
  PAGE_VIEW: 'page_view',
  USER_SIGNUP: 'user_signup',
  USER_LOGIN: 'user_login',
  SESSION_START: 'session_start',
  SESSION_END: 'session_end',
  REGISTRY_ITEM_ADDED: 'registry_item_added',
  REGISTRY_ITEM_VIEWED: 'registry_item_viewed',
  REGISTRY_ITEM_PURCHASED: 'registry_item_purchased',
};

// Initialize session data
let currentSession = null;
let sessionStartTime = null;
let lastActivityTime = null;
let pageViewCount = 0;
let currentUser = null;

// Session timeout (30 minutes of inactivity)
const SESSION_TIMEOUT = 30 * 60 * 1000;

// Initialize analytics
export const initAnalytics = () => {
  try {
    // Listen for auth state changes
    onAuthStateChanged(auth, (user) => {
      try {
        if (user) {
          currentUser = user;
          try {
            setAnalyticsUserId(user.uid);
          } catch (e) {
            console.log('Error setting analytics user ID:', e);
          }
          
          // Log user login event
          try {
            logAnalyticsEvent(EVENT_TYPES.USER_LOGIN, {
              userId: user.uid,
              email: user.email,
            });
          } catch (e) {
            console.log('Error logging user login event:', e);
          }
          
          // Start a new session if none exists
          if (!currentSession) {
            try {
              startSession(user.uid);
            } catch (e) {
              console.log('Error starting session for authenticated user:', e);
              // Create a minimal session to prevent further errors
              createMinimalSession(user.uid);
            }
          }
        } else {
          currentUser = null;
          
          // End the current session if user logs out
          if (currentSession) {
            try {
              endSession();
            } catch (e) {
              console.log('Error ending session on logout:', e);
              // Reset session data even if there was an error
              resetSessionData();
            }
          }
        }
      } catch (e) {
        console.log('Error in auth state change handler:', e);
      }
    });
    
    // Set up activity tracking with error handling
    try {
      if (typeof window !== 'undefined') {
        window.addEventListener('click', updateActivity);
        window.addEventListener('keypress', updateActivity);
        window.addEventListener('scroll', updateActivity);
        window.addEventListener('mousemove', updateActivity);
        
        // Handle page unload
        window.addEventListener('beforeunload', () => {
          if (currentSession) {
            try {
              endSession();
            } catch (e) {
              console.log('Error ending session on page unload:', e);
            }
          }
        });
      }
    } catch (e) {
      console.log('Error setting up activity tracking:', e);
    }
    
    // Check for session timeout periodically
    try {
      setInterval(() => {
        try {
          checkSessionTimeout();
        } catch (e) {
          console.log('Error checking session timeout:', e);
        }
      }, 60000); // Check every minute
    } catch (e) {
      console.log('Error setting up session timeout interval:', e);
    }
    
    // Start a session for anonymous users too
    if (!currentUser) {
      try {
        startSession(null);
      } catch (e) {
        console.log('Error starting session for anonymous user:', e);
        // Create a minimal session to prevent further errors
        createMinimalSession(null);
      }
    }
  } catch (e) {
    console.log('Error initializing analytics:', e);
  }
};

// Create a minimal session without Firestore operations
const createMinimalSession = (userId) => {
  try {
    sessionStartTime = new Date();
    lastActivityTime = sessionStartTime;
    pageViewCount = 0;
    
    // Create a minimal session object
    currentSession = {
      id: 'minimal-' + new Date().getTime(),
      userId: userId || 'anonymous',
      startTime: sessionStartTime,
      pages: [],
      pageViews: 0
    };
    
    console.log('Created minimal session:', currentSession.id);
  } catch (e) {
    console.log('Error creating minimal session:', e);
  }
};

// Reset session data
const resetSessionData = () => {
  currentSession = null;
  sessionStartTime = null;
  lastActivityTime = null;
  pageViewCount = 0;
};

// Start a new session
const startSession = async (userId) => {
  try {
    // First create a minimal session to ensure we have something
    createMinimalSession(userId);
    
    // Now try to create a proper session in Firestore
    try {
      // Create a new session document
      const sessionDoc = {
        userId: userId || 'anonymous',
        startTime: Timestamp.fromDate(sessionStartTime),
        endTime: null,
        duration: 0,
        pageViews: 0,
        pages: [],
        userAgent: typeof navigator !== 'undefined' ? (navigator?.userAgent || 'unknown') : 'unknown',
        referrer: typeof document !== 'undefined' ? (document?.referrer || 'direct') : 'direct',
        deviceType: getDeviceType(),
      };
      
      // Check if we have permission to write to the sessions collection
      try {
        // Use a safer approach to add the document
        const sessionsCollectionRef = collection(db, SESSIONS_COLLECTION);
        if (!sessionsCollectionRef) {
          throw new Error('Sessions collection reference is null');
        }
        
        const sessionRef = await addDoc(sessionsCollectionRef, sessionDoc);
        
        if (sessionRef && sessionRef.id) {
          // Update our session with the Firestore ID
          currentSession.id = sessionRef.id;
          
          // Log session start event
          try {
            logAnalyticsEvent(EVENT_TYPES.SESSION_START, {
              sessionId: currentSession.id,
              userId: userId || 'anonymous',
            });
          } catch (e) {
            console.log('Error logging session start event:', e);
          }
          
          // Update daily active users
          if (userId) {
            try {
              updateDailyActiveUsers(userId);
            } catch (e) {
              console.log('Error updating daily active users:', e);
            }
          }
        } else {
          console.log('Session reference or ID is null, keeping minimal session ID');
        }
      } catch (error) {
        console.log('Analytics permission error - continuing with minimal session:', error);
        // We already have a minimal session, so no need to create another one
      }
    } catch (error) {
      console.log('Error creating Firestore session, continuing with minimal session:', error);
      // We already have a minimal session, so no need to create another one
    }
  } catch (error) {
    console.error('Critical error in startSession:', error);
    // Create an absolute fallback session
    try {
      currentSession = {
        id: 'fallback-' + new Date().getTime(),
        userId: userId || 'anonymous',
        startTime: new Date(),
        pages: [],
        pageViews: 0
      };
    } catch (e) {
      console.log('Error creating fallback session:', e);
    }
  }
};

// End the current session
const endSession = async () => {
  if (!currentSession) return;
  
  try {
    const endTime = new Date();
    const duration = endTime - (sessionStartTime || endTime);
    
    // Only try to update Firestore if this is a proper Firestore session
    if (currentSession.id && 
        typeof currentSession.id === 'string' && 
        !currentSession.id.startsWith('minimal-') && 
        !currentSession.id.startsWith('fallback-')) {
      
      try {
        // Ensure pages array exists
        const pages = Array.isArray(currentSession.pages) ? currentSession.pages : [];
        
        // Get a reference to the document
        const sessionDocRef = doc(db, SESSIONS_COLLECTION, currentSession.id);
        
        if (!sessionDocRef) {
          throw new Error('Session document reference is null');
        }
        
        // Update the session document
        await updateDoc(sessionDocRef, {
          endTime: Timestamp.fromDate(endTime),
          duration: duration,
          pageViews: pageViewCount,
          pages: pages,
        });
        
        // Log session end event
        try {
          logAnalyticsEvent(EVENT_TYPES.SESSION_END, {
            sessionId: currentSession.id,
            userId: currentSession.userId || 'anonymous',
            duration: duration,
            pageViews: pageViewCount,
          });
        } catch (e) {
          console.log('Error logging session end event:', e);
        }
      } catch (error) {
        console.log('Error updating session in Firestore:', error);
      }
    } else {
      // For minimal or fallback sessions, just log the event
      try {
        logAnalyticsEvent(EVENT_TYPES.SESSION_END, {
          sessionId: currentSession.id || 'unknown',
          userId: currentSession.userId || 'anonymous',
          duration: duration,
          pageViews: pageViewCount,
        });
      } catch (e) {
        console.log('Error logging session end event for minimal session:', e);
      }
    }
  } catch (error) {
    console.error('Error in endSession:', error);
  } finally {
    // Always reset session data
    resetSessionData();
  }
};

// Update user activity
const updateActivity = () => {
  try {
    lastActivityTime = new Date();
  } catch (e) {
    console.log('Error updating activity time:', e);
  }
};

// Check for session timeout
const checkSessionTimeout = () => {
  try {
    if (!currentSession || !lastActivityTime) return;
    
    const now = new Date();
    const inactiveTime = now - lastActivityTime;
    
    if (inactiveTime > SESSION_TIMEOUT) {
      try {
        endSession();
      } catch (e) {
        console.log('Error ending session in checkSessionTimeout:', e);
        // Reset session data even if endSession fails
        resetSessionData();
      }
      
      // Start a new session if the user is still logged in
      try {
        if (currentUser) {
          startSession(currentUser.uid);
        } else {
          startSession(null);
        }
      } catch (e) {
        console.log('Error starting new session after timeout:', e);
        // Create a minimal session if startSession fails
        createMinimalSession(currentUser?.uid || null);
      }
    }
  } catch (e) {
    console.log('Error in checkSessionTimeout:', e);
  }
};

// Track page view
export const trackPageView = async (pageName, pageParams = {}) => {
  try {
    // Ensure pageName is a string
    const safePageName = pageName || 'unknown';
    const safeParams = pageParams || {};
    
    // Start a new session if none exists
    if (!currentSession) {
      try {
        if (currentUser) {
          await startSession(currentUser.uid);
        } else {
          await startSession(null);
        }
      } catch (e) {
        console.log('Error starting session in trackPageView:', e);
        // Create a minimal session if startSession fails
        createMinimalSession(currentUser?.uid || null);
      }
    }
    
    if (!currentSession) {
      console.log('No session available for tracking page view');
      return;
    }
    
    // Increment page view count
    pageViewCount++;
    
    // Add page to session pages if not already included
    try {
      if (!currentSession.pages) {
        currentSession.pages = [];
      }
      
      if (Array.isArray(currentSession.pages) && !currentSession.pages.includes(safePageName)) {
        currentSession.pages.push(safePageName);
      }
    } catch (e) {
      console.log('Error updating session pages:', e);
    }
    
    // Only try to write to Firestore if this is a proper Firestore session
    if (currentSession.id && 
        typeof currentSession.id === 'string' && 
        !currentSession.id.startsWith('minimal-') && 
        !currentSession.id.startsWith('fallback-')) {
      
      try {
        // Create page view document
        const pageView = {
          sessionId: currentSession.id,
          userId: currentSession.userId || 'anonymous',
          pageName: safePageName,
          timestamp: Timestamp.fromDate(new Date()),
          params: safeParams,
        };
        
        // Get a reference to the collection
        const pageViewsCollectionRef = collection(db, PAGE_VIEWS_COLLECTION);
        
        if (!pageViewsCollectionRef) {
          throw new Error('Page views collection reference is null');
        }
        
        await addDoc(pageViewsCollectionRef, pageView);
        
        // Also increment the page view count in daily stats
        try {
          incrementPageViewCount();
        } catch (e) {
          console.log('Error incrementing page view count:', e);
        }
      } catch (error) {
        console.log('Error tracking page view in Firestore:', error);
      }
    }
    
    // Log page view event (always do this regardless of Firestore success)
    try {
      logAnalyticsEvent(EVENT_TYPES.PAGE_VIEW, {
        pageName: safePageName,
        ...safeParams,
      });
    } catch (e) {
      console.log('Error logging page view event:', e);
    }
    
    // Update activity time
    try {
      updateActivity();
    } catch (e) {
      console.log('Error updating activity time:', e);
    }
  } catch (error) {
    console.error('Critical error in trackPageView:', error);
  }
};

// Track registry item events
export const trackRegistryItem = async (eventType, itemData) => {
  try {
    // Validate inputs
    if (!eventType) {
      console.log('No event type provided for trackRegistryItem');
      return;
    }
    
    const safeItemData = itemData || {
      id: 'unknown',
      name: 'Unknown Item',
      category: 'Unknown'
    };
    
    // Start a new session if none exists
    if (!currentSession) {
      try {
        if (currentUser) {
          await startSession(currentUser.uid);
        } else {
          await startSession(null);
        }
      } catch (e) {
        console.log('Error starting session in trackRegistryItem:', e);
        // Create a minimal session if startSession fails
        createMinimalSession(currentUser?.uid || null);
      }
    }
    
    if (!currentSession) {
      console.log('No session available for tracking registry item');
      return;
    }
    
    // Only try to write to Firestore if this is a proper Firestore session
    if (currentSession.id && 
        typeof currentSession.id === 'string' && 
        !currentSession.id.startsWith('minimal-') && 
        !currentSession.id.startsWith('fallback-')) {
      
      try {
        // Create registry item event document
        const event = {
          sessionId: currentSession.id,
          userId: currentSession.userId || 'anonymous',
          eventType: eventType,
          itemId: safeItemData.id || 'unknown',
          itemName: safeItemData.name || 'Unknown Item',
          itemCategory: safeItemData.category || 'Unknown',
          timestamp: Timestamp.fromDate(new Date()),
        };
        
        // Get a reference to the collection
        const registryItemsCollectionRef = collection(db, REGISTRY_ITEMS_COLLECTION);
        
        if (!registryItemsCollectionRef) {
          throw new Error('Registry items collection reference is null');
        }
        
        await addDoc(registryItemsCollectionRef, event);
      } catch (error) {
        console.log('Error tracking registry item in Firestore:', error);
      }
    }
    
    // Log registry item event (always do this regardless of Firestore success)
    try {
      logAnalyticsEvent(eventType, {
        itemId: safeItemData.id || 'unknown',
        itemName: safeItemData.name || 'Unknown Item',
        itemCategory: safeItemData.category || 'Unknown',
      });
    } catch (e) {
      console.log('Error logging registry item event:', e);
    }
    
    // Update activity time
    try {
      updateActivity();
    } catch (e) {
      console.log('Error updating activity time:', e);
    }
  } catch (error) {
    console.error('Critical error in trackRegistryItem:', error);
  }
};

// Update daily active users
const updateDailyActiveUsers = async (userId) => {
  if (!userId) return;
  
  try {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    const dateString = today.toISOString().split('T')[0];
    const docRef = doc(db, 'dailyStats', dateString);
    
    try {
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists()) {
        // Document exists, update it
        const data = docSnap.data();
        
        // Ensure activeUsers is an array
        let activeUsers = [];
        if (data.activeUsers && Array.isArray(data.activeUsers)) {
          activeUsers = [...data.activeUsers];
        }
        
        if (!activeUsers.includes(userId)) {
          activeUsers.push(userId);
          await updateDoc(docRef, {
            activeUsers: activeUsers,
            activeUserCount: activeUsers.length,
          });
        }
      } else {
        // Document doesn't exist, create it
        await setDoc(docRef, {
          date: Timestamp.fromDate(today),
          activeUsers: [userId],
          activeUserCount: 1,
          newUsers: [],
          newUserCount: 0,
          pageViews: 0,
        });
      }
    } catch (error) {
      console.log('Error updating daily active users document:', error);
    }
  } catch (error) {
    console.error('Error in updateDailyActiveUsers:', error);
  }
};

// Track new user signup
export const trackUserSignup = async (userId, userData) => {
  if (!userId || !userData) return;
  
  try {
    // Log user signup event
    logAnalyticsEvent(EVENT_TYPES.USER_SIGNUP, {
      userId: userId,
      email: userData.email || 'unknown',
    });
    
    // Update daily new users
    try {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      
      const dateString = today.toISOString().split('T')[0];
      const docRef = doc(db, 'dailyStats', dateString);
      
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists()) {
        // Document exists, update it
        const data = docSnap.data();
        
        // Ensure newUsers is an array
        let newUsers = [];
        if (data.newUsers && Array.isArray(data.newUsers)) {
          newUsers = [...data.newUsers];
        }
        
        if (!newUsers.includes(userId)) {
          newUsers.push(userId);
          await updateDoc(docRef, {
            newUsers: newUsers,
            newUserCount: newUsers.length,
          });
        }
      } else {
        // Document doesn't exist, create it
        await setDoc(docRef, {
          date: Timestamp.fromDate(today),
          activeUsers: [userId],
          activeUserCount: 1,
          newUsers: [userId],
          newUserCount: 1,
          pageViews: 0,
        });
      }
    } catch (error) {
      console.log('Error updating daily new users document:', error);
    }
  } catch (error) {
    console.error('Error tracking user signup:', error);
  }
};

// Increment page view count in daily stats
export const incrementPageViewCount = async () => {
  try {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    const dateString = today.toISOString().split('T')[0];
    const docRef = doc(db, 'dailyStats', dateString);
    
    try {
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists()) {
        // Document exists, update it
        await updateDoc(docRef, {
          pageViews: increment(1),
        });
      } else {
        // Document doesn't exist, create it
        await setDoc(docRef, {
          date: Timestamp.fromDate(today),
          activeUsers: [],
          activeUserCount: 0,
          newUsers: [],
          newUserCount: 0,
          pageViews: 1,
        });
      }
    } catch (error) {
      console.log('Error incrementing page view count document:', error);
    }
  } catch (error) {
    console.error('Error in incrementPageViewCount:', error);
  }
};

// Get device type
const getDeviceType = () => {
  try {
    // Check if navigator is available (it might not be in some environments)
    if (typeof navigator === 'undefined') {
      return 'unknown';
    }
    
    const userAgent = navigator?.userAgent || '';
    
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userAgent)) {
      return 'tablet';
    }
    
    if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(userAgent)) {
      return 'mobile';
    }
    
    return 'desktop';
  } catch (error) {
    console.log('Error determining device type:', error);
    return 'unknown';
  }
};

// Analytics data retrieval functions
export const getAnalyticsData = {
  // Get total user count
  getTotalUserCount: async () => {
    try {
      const q = query(collection(db, 'users'));
      const querySnapshot = await getDocs(q);
      return querySnapshot.size;
    } catch (error) {
      console.error('Error getting total user count:', error);
      return 0;
    }
  },
  
  // Get new user count for a specific time period
  getNewUserCount: async (startDate, endDate) => {
    try {
      const q = query(
        collection(db, 'dailyStats'),
        where('date', '>=', Timestamp.fromDate(startDate)),
        where('date', '<=', Timestamp.fromDate(endDate))
      );
      
      const querySnapshot = await getDocs(q);
      
      let totalNewUsers = 0;
      
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        totalNewUsers += data.newUserCount || 0;
      });
      
      return totalNewUsers;
    } catch (error) {
      console.error('Error getting new user count:', error);
      return 0;
    }
  },
  
  // Get active user count for a specific time period
  getActiveUserCount: async (startDate, endDate) => {
    try {
      const q = query(
        collection(db, 'dailyStats'),
        where('date', '>=', Timestamp.fromDate(startDate)),
        where('date', '<=', Timestamp.fromDate(endDate))
      );
      
      const querySnapshot = await getDocs(q);
      
      // Use a Set to store unique user IDs
      const uniqueUsers = new Set();
      
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.activeUsers && Array.isArray(data.activeUsers)) {
          data.activeUsers.forEach(userId => uniqueUsers.add(userId));
        }
      });
      
      return uniqueUsers.size;
    } catch (error) {
      console.error('Error getting active user count:', error);
      return 0;
    }
  },
  
  // Get page view count for a specific time period
  getPageViewCount: async (startDate, endDate) => {
    try {
      const q = query(
        collection(db, 'dailyStats'),
        where('date', '>=', Timestamp.fromDate(startDate)),
        where('date', '<=', Timestamp.fromDate(endDate))
      );
      
      const querySnapshot = await getDocs(q);
      
      let totalPageViews = 0;
      
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        totalPageViews += data.pageViews || 0;
      });
      
      return totalPageViews;
    } catch (error) {
      console.error('Error getting page view count:', error);
      return 0;
    }
  },
  
  // Get average session duration for a specific time period
  getAverageSessionDuration: async (startDate, endDate) => {
    try {
      const q = query(
        collection(db, SESSIONS_COLLECTION),
        where('startTime', '>=', Timestamp.fromDate(startDate)),
        where('startTime', '<=', Timestamp.fromDate(endDate)),
        where('endTime', '!=', null)
      );
      
      const querySnapshot = await getDocs(q);
      
      let totalDuration = 0;
      let sessionCount = 0;
      
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.duration) {
          totalDuration += data.duration;
          sessionCount++;
        }
      });
      
      return sessionCount > 0 ? Math.round(totalDuration / sessionCount) : 0;
    } catch (error) {
      console.error('Error getting average session duration:', error);
      return 0;
    }
  },
  
  // Get bounce rate for a specific time period
  getBounceRate: async (startDate, endDate) => {
    try {
      const q = query(
        collection(db, SESSIONS_COLLECTION),
        where('startTime', '>=', Timestamp.fromDate(startDate)),
        where('startTime', '<=', Timestamp.fromDate(endDate)),
        where('endTime', '!=', null)
      );
      
      const querySnapshot = await getDocs(q);
      
      let totalSessions = 0;
      let bounceSessions = 0;
      
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        totalSessions++;
        
        // A session is considered a bounce if it has only one page view
        if (data.pageViews <= 1) {
          bounceSessions++;
        }
      });
      
      return totalSessions > 0 ? Math.round((bounceSessions / totalSessions) * 100) : 0;
    } catch (error) {
      console.error('Error getting bounce rate:', error);
      return 0;
    }
  },
  
  // Get conversion rate for a specific time period
  getConversionRate: async (startDate, endDate) => {
    try {
      // For this example, we'll define conversion as a user who viewed a registry item
      // and then purchased it within the same session
      
      // Get all registry item events
      const q = query(
        collection(db, REGISTRY_ITEMS_COLLECTION),
        where('timestamp', '>=', Timestamp.fromDate(startDate)),
        where('timestamp', '<=', Timestamp.fromDate(endDate))
      );
      
      const querySnapshot = await getDocs(q);
      
      // Track sessions with views and purchases
      const sessionsWithViews = new Set();
      const sessionsWithPurchases = new Set();
      
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        
        if (data.eventType === EVENT_TYPES.REGISTRY_ITEM_VIEWED) {
          sessionsWithViews.add(data.sessionId);
        } else if (data.eventType === EVENT_TYPES.REGISTRY_ITEM_PURCHASED) {
          sessionsWithPurchases.add(data.sessionId);
        }
      });
      
      // Count sessions that had both views and purchases
      let conversions = 0;
      sessionsWithPurchases.forEach(sessionId => {
        if (sessionsWithViews.has(sessionId)) {
          conversions++;
        }
      });
      
      return sessionsWithViews.size > 0 ? Math.round((conversions / sessionsWithViews.size) * 100) : 0;
    } catch (error) {
      console.error('Error getting conversion rate:', error);
      return 0;
    }
  },
  
  // Get popular pages for a specific time period
  getPopularPages: async (startDate, endDate) => {
    try {
      const q = query(
        collection(db, PAGE_VIEWS_COLLECTION),
        where('timestamp', '>=', Timestamp.fromDate(startDate)),
        where('timestamp', '<=', Timestamp.fromDate(endDate))
      );
      
      const querySnapshot = await getDocs(q);
      
      // Count page views by page name
      const pageViewCounts = {};
      
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        const pageName = data.pageName || 'Unknown';
        
        if (!pageViewCounts[pageName]) {
          pageViewCounts[pageName] = {
            pageName: pageName,
            count: 0
          };
        }
        
        pageViewCounts[pageName].count++;
      });
      
      // Convert to array and sort by count
      const popularPages = Object.values(pageViewCounts);
      popularPages.sort((a, b) => b.count - a.count);
      
      return popularPages.slice(0, 10); // Return top 10
    } catch (error) {
      console.error('Error getting popular pages:', error);
      return [];
    }
  },
  
  // Get popular registry items for a specific time period
  getPopularRegistryItems: async (startDate, endDate) => {
    try {
      const q = query(
        collection(db, REGISTRY_ITEMS_COLLECTION),
        where('timestamp', '>=', Timestamp.fromDate(startDate)),
        where('timestamp', '<=', Timestamp.fromDate(endDate))
      );
      
      const querySnapshot = await getDocs(q);
      
      // Count views and purchases by item
      const itemStats = {};
      
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        const itemId = data.itemId || 'Unknown';
        
        if (!itemStats[itemId]) {
          itemStats[itemId] = {
            id: itemId,
            name: data.itemName || 'Unknown Item',
            category: data.itemCategory || 'Unknown',
            views: 0,
            purchaseCount: 0
          };
        }
        
        if (data.eventType === EVENT_TYPES.REGISTRY_ITEM_VIEWED) {
          itemStats[itemId].views++;
        } else if (data.eventType === EVENT_TYPES.REGISTRY_ITEM_PURCHASED) {
          itemStats[itemId].purchaseCount++;
        }
      });
      
      // Convert to array and sort by views
      const popularItems = Object.values(itemStats);
      popularItems.sort((a, b) => b.views - a.views);
      
      return popularItems.slice(0, 50); // Return top 50
    } catch (error) {
      console.error('Error getting popular registry items:', error);
      return [];
    }
  },
  
  // Get daily stats for a specific time period
  getDailyStats: async (startDate, endDate) => {
    try {
      const q = query(
        collection(db, 'dailyStats'),
        where('date', '>=', Timestamp.fromDate(startDate)),
        where('date', '<=', Timestamp.fromDate(endDate)),
        orderBy('date', 'asc')
      );
      
      const querySnapshot = await getDocs(q);
      
      const dailyStats = [];
      
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        
        dailyStats.push({
          date: data.date.toDate(),
          activeUsers: data.activeUserCount || 0,
          newUsers: data.newUserCount || 0,
          pageViews: data.pageViews || 0
        });
      });
      
      return dailyStats;
    } catch (error) {
      console.error('Error getting daily stats:', error);
      return [];
    }
  }
};

export default {
  initAnalytics,
  trackPageView,
  trackRegistryItem,
  trackUserSignup,
  incrementPageViewCount,
  getAnalyticsData,
  EVENT_TYPES,
}; 
import React, { useState, useEffect } from "react";
import { View, Text, Button, StyleSheet, Dimensions, TextInput, Pressable, ScrollView } from "react-native";
import { getFirestore, collection, getDocs, updateDoc, query, where, setDoc, addDoc, doc, deleteDoc, onSnapshot, getDoc } from "firebase/firestore";
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged, } from "firebase/auth";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { app } from "../firebase";
import AWS from "aws-sdk";
import { useUser } from "../Widgets/UserContext";
import Popup from "../Widgets/Popup";
import { sendPasswordResetEmail } from "firebase/auth";
import axios from 'axios';
import Analytics from './AdminWidgets/Analytics'; // Import the Analytics component
import AnalyticsService from '../services/AnalyticsService'; // Import the Analytics service
import UserList from '../components/UserList';

const auth = getAuth(app);

const isDevelopment = process.env.NODE_ENV === "development";

const s3 = new AWS.S3();
const db = getFirestore();

AWS.config.update({
	accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
	secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
	region: process.env.REACT_APP_AWS_REGION,
});

// Add after imports
const { width } = Dimensions.get('window');
const isMobile = width < 768;
const isTablet = width >= 768 && width < 1024;
const isSmallDesktop = width >= 1024 && width < 1440;
const isLargeDesktop = width >= 1440;

const Admin = () => {
	console.log("Admin component rendering...");
	
	const [organizations, setOrganizations] = useState([]);
	const [approvedOrgs, setApprovedOrgs] = useState([]);
	const [deniedOrgs, setDeniedOrgs] = useState([]);
	const [loading, setLoading] = useState(true);
	const [catalogueItems, setCatalogueItems] = useState([]); // New state for catalogue items
	const [error, setError] = useState(null); // Add error state

	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 25;
	const [searchQuery, setSearchQuery] = useState("");

	const [affiliateUrl, setAffiliateUrl] = useState("");
	const [itemPreview, setItemPreview] = useState(null);

	const [loadingStep, setLoadingStep] = useState("");
	const [isFetching, setIsFetching] = useState(false);
	const [showStillWorking, setShowStillWorking] = useState(false);

	const [category, setCategory] = useState(""); // For selecting a category
	const categories = ["Bedroom", "Bathroom", "Decor", "Health & Personal Care", "Kitchen", "Laundry & Cleaning", "Electronics & Office Supplies", "Office Supplies", "Storage & Organization"];

	const [categoryError, setCategoryError] = useState(false);

	const [filter, setFilter] = useState("all"); // 'all', or any of the categories
	const [sort, setSort] = useState("none"); // 'none', 'priceLowHigh', 'priceHighLow'

	const [itemName, setItemName] = useState("");
	const [itemDescription, setItemDescription] = useState("");
	const [itemPrice, setItemPrice] = useState("");
	const [itemImage, setItemImage] = useState("");

	const [selectedPrice, setSelectedPrice] = useState('');
	const [priceUpdates, setPriceUpdates] = useState({}); // For individual edits
	const [selectedCategory, setSelectedCategory] = useState('');
	const [selectedProducts, setSelectedProducts] = useState([]);
	const [bulkPrice, setBulkPrice] = useState('');
	const [bulkDescription, setBulkDescription] = useState('');

	const [activeTab, setActiveTab] = useState("users"); // Default tab changed to users

	const tabs = [
		{ id: "users", label: "Users" },
		{ id: "organizations", label: "Organizations" },
		{ id: "catalogue", label: "Catalogue" },
		{ id: "blog", label: "Blog" },
		{ id: "analytics", label: "Analytics" },
		{ id: "maintenance", label: "Maintenance" }
	];

	let LOCAL_SERVER_URL = "http://localhost:3300";
	let AWS_SERVER_URL = "https://api.mytassel.com";

	const isDevelopment = process.env.NODE_ENV === "development"; // Adjust this condition based on your setup
	const SERVER_URL = isDevelopment ? LOCAL_SERVER_URL : AWS_SERVER_URL;

	const { currentUserData } = useUser();
	const [showLoginPopup, setShowLoginPopup] = useState(false);
	const [showAdmin, setShowAdmin] = useState(false); // New state for showing admin content

	const [testEmail, setTestEmail] = useState("");
	const [emailType, setEmailType] = useState("genericWelcome");
	const [emailParams, setEmailParams] = useState({});
	const [emailTestResult, setEmailTestResult] = useState("");
	const [recipientEmail, setRecipientEmail] = useState("");
	const [testPhone, setTestPhone] = useState("");
	const [smsType, setSmsType] = useState("welcome");
	const [smsTestResult, setSmsTestResult] = useState("");

	const [isMaintenance, setIsMaintenance] = useState(false);
	const [mismatchedUsers, setMismatchedUsers] = useState([]);
	const [maintenanceMode, setMaintenanceMode] = useState(false);
	const [disableRegistration, setDisableRegistration] = useState(false);

	const [blogTitle, setBlogTitle] = useState("");
	const [blogContent, setBlogContent] = useState("");
	const [blogCategory, setBlogCategory] = useState("");
	const [blogImage, setBlogImage] = useState("");
	const [blogTags, setBlogTags] = useState([]);
	const [blogPosts, setBlogPosts] = useState([]);
	const [editingBlogId, setEditingBlogId] = useState(null);
	const [publishDate, setPublishDate] = useState("");
	const [lastUpdatedDate, setLastUpdatedDate] = useState("");

	const blogCategories = [
		"College Life",
		"Graduation Tips",
		"Dorm Living",
		"Study Tips",
		"Career Advice",
		"Financial Planning",
		"Student Life",
		"Moving Tips"
	];

	// Add preset blog tags
	const presetBlogTags = [
		"Freshman Tips",
		"Senior Year",
		"Dorm Essentials",
		"Study Hacks",
		"Career Planning",
		"Money Management",
		"Campus Life",
		"Organization Tips",
		"Time Management",
		"College Success",
		"Graduation",
		"Student Resources",
		"Moving Guide",
		"College Prep",
		"Student Life Hacks"
	];

	// Add state for subcategories
	const [subCategories, setSubCategories] = useState({
		"Bedroom": [
			"Sheets", "Comforter", "Bed In a Bag", "Pillow", "Pillow Protector", "Body Pillow",
			"Mattress Topper", "Mattress Protector", "Throw Blanket", "Study Pillow",
			"Space Heaters", // Added new subcategory
			"Room Security", // Changed from "Room Alarm"
			"Misc"
		],
		"Bathroom": [
			"Towel Set", "Waterproof Speakers", // Changed from "Bluetooth Speaker"
			"Shower Caddy", "Shower Shoes", "Bathrobe", "Shower Wrap", "Hair Wrap",
			"Bathroom Rug", "Shower Curtain", "Tension Rod", "Cosmetic Organizer", // Moved from Bedroom
			"Misc"
		],
		"Laundry & Cleaning": [
			"Cleaning Bundle", "Laundry Basket", "Hamper", "Laundry Detergent",
			"Odor Eliminator", "Steamer", "Iron", // Added "Iron"
			"Ironing Board", "Vacuums", "Laundry Bag", "Broom & Dust Pan",
			"Garbage Can", "Misc"
		],
		"Electronics & Office Supplies": [
			"Television", "Laptop", "Chargers", "Flash Drive", "Hard Drive",
			"Extension Cords", "Headphones", "Printer", "Alarm Clock",
			"Bluetooth Speaker", "Personal Alarm",
			"Firestick", "Alexa/Amazon Speakers",
			"Day Planner", "Backpack", "Pens", "Pencils", "Highlighters",
			"Notebooks", "Stapler", "Scissors", "Printer Paper", "Sticky Notes",
			"Dry Erase Markers", "Desk Set", "Computer Mouse",
			"Misc"
		],
		"Decor": ["Throw Rug", "Throw Pillows", "Clip Lamps", "Desk Lamp", "Floor Lamp", "Lounge Seating", "Mirror", "Picture Frames", "String Lights", "Tension Rod", "Vanity Mirror", "Window Curtains", "Wall Safe Adhesive", "Dry Erase/Bulletin Board", "Standing Fan", "Bed Fan/Clip Fan", "Doorstopper", "Lap Desk", "Room Decor", "Faux Plants", "Floor Pillows", "Misc"],
		"Health & Personal Care": ["Hair Dryer", "Styling Tools", "Hair Removal", "First Aid Kit", "Pain Reliever", "Sewing Kit", "Exercise Equipment", "Humidifier", "Diffuser", "Diffuser Oils", "Air Purifier", "Noise Machine", "Tool Set", "Misc"],
		"Kitchen": ["Microwave", "Refrigerator", "Personal Refrigerator", "Coffee Maker", "Rice Cooker", "Cooking Tools", "Smoothie Maker", "Air Fryer", "Dishes", "Silverware", "Water Bottles", "Travel Mug", "Can Opener", "Water Filtering", "Mugs", "Snack Bundles", "Food Storage", "Pots/Pans", "Cooking Utensils", "Misc"],
		"Storage & Organization": ["Bedside Shelf/Caddy", "Closet Organization", "Shoe Storage/Racks", "Hangers", "Trunk/Storage", "Underbed Storage", "Drawers", "Over Door Organization", "Rolling Cart", "Desk Organizer", "Wall Key Organizer", "Moving Bags", "Luggage", "Misc"]
	});

	const [selectedSubCategory, setSelectedSubCategory] = useState("");

	// Add these state variables near the other state declarations
	const [users, setUsers] = useState([]);
	const [authUsers, setAuthUsers] = useState([]);
	const [userSearchQuery, setUserSearchQuery] = useState('');
	const [selectedUser, setSelectedUser] = useState(null);
	const [isUserModalOpen, setIsUserModalOpen] = useState(false);
	const [userActionLoading, setUserActionLoading] = useState(false);
	const [shareAnalytics, setShareAnalytics] = useState({}); // Add this line for share analytics

	// Add these state variables
	const [userFilter, setUserFilter] = useState('all'); // 'all', 'verified', 'unverified', 'admin', 'student'
	const [userSort, setUserSort] = useState('newest'); // 'newest', 'oldest', 'alphabetical'

	// Add a new state for search input
	const [searchInput, setSearchInput] = useState('');

	// Add new state variables
	const [adminPassword, setAdminPassword] = useState("");
	const [showStripeResetConfirm, setShowStripeResetConfirm] = useState(false);
	const [userToResetStripe, setUserToResetStripe] = useState(null);

	// Add search button handler
	const handleSearch = () => {
		if (searchInput.trim().length < 3) {
			alert('Please enter at least 3 characters to search');
			return;
		}
		setSearchQuery(searchInput);
	};

	// Add these functions for user management
	const fetchAllUsers = async () => {
		try {
			// Fetch database users
			const usersSnapshot = await getDocs(collection(db, "users"));
			const dbUsers = usersSnapshot.docs.map(doc => ({
				...doc.data(),
				id: doc.id,
				source: 'database'
			}));
			setUsers(dbUsers);

			// Get the current user's ID token
			const token = await auth.currentUser.getIdToken();

			// Fetch authentication users
			const authUsersResponse = await fetch(`${SERVER_URL}/api/listUsers`, {
				method: 'GET',
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${token}`
					}
			});
			const authUsersData = await authUsersResponse.json();
			setAuthUsers(authUsersData.users || []);
		} catch (error) {
			console.error("Error fetching users:", error);
		}
	};

	const handleResetUserPassword = async (email) => {
		if (window.confirm(`Are you sure you want to send a password reset email to ${email}?`)) {
			try {
				setUserActionLoading(true);
				await sendPasswordResetEmail(auth, email);
				alert(`Password reset email sent to ${email}`);
			} catch (error) {
				alert(`Error: ${error.message}`);
			} finally {
				setUserActionLoading(false);
			}
		}
	};

	const handleUpdateUserEmail = async (userId, currentEmail) => {
		const newEmail = prompt("Enter new email address:", currentEmail);
		if (newEmail && newEmail !== currentEmail) {
			try {
				setUserActionLoading(true);
				await fetch(`${SERVER_URL}/api/updateUserEmail`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({
						uid: userId,
						newEmail: newEmail
					})
				});
				
				// Update in Firestore
				const userRef = doc(db, "users", userId);
				await updateDoc(userRef, { email: newEmail });
				
				alert("Email updated successfully");
				fetchAllUsers();
			} catch (error) {
				alert(`Error: ${error.message}`);
			} finally {
				setUserActionLoading(false);
			}
		}
	};

	const handleDeleteUser = async (userId, email) => {
		// Create a more detailed confirmation message
		const confirmMessage = `Are you sure you want to delete user ${email}?\n\nThis will:\n- Delete their authentication account\n- Remove all their data from the database\n- This action CANNOT be undone\n\nPlease type DELETE to confirm:`;
		
		const userInput = prompt(confirmMessage);
		
		if (userInput === 'DELETE') {
			try {
				setUserActionLoading(true);
				
				// First try to delete from Firestore
				try {
					await deleteDoc(doc(db, "users", email.toLowerCase()));
					console.log("Successfully deleted Firestore document");
				} catch (firestoreError) {
					console.error("Error deleting Firestore document:", firestoreError);
					throw new Error(`Failed to delete user data: ${firestoreError.message}`);
				}
				
				// Then try to delete from Authentication
				try {
					const token = await auth.currentUser.getIdToken();
					const authResponse = await fetch(`${SERVER_URL}/api/deleteUser`, {
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
							'Authorization': `Bearer ${token}`
						},
						body: JSON.stringify({ 
							uid: userId,
							email: email.toLowerCase() 
						})
					});

					if (!authResponse.ok) {
						const errorData = await authResponse.json();
						throw new Error(errorData.message || 'Failed to delete authentication account');
					}
				} catch (authError) {
					console.error("Error deleting auth account:", authError);
					// Even if auth deletion fails, we've already deleted the Firestore data
					alert("Warning: User data was deleted but there was an issue removing their authentication account. Please contact support.");
					fetchAllUsers();
					return;
				}
				
				alert("User successfully deleted from both authentication and database.");
				fetchAllUsers();
			} catch (error) {
				console.error("Error in deletion process:", error);
				alert(`Error during deletion process: ${error.message}\nPlease try again or contact support if the issue persists.`);
			} finally {
				setUserActionLoading(false);
			}
		} else if (userInput !== null) {
			alert("Deletion cancelled - incorrect confirmation text entered.");
		}
	};

	// Function to handle subcategory change
	const handleSubCategoryChange = async (itemId, newSubCategory) => {
		try {
			const itemRef = doc(db, "catalogueItems", itemId);
			await updateDoc(itemRef, { subCategory: newSubCategory });

			setCatalogueItems(
				catalogueItems.map((item) => {
					if (item.id === itemId) {
						return { ...item, subCategory: newSubCategory };
					}
					return item;
				})
			);

			// Automatically select the product
			setSelectedProducts((prevSelectedProducts) => {
				if (!prevSelectedProducts.includes(itemId)) {
					return [...prevSelectedProducts, itemId];
				}
				return prevSelectedProducts;
			});
		} catch (error) {
			console.error("Error updating subcategory:", error);
		}
	};

	useEffect(() => {
		if (isDevelopment) {
			setShowAdmin(true); // Bypass login and authorization checks in development
		} else {
			if (!currentUserData || currentUserData?.accountType !== "admin") {
				setShowLoginPopup(true);
				setShowAdmin(false);
				setTestEmail(currentUserData?.email);
			} else {
				setShowAdmin(true);
			}
		}
	}, [currentUserData]);

	useEffect(() => {
		try {
			// Wrap initialization in try/catch
			const initializeAdmin = async () => {
				try {
					setLoading(true);
					await fetchOrganizations();
					await fetchCatalogueItems();
					await fetchVisibilitySettings();
					await fetchBlogPosts();
					await fetchAllUsers();
					setLoading(false);
				} catch (err) {
					console.error("Error initializing Admin:", err);
					setError(err.message || "Failed to initialize Admin page");
					setLoading(false);
				}
			};
			
			initializeAdmin();
		} catch (err) {
			console.error("Critical error in Admin component:", err);
			setError(err.message || "Critical error in Admin component");
			setLoading(false);
		}
	}, []);

	useEffect(() => {
		fetchCatalogueItems();
	}, [filter, sort]);

	useEffect(() => {
		const statusDocRef = doc(db, "maintenance", "status"); // Ensure this path is correct
		const unsubscribe = onSnapshot(statusDocRef, (doc) => {
			if (doc.exists()) {
				setIsMaintenance(doc.data().isMaintenanceActive); // Corrected field name
				console.log("Maintenance status set!");
			} else {
				console.log("No such document!");
			}
		});
		return () => unsubscribe(); // Cleanup subscription on unmount
	}, []); // No need to add db as a dependency since it's imported

	useEffect(() => {
		scanForUidMismatches();
	}, []);

	useEffect(() => {
		if (activeTab === 'users') {
			fetchAllUsers();
			fetchShareAnalytics();
		}
	}, [activeTab]);

	// Initialize analytics when the analytics tab is active
	useEffect(() => {
		if (activeTab === 'analytics') {
			// This component will handle its own data loading
			console.log('Analytics tab is active - analytics component will load data');
		}
	}, [activeTab]);

	const toggleMaintenance = async () => {
		console.log("Toggle Maintenance button pressed"); // Debugging line
		const password = prompt("Please retype the site password to confirm:");
		if (password === "Newtjhawk27") { // Check if the password matches
			const newMaintenanceStatus = !isMaintenance; // Toggle the maintenance status
			await updateDoc(doc(db, "maintenance", "status"), {
				isMaintenanceActive: newMaintenanceStatus,
				startTime: newMaintenanceStatus ? new Date().toLocaleString() : null, // Changed to standard language format
			});
		} else {
			alert("Incorrect password. Maintenance status not changed.");
		}
	};

	const fetchOrganizations = async () => {
		const querySnapshot = await getDocs(collection(db, "new organizations"));
		const orgs = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
		setOrganizations(orgs);
		setLoading(false);
	};

	// Adjusted fetchCatalogueItems to optionally filter and sort items
	const fetchCatalogueItems = async () => {
		let querySnapshot = await getDocs(collection(db, "catalogueItems"));
		let items = querySnapshot.docs.map((doc) => {
			const data = doc.data();
			return {
				id: doc.id,
				...data,
				subCategory: data.subCategory || "Default Subcategory" // Use default or existing subcategory
			};
		});

		// Filter items if a category is selected
		if (filter !== "all") {
			items = items.filter((item) => item.category === filter);
		}

		// Sort items based on the selected sorting method
		if (sort === "priceLowHigh") {
			items.sort((a, b) => parseFloat(a.price.replace("$", "")) - parseFloat(b.price.replace("$", "")));
		} else if (sort === "priceHighLow") {
			items.sort((a, b) => parseFloat(b.price.replace("$", "")) - parseFloat(a.price.replace("$", "")));
		}

		setCatalogueItems(items);
	};

	// Function to handle search input change
	const handleSearchChange = (e) => {
		setSearchQuery(e.target.value);
		setCurrentPage(1); // Reset to first page to start filtering from the beginning
	};

	// Filter and paginate items
	const filteredItems = catalogueItems.filter((item) => item.name.toLowerCase().includes(searchQuery.toLowerCase()));

	// Calculate total pages
	const totalPages = Math.ceil(filteredItems.length / itemsPerPage);

	// Function to change page
	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	// Calculate the items to display
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

	const handleApprove = (org) => {
		const updatedOrg = { ...org, approved: true, code: generateInstitutionCode(org.organizationType) };
		setApprovedOrgs([...approvedOrgs, updatedOrg]);
		setOrganizations(organizations.filter((o) => o.id !== org.id));
	};

	const handleDeny = (org) => {
		const updatedOrg = { ...org, approved: false };
		setDeniedOrgs([...deniedOrgs, updatedOrg]);
		setOrganizations(organizations.filter((o) => o.id !== org.id));
	};

	const uploadApproved = async () => {
		if (window.confirm("Are you sure you want to upload these entries?")) {
			try {
				// Upload each approved organization to Firestore
				for (const org of approvedOrgs) {
					await addDoc(collection(db, "approvedOrganizations"), formatOrgForUpload(org));
				}

				// Clear approved organizations
				setApprovedOrgs([]);

				// Update Firestore for each organization
				approvedOrgs.forEach((org) => {
					deleteDoc(doc(db, "new organizations", org.id));
				});
			} catch (error) {
				console.error("Error during upload", error);
			}
		}
	};

	const formatOrgForUpload = (org) => {
		return {
			name: org.name,
			domains: [org.website],
			web_pages: [org.website],
			country: org.location || "",
			alpha_two_code: org.location || "",
			"state-province": org.location || null,
			organizationCode: org.code,
			members: {},
		};
	};

	const purgeDenied = async () => {
		if (window.confirm("Are you sure you want to permanently delete these entries?")) {
			for (const org of deniedOrgs) {
				await deleteDoc(doc(db, "new organizations", org.name));
			}
			setDeniedOrgs([]);
		}
	};

	const handleEdit = (id, field, value) => {
		setOrganizations(
			organizations.map((org) => {
				if (org.id === id) {
					return { ...org, [field]: value };
				}
				return org;
			})
		);
	};

	const handleReevaluate = (org, listSetter, fromList) => {
		listSetter(fromList.filter((o) => o.id !== org.id));
		setOrganizations([...organizations, org]);
	};

	const fetchItemPreview = async () => {
		const cleanedAffiliateUrl = sanitizeAffiliateUrl(affiliateUrl);
		setAffiliateUrl(cleanedAffiliateUrl); // Update the state with the sanitized URL

		setLoadingStep("Checking for duplicates...");
		const isDuplicate = await checkForDuplicateLink(cleanedAffiliateUrl);

		if (isDuplicate) {
			setLoadingStep("This affiliate link is already in the database.");
			// Optionally, reset affiliateUrl or handle as needed
			setAffiliateUrl("");
			return; // Stop execution to prevent adding a duplicate
		}

		setLoadingStep("Fetching preview, please wait...");
		setIsFetching(true);
		setShowStillWorking(false); // Reset the "still working" message state

		// Set a timeout to show the "still working" message after 7 seconds
		const stillWorkingTimeout = setTimeout(() => {
			setShowStillWorking(true);
		}, 7000); // 7000 milliseconds = 7 seconds

		try {
			const response = await fetch(`${SERVER_URL}/fetch-preview`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ url: affiliateUrl }),
			});
			clearTimeout(stillWorkingTimeout); // Clear the timeout upon fetch completion
			if (response.ok) {
				setLoadingStep("Processing data...");
				const previewData = await response.json();
				setItemPreview(previewData);
				setLoadingStep("Preview fetched successfully.");
			} else {
				console.error("Error:", response);
				throw new Error("Failed to fetch item preview");
			}
		} catch (error) {
			setLoadingStep(`Error: ${error.message}`);
			console.error("Failed to fetch item preview:", error);
		} finally {
			setIsFetching(false);
			setShowStillWorking(false); // Ensure "still working" message is hidden after fetch
		}
	};

	const checkForDuplicateLink = async (affiliateUrl) => {
		// Assuming you're using Firestore as indicated in previous snippets
		const querySnapshot = await getDocs(query(collection(db, "catalogueItems"), where("url", "==", affiliateUrl)));
		return !querySnapshot.empty; // Returns true if a duplicate exists, false otherwise
	};

	const sanitizeAffiliateUrl = (url) => {
		// Trim leading and trailing spaces
		let cleanedUrl = url.trim();

		// Further cleaning can go here, e.g., removing or replacing specific characters
		// For example, to remove all spaces (not just leading/trailing), you could use:
		// cleanedUrl = cleanedUrl.replace(/\s+/g, '');

		return cleanedUrl;
	};

	const submitItemToDatabase = async () => {
		if (!category) {
			alert("Please select a category.");
			setCategoryError(true);
			return;
		}

		if (!selectedSubCategory) {
			alert("Please select a subcategory.");
			setCategoryError(true);
			return;
		}

		// Determine if we're using fetched data or manual entry
		const itemData = manualEntry
			? {
				name: itemName,
				description: itemDescription,
				price: itemPrice,
				image: itemImage,
				url: affiliateUrl,
				category: category,
				subCategory: selectedSubCategory,
			}
			: {
				name: itemPreview.title,
				description: itemPreview.description,
				price: itemPreview.price,
				image: itemPreview.imageUrl,
				url: affiliateUrl,
				category: category,
				subCategory: selectedSubCategory,
			};

		try {
			await addDoc(collection(db, "catalogueItems"), itemData);

			setCatalogueItems([...catalogueItems, itemData]);
			setLoadingStep("Item added successfully to the catalogue.");

			// Reset the form fields
			setAffiliateUrl("");
			setItemPreview(null);
			setCategory("");
			setSelectedSubCategory("");
			setCategoryError(false);

			fetchCatalogueItems();
		} catch (error) {
			console.error("Error adding item to catalogue:", error);
			setLoadingStep("Failed to add item to catalogue.");
		}
	};

	const [manualEntry, setManualEntry] = useState(false); // New state to toggle manual entry

	// Toggle function
	const toggleManualEntry = () => {
		setManualEntry(!manualEntry);
		// Reset item preview and manual entry fields
		setItemPreview(null);
		setItemName("");
		setItemDescription("");
		setItemPrice("");
		setItemImage("");
	};

	// Function to delete an item
	const deleteItem = async (itemId) => {
		await deleteDoc(doc(db, "catalogueItems", itemId));
		fetchCatalogueItems(); // Refetch items after deletion
	};

	const handlePriceChange = (event) => {
		setSelectedPrice(event.target.value);
	};

	const handleIndividualPriceChange = (productId, newPrice) => {
		setPriceUpdates(prev => ({ ...prev, [productId]: newPrice }));
	};

	// Function to handle product selection
	const handleSelectProduct = (productId) => {
		setSelectedProducts(prevSelectedProducts =>
			prevSelectedProducts.includes(productId)
				? prevSelectedProducts.filter(id => id !== productId)
				: [...prevSelectedProducts, productId]
		);
	};

	const [showUpdatePopup, setShowUpdatePopup] = useState(false);
	const [estimatedTime, setEstimatedTime] = useState(0);

	const updateProducts = async () => {
		if (selectedProducts.length === 0) {
			alert('Please select at least one product.');
			return;
		}

		const updates = {};
		if (selectedCategory) updates.category = selectedCategory;
		if (bulkPrice) updates.price = bulkPrice;
		if (bulkDescription) updates.description = bulkDescription;

		// Estimate time based on the number of selected products
		const estimatedTimeInSeconds = selectedProducts.length * 2; // Example: 2 seconds per product
		setEstimatedTime(estimatedTimeInSeconds);
		setShowUpdatePopup(true);

		try {
			for (const productId of selectedProducts) {
				const productRef = doc(db, "catalogueItems", productId);
				await updateDoc(productRef, updates);
			}

			alert('Products updated successfully!');
			fetchCatalogueItems(); // Refetch products to get updated data
			setSelectedProducts([]); // Clear selections
			setSelectedCategory('');
			setBulkPrice('');
			setBulkDescription('');
		} catch (error) {
			console.error('Error updating products:', error);
			alert('Error updating products');
		} finally {
			setShowUpdatePopup(false);
		}
	};

	// Add this function inside your component
	const toggleSelectAllProducts = () => {
		if (selectedProducts.length === catalogueItems.filter(item => item.category === filter).length) {
			setSelectedProducts([]);
		} else {
			setSelectedProducts(catalogueItems.filter(item => item.category === filter).map(item => item.id));
		}
	};

	// To make category dropdown red if there's an error
	const categorySelectStyle = categoryError ? { ...styles.input, borderColor: "red" } : styles.input;
	//     https://amzn.to/3UCGBby

	const handleEmailParamChange = (param, value) => {
		setEmailParams((prevParams) => ({
			...prevParams,
			[param]: value,
		}));
	};

	// Update the handleTestEmailSubmit function
	const handleTestEmailSubmit = async (event) => {
		event.preventDefault();
		try {
			// In development, override recipient email
			const finalRecipientEmail = isDevelopment ? "trevorhawkins@dsignable.com" : recipientEmail;

			// Create detailed test parameters
			const testParams = {
				email: finalRecipientEmail,
				emailType,
				timestamp: new Date().toISOString(),
				environment: isDevelopment ? 'development' : 'production',
				...(emailType === "contact" && {
					name: emailParams.name || "Test User",
					subject: emailParams.subject || "Test Contact Form",
					message: emailParams.message || "This is a test contact form submission",
					phone: emailParams.phone || "555-555-5555",
					// Add metadata for tracking
					metadata: {
						isTest: true,
						testId: Date.now().toString(),
						adminEmail: currentUserData?.email
					}
				})
			};

			setEmailTestResult("Sending email...");

			const response = await fetch(`${SERVER_URL}/test-email`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"X-Test-Email": "true",
					"X-Admin-Email": currentUserData?.email || "unknown"
				},
				body: JSON.stringify(testParams)
			});

			if (!response.ok) {
				const errorData = await response.json();
				throw new Error(errorData.message || "Failed to send test email");
			}

			const result = await response.json();
			
			// Show detailed success message
			setEmailTestResult(`
				Email Status: ${result.status || 'Sent'}
				Recipient: ${finalRecipientEmail}
				Type: ${emailType}
				Message ID: ${result.messageId || 'N/A'}
				Server Response: ${result.message || 'Success'}
				Time: ${new Date().toLocaleString()}
			`);

			// Log for debugging
			console.log("Email Test Details:", {
				recipient: finalRecipientEmail,
				type: emailType,
				params: testParams,
				response: result
			});

		} catch (error) {
			console.error("Email Test Error:", error);
			setEmailTestResult(`
				Failed to send test email:
				Error: ${error.message}
				Time: ${new Date().toLocaleString()}
				Recipient: ${finalRecipientEmail}
				Type: ${emailType}
			`);
			alert("Failed to send test email! Check console for details.");
		}
	};

	// Add a styled result display component
	const EmailTestResult = ({ result }) => {
		if (!result) return null;
		
		const isError = result.includes("Failed");
		
		return (
			<View style={[
				styles.testResultContainer,
				isError ? styles.errorResult : styles.successResult
			]}>
				<Text style={[
					styles.testResultText,
					isError ? styles.errorText : styles.successText
				]}>
					{result.split('\n').map((line, i) => (
						<Text key={i}>{line}{'\n'}</Text>
					))}
				</Text>
			</View>
		);
	};

	const scanForUidMismatches = async () => {
		try {
		const usersRef = collection(db, "users");
		const querySnapshot = await getDocs(usersRef);
		const mismatchedUsers = [];

		querySnapshot.forEach((doc) => {
				try {
			const userData = doc.data();
					const uid = userData?.uid;
					const eventsUrl = userData?.pages?.events;
					const registryUrl = userData?.pages?.registry;

					// Only process UIDs that exist and start with "G"
					if (uid && typeof uid === 'string' && uid.startsWith("G")) {
				if (
					(eventsUrl && !eventsUrl.includes(uid)) ||
					(registryUrl && !registryUrl.includes(uid))
				) {
					mismatchedUsers.push({
								firstName: userData.firstName || 'Unknown',
								lastName: userData.lastName || 'Unknown',
								email: userData.email || 'No Email',
						uid: uid,
						eventsUrl: eventsUrl || "N/A",
						registryUrl: registryUrl || "N/A",
					});
				}
					}
				} catch (err) {
					console.error("Error processing user document:", err);
			}
		});

		if (mismatchedUsers.length > 0) {
			await alertMismatchedUsers(mismatchedUsers);
			setMismatchedUsers(mismatchedUsers); // Update state to display on the admin page
			}
		} catch (error) {
			console.error("Error scanning for UID mismatches:", error);
		}
	};

	const alertMismatchedUsers = async (mismatchedUsers) => {
		try {
			const response = await fetch(`${SERVER_URL}/alert-mismatched-users`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ mismatchedUsers }),
			});

			if (!response.ok) {
				throw new Error("Failed to send alert email");
			}
		} catch (error) {
			console.error("Error sending alert email:", error);
		}
	};

	const toggleCatalogueVisibility = async (field) => {
		try {
			const docRef = doc(db, "maintenance", "catalogueVisibility");
			const docSnap = await getDoc(docRef);

			if (docSnap.exists()) {
				const currentData = docSnap.data();
				const newValue = !currentData[field];
				await updateDoc(docRef, {
					[field]: newValue
				});
				setVisibilitySettings((prevSettings) => ({
					...prevSettings,
					[field]: newValue
				}));
			} else {
				// If the document doesn't exist, create it with default values
				await setDoc(docRef, {
					hidePrice: false,
					hideImages: false,
					hideAll: false
				});
				fetchVisibilitySettings(); // Fetch again to update state
			}
		} catch (error) {
			console.error("Error updating catalogue visibility:", error);
		}
	};

	const [visibilitySettings, setVisibilitySettings] = useState({
		hidePrice: false,
		hideImages: false,
		hideAll: false
	});

	const fetchVisibilitySettings = async () => {
		try {
			const docRef = doc(db, "maintenance", "catalogueVisibility");
			const docSnap = await getDoc(docRef);

			if (docSnap.exists()) {
				setVisibilitySettings(docSnap.data());
			}
		} catch (error) {
			console.error("Error fetching visibility settings:", error);
		}
	};

	useEffect(() => {
		fetchVisibilitySettings();
	}, []);

	// Button styles based on visibility settings
	const getButtonStyle = (field) => {
		return visibilitySettings[field] ? styles.buttonActive : styles.buttonInactive;
	};

	const handleBlogSubmit = async (e) => {
		if (e) e.preventDefault();
		try {
			const blogData = {
				title: blogTitle,
				content: blogContent,
				category: blogCategory.trim(), // Ensure category is trimmed
				imageUrl: blogImage,
				tags: blogTags,
				author: currentUserData?.email,
				createdAt: publishDate || new Date().toISOString(),
				updatedAt: lastUpdatedDate || new Date().toISOString()
			};

			console.log("Saving blog post with category:", blogData.category); // Debug log

			if (editingBlogId) {
				await updateDoc(doc(db, "blog", editingBlogId), blogData);
			} else {
				await addDoc(collection(db, "blog"), blogData);
			}

			// Reset form
			resetForm();
			// Refresh blog posts
			fetchBlogPosts();
		} catch (error) {
			console.error("Error saving blog post:", error);
			alert("Error saving blog post. Please try again.");
		}
	};

	const resetForm = () => {
		setBlogTitle("");
		setBlogContent("");
		setBlogCategory("");
		setBlogImage("");
		setBlogTags([]);
		setPublishDate("");
		setLastUpdatedDate("");
		setEditingBlogId(null);
	};

	const fetchBlogPosts = async () => {
		const querySnapshot = await getDocs(collection(db, "blog"));
		const posts = querySnapshot.docs.map(doc => ({
			id: doc.id,
			...doc.data()
		}));
		setBlogPosts(posts);
	};

	useEffect(() => {
		fetchBlogPosts();
	}, []);

	const checkMismatchedUsers = async () => {
		const usersRef = collection(db, "users");
		const snapshot = await getDocs(usersRef);
		const mismatched = [];

		snapshot.forEach(doc => {
			const userData = doc.data();
			if (userData.email && userData.uid && userData.email.toLowerCase() !== doc.id.toLowerCase()) {
				mismatched.push({ ...userData, id: doc.id });
			}
		});

		setMismatchedUsers(mismatched);
	};

	const fixUserMismatch = async (docId) => {
		try {
			const userRef = doc(db, "users", docId);
			await updateDoc(userRef, {
				email: docId.toLowerCase()
			});
			await checkMismatchedUsers(); // Refresh the list
		} catch (error) {
			console.error("Error fixing user mismatch:", error);
		}
	};

	const sendTestEmail = async () => {
		try {
			// Implement your email sending logic here
			alert(`Test email would be sent to ${testEmail}`);
		} catch (error) {
			console.error("Error sending test email:", error);
		}
	};

	const toggleMaintenanceMode = async (enabled) => {
		try {
			const configRef = doc(db, "config", "maintenance");
			await setDoc(configRef, { enabled }, { merge: true });
			setMaintenanceMode(enabled);
		} catch (error) {
			console.error("Error toggling maintenance mode:", error);
		}
	};

	const toggleRegistration = async (disabled) => {
		try {
			const configRef = doc(db, "config", "registration");
			await setDoc(configRef, { disabled }, { merge: true });
			setDisableRegistration(disabled);
		} catch (error) {
			console.error("Error toggling registration:", error);
		}
	};

	// Add this state near the other blog states
	const [showPreview, setShowPreview] = useState(false);

	// Add this function to format the date
	const formatDate = (dateString) => {
		return new Date(dateString).toLocaleDateString();
	};

	// Add this function to handle tag input
	const handleTagInput = (e) => {
		if (e.key === 'Enter' && e.target.value) {
			e.preventDefault();
			const newTag = e.target.value.trim();
			if (!blogTags.includes(newTag)) {
				setBlogTags([...blogTags, newTag]);
			}
			e.target.value = '';
		} else if (e.key === 'Backspace' && e.target.value === '' && blogTags.length > 0) {
			setBlogTags(blogTags.slice(0, -1));
		}
	};

	const handlePresetTagClick = (tag) => {
		if (!blogTags.includes(tag)) {
			setBlogTags([...blogTags, tag]);
		}
	};

	// Add this function to get filtered and sorted users
	const getFilteredUsers = () => {
		return users
			.filter(user => {
				if (userSearchQuery) {
					const searchLower = userSearchQuery.toLowerCase();
					return (
						user.email?.toLowerCase().includes(searchLower) ||
						user.firstName?.toLowerCase().includes(searchLower) ||
						user.lastName?.toLowerCase().includes(searchLower)
					);
				}
				
				switch (userFilter) {
					case 'stripe_connected':
						return !!user.stripeAccountId;
					case 'stripe_pending':
						return !user.stripeAccountId;
					case 'admin':
						return user.accountType === 'admin';
					case 'student':
						return user.accountType === 'student';
					default:
						return true;
				}
			})
			.sort((a, b) => {
				switch (userSort) {
					case 'newest':
						return new Date(b.lastLogin || 0) - new Date(a.lastLogin || 0);
					case 'oldest':
						return new Date(a.lastLogin || 0) - new Date(b.lastLogin || 0);
					case 'alphabetical':
						return `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`);
					default:
						return 0;
				}
			});
	};

	// Add function to handle Stripe reset
	const handleStripeReset = async (user) => {
		setUserToResetStripe(user);
		setShowStripeResetConfirm(true);
	};

	const confirmStripeReset = async () => {
		if (adminPassword !== "Newtjhawk27") {
			alert("Invalid admin password");
			return;
		}

		try {
			setUserActionLoading(true);
			// Use email as the document ID instead of uid
			const userRef = doc(db, "users", userToResetStripe.email);
			
			// First verify the document exists
			const userDoc = await getDoc(userRef);
			if (!userDoc.exists()) {
				throw new Error("User document not found");
			}

			// Update the document with reset Stripe fields
			await updateDoc(userRef, {
				stripeAccountId: "", // This will remove the field
				stripeSetupComplete: false
			});

			// Also make API call to disconnect from Stripe if needed
			try {
				await axios.post(`${SERVER_URL}/disconnect-stripe-account`, {
					userId: userToResetStripe.uid,
					stripeAccountId: userToResetStripe.stripeAccountId
				});
			} catch (stripeError) {
				console.warn("Warning: Could not disconnect Stripe account:", stripeError);
				// Continue anyway as we've already reset the local data
			}

			alert("Stripe configuration reset successfully");
			
			// Refresh the users list
			fetchAllUsers();
			
		} catch (error) {
			console.error("Error resetting Stripe:", error);
			alert(`Error resetting Stripe configuration: ${error.message}`);
		} finally {
			setUserActionLoading(false);
			setShowStripeResetConfirm(false);
			setAdminPassword("");
			setUserToResetStripe(null);
		}
	};

	// Initialize analytics when the component mounts
	useEffect(() => {
		if (showAdmin) {
			// Initialize analytics service
			AnalyticsService.initAnalytics();
		}
	}, [showAdmin]);

	// Add this function to fetch share analytics
	const fetchShareAnalytics = async () => {
		try {
			const shareAnalyticsRef = collection(db, "shareAnalytics");
			const shareAnalyticsSnapshot = await getDocs(shareAnalyticsRef);
			const analytics = {};
			
			shareAnalyticsSnapshot.forEach(doc => {
				const data = doc.data();
				if (data && data.userEmail) {
					// Use email as the key instead of userId
					analytics[data.userEmail.toLowerCase()] = {
						totalShares: data.totalShares || 0,
						emailShares: Array.isArray(data.emailShares) ? data.emailShares : [],
						smsShares: Array.isArray(data.smsShares) ? data.smsShares : [],
						lastShareDate: data.lastShareDate || null,
						userId: data.userId,
						firstName: data.firstName,
						lastName: data.lastName,
						userEmail: data.userEmail.toLowerCase()
					};
				}
			});
			
			console.log(`Loaded share analytics for ${Object.keys(analytics).length} users`);
			setShareAnalytics(analytics);
		} catch (error) {
			console.error("Error fetching share analytics:", error);
		}
	};

	if (loading) {
		return <div style={styles.loading}>Loading...</div>;
	}

	if (error) {
	return (
			<View style={styles.errorContainer}>
				<Text style={styles.errorTitle}>Something went wrong</Text>
				<Text style={styles.errorText}>{error}</Text>
				<Pressable 
					style={styles.errorButton}
					onPress={() => window.location.reload()}
				>
					<Text style={styles.errorButtonText}>Reload Page</Text>
				</Pressable>
			</View>
		);
	}

	// Simplified render method
	return (
		<View style={styles.container}>
			{!currentUserData || currentUserData?.accountType !== "admin" ? (
				<View style={styles.loginContainer}>
					<Text style={styles.loginText}>You must be an admin to access this page.</Text>
				</View>
									) : (
										<>
					<View style={styles.tabContainer}>
						{tabs.map((tab) => (
							<Pressable
								key={tab.id}
								style={[
									styles.tabButton,
									activeTab === tab.id && styles.activeTabButton,
								]}
								onPress={() => setActiveTab(tab.id)}
							>
								<Text
									style={[
										styles.tabButtonText,
										activeTab === tab.id && styles.activeTabButtonText,
									]}
								>
									{tab.label}
								</Text>
							</Pressable>
						))}
								</View>

					<View style={styles.contentWrapper}>
						<Text style={styles.header}>
							{tabs.find(tab => tab.id === activeTab)?.label || 'Admin Dashboard'}
						</Text>
						
						{activeTab === "organizations" && (
							<View style={styles.section}>
								{/* Pending Organizations Section */}
								<Text style={styles.subHeader}>Pending Organizations</Text>
								<View style={styles.tableWrapper}>
									<table style={styles.table}>
										<thead>
											<tr>
												<th style={{
													...styles.tableHeader, 
													width: isMobile ? '150px' : '20%'
												}}>Name</th>
												<th style={{...styles.tableHeader, width: '20%'}}>Address</th>
												<th style={{...styles.tableHeader, width: '25%'}}>Website</th>
												<th style={{...styles.tableHeader, width: '15%'}}>Type</th>
												<th style={{...styles.tableHeader, width: '20%'}}>Actions</th>
											</tr>
										</thead>
										<tbody>
											{organizations.map((org) => (
												<tr key={org.id} style={styles.tableRow}>
													<td>{org.name}</td>
													<td>{org.location}</td>
													<td>{org.website}</td>
													<td>{org.organizationType}</td>
													
													<td>
														<button onClick={() => handleApprove(org)} style={styles.buttonApprove}>
															Approve
														</button>
														<button onClick={() => handleDeny(org)} style={styles.buttonDeny}>
															Deny
														</button>
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</View>

								{/* Approved Organizations Section */}
								<Text style={styles.subHeader}>Approved Organizations</Text>
								<View style={styles.tableWrapper}>
									<table style={styles.table}>
										<thead>
											<tr>
												<th style={{
													...styles.tableHeader, 
													width: isMobile ? '150px' : '20%'
												}}>Name</th>
												<th style={{...styles.tableHeader, width: '15%'}}>Address</th>
												<th style={{...styles.tableHeader, width: '25%'}}>Website</th>
												<th style={{...styles.tableHeader, width: '15%'}}>Type</th>
												<th style={{...styles.tableHeader, width: '10%'}}>Code</th>
												<th style={{...styles.tableHeader, width: '15%'}}>Reevaluate</th>
											</tr>
										</thead>
										<tbody>
											{approvedOrgs.map((org) => (
												<tr key={org.id} style={styles.tableRow}>
													<td>{org.name}</td>
													<td>{org.location}</td>
													<td>{org.website}</td>
													<td>{org.organizationType}</td>
													<td>{org.code}</td>
													<td>
														<button onClick={() => handleReevaluate(org, setApprovedOrgs, approvedOrgs)} style={styles.buttonReevaluate}>
															Reevaluate
														</button>
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</View>
								<button onClick={uploadApproved} style={styles.buttonApprove}>
									Upload Approved
								</button>

								{/* Denied Organizations Section */}
								<Text style={styles.subHeader}>Denied Organizations</Text>
								<View style={styles.tableWrapper}>
									<table style={styles.table}>
										<thead>
											<tr>
												<th style={{
													...styles.tableHeader, 
													width: isMobile ? '150px' : '25%'
												}}>Name</th>
												<th style={{...styles.tableHeader, width: '20%'}}>Address</th>
												<th style={{...styles.tableHeader, width: '25%'}}>Website</th>
												<th style={{...styles.tableHeader, width: '15%'}}>Type</th>
												<th style={{...styles.tableHeader, width: '15%'}}>Reevaluate</th>
											</tr>
										</thead>
										<tbody>
											{deniedOrgs.map((org) => (
												<tr key={org.id} style={styles.tableRow}>
													<td>{org.name}</td>
													<td>{org.location}</td>
													<td>{org.website}</td>
													<td>{org.organizationType}</td>
													<td>
														<button onClick={() => handleReevaluate(org, setDeniedOrgs, deniedOrgs)} style={styles.buttonReevaluate}>
															Reevaluate
														</button>
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</View>
								<button onClick={purgeDenied} style={styles.buttonDeny}>
									Purge Denied
								</button>
							</View>
						)}

						{activeTab === "users" && (
							<View style={styles.section}>
								<View style={styles.sectionHeader}>
									<Text style={styles.subHeader}>User Management</Text>
									<View style={styles.userStats}>
										<View style={styles.statCard}>
											<Text style={styles.statNumber}>{users.length}</Text>
											<Text style={styles.statLabel}>Total Users</Text>
										</View>
										<View style={styles.statCard}>
											<Text style={styles.statNumber}>
												{users.filter(user => user.emailVerified).length}
											</Text>
											<Text style={styles.statLabel}>Verified Users</Text>
										</View>
										<View style={styles.statCard}>
											<Text style={styles.statNumber}>
												{users.filter(user => user.accountType === 'admin').length}
											</Text>
											<Text style={styles.statLabel}>Admins</Text>
										</View>
									</View>
								</View>

								<View style={styles.controlsContainer}>
									<View style={styles.searchContainer}>
										<TextInput
											placeholder="Search users..."
											value={userSearchQuery}
											onChangeText={setUserSearchQuery}
											style={styles.searchInput}
										/>
									</View>

									<View style={styles.filterContainer}>
										<select 
											value={userFilter}
											onChange={(e) => setUserFilter(e.target.value)}
											style={styles.select}
										>
											<option value="all">All Users</option>
											<option value="stripe_connected">Stripe Setup</option>
											<option value="stripe_pending">Stripe Pending</option>
											<option value="admin">Admins Only</option>
											<option value="student">Students Only</option>
										</select>

										<select 
											value={userSort}
											onChange={(e) => setUserSort(e.target.value)}
											style={styles.select}
										>
											<option value="newest">Newest First</option>
											<option value="oldest">Oldest First</option>
											<option value="alphabetical">Alphabetical</option>
										</select>
									</View>
								</View>

								<View style={styles.tableContainer}>
									<table style={styles.table}>
										<thead>
											<tr>
												<th style={styles.tableHeader}>Email</th>
												<th style={styles.tableHeader}>Name</th>
												<th style={styles.tableHeader}>Account Type</th>
												<th style={styles.tableHeader}>Stripe Status</th>
												<th style={styles.tableHeader}>Privacy Status</th>
												<th style={styles.tableHeader}>Share Analytics</th>
												<th style={styles.tableHeader}>Actions</th>
											</tr>
										</thead>
										<tbody>
											{getFilteredUsers().map(user => (
												<tr key={user.id} style={styles.tableRow}>
													<td style={styles.tableCell}>{user.email}</td>
													<td style={styles.tableCell}>{`${user.firstName || ''} ${user.lastName || ''}`}</td>
													<td style={styles.tableCell}>
														<span style={{
															color: user.accountType === 'admin' ? '#2c6e49' : '#64748b',
															fontWeight: 'bold'
														}}>
															{user.accountType || 'user'}
														</span>
													</td>
													<td style={styles.tableCell}>
														<span style={{
															color: user.stripeAccountId ? '#4ade80' : '#fbbf24',
															fontWeight: 'bold'
														}}>
															{user.stripeAccountId ? 'Setup Complete' : 'Pending Setup'}
														</span>
													</td>
													<td style={styles.tableCell}>
														<span style={{
															color: user.isFriendsListPrivate ? '#ef4444' : '#4ade80',
															fontWeight: 'bold'
														}}>
															{user.isFriendsListPrivate ? 'Private' : 'Public'}
														</span>
													</td>
													<td style={styles.tableCell}>
														{shareAnalytics[user.email?.toLowerCase()] ? (
															<View style={styles.shareAnalyticsCell}>
																<View style={styles.shareAnalyticsPills}>
																	<View style={styles.sharePill}>
																		<Text style={styles.sharePillCount}>{shareAnalytics[user.email?.toLowerCase()]?.totalShares || 0}</Text>
																		<Text style={styles.sharePillLabel}>Total</Text>
																	</View>
																	<View style={styles.sharePill}>
																		<Text style={styles.sharePillCount}>{shareAnalytics[user.email?.toLowerCase()]?.emailShares?.length || 0}</Text>
																		<Text style={styles.sharePillLabel}>Email</Text>
																	</View>
																	<View style={styles.sharePill}>
																		<Text style={styles.sharePillCount}>{shareAnalytics[user.email?.toLowerCase()]?.smsShares?.length || 0}</Text>
																		<Text style={styles.sharePillLabel}>SMS</Text>
																	</View>
																</View>
																{shareAnalytics[user.email?.toLowerCase()]?.totalShares > 0 && (
																	<button
																		onClick={() => {
																			setSelectedUser(user);
																			setIsUserModalOpen(true);
																		}}
																		style={styles.viewDetailsButton}
																	>
																		View Details
																	</button>
																)}
															</View>
														) : (
															<Text style={styles.noShares}>No shares</Text>
														)}
													</td>
													<td style={styles.tableCell}>
														<View style={styles.actionButtons}>
															<button
																onClick={() => handleResetUserPassword(user.email)}
																style={styles.actionButton}
																disabled={userActionLoading}
															>
																Reset Password
															</button>
															<button
																onClick={() => handleUpdateUserEmail(user.id, user.email)}
																style={styles.actionButton}
																disabled={userActionLoading}
															>
																Update Email
															</button>
															<button
																onClick={() => handleDeleteUser(user.id, user.email)}
																style={{...styles.actionButton, backgroundColor: '#fee2e2', color: '#ef4444'}}
																disabled={userActionLoading}
															>
																Delete
															</button>
															<button
																onClick={() => handleStripeReset(user)}
																style={{...styles.actionButton, color: '#ef4444'}}
																disabled={userActionLoading}
															>
																Reset Stripe
															</button>
															{user.pages?.registry && (
																<button
																	onClick={() => {
																		const baseUrl = user.pages.registry;
																		const url = user.uic ? `${baseUrl}?uic=${user.uic}` : baseUrl;
																		window.open(url, '_blank');
																	}}
																	style={{...styles.actionButton, backgroundColor: '#ecfdf5', color: '#059669'}}
																>
																	View Registry
																</button>
															)}
															{user.pages?.events && (
																<button
																	onClick={() => {
																		const baseUrl = user.pages.events;
																		const url = user.uic ? `${baseUrl}?uic=${user.uic}` : baseUrl;
																		window.open(url, '_blank');
																	}}
																	style={{...styles.actionButton, backgroundColor: '#ecfdf5', color: '#059669'}}
																>
																	View Events
																</button>
															)}
														</View>
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</View>
							</View>
						)}

						{activeTab === "analytics" && (
							<View style={styles.analyticsContainer}>
								<Analytics />
							</View>
						)}

						{activeTab === "catalogue" && (
							<View style={styles.section}>
								{/* Add Affiliates Section */}
								<Text style={styles.subHeader}>Affiliate Link Details</Text>
								<View>
									<Text style={{fontSize: 18, marginBottom: 10}}>Toggle Catalogue Visibility</Text>
									<View style={styles.visibilityButtonContainer}>
										<button 
											onClick={() => toggleCatalogueVisibility('hidePrice')} 
											style={{
												...styles.visibilityButton,
												...(visibilitySettings.hidePrice && styles.visibilityButtonActive)
											}}
										>
											{visibilitySettings.hidePrice ? "Show Price" : "Hide Price"}
										</button>
										<button 
											onClick={() => toggleCatalogueVisibility('hideImages')} 
											style={{
												...styles.visibilityButton,
												...(visibilitySettings.hideImages && styles.visibilityButtonActive)
											}}
										>
											{visibilitySettings.hideImages ? "Show Images" : "Hide Images"}
										</button>
										<button 
											onClick={() => toggleCatalogueVisibility('hideAll')} 
											style={{
												...styles.visibilityButton,
												...(visibilitySettings.hideAll && styles.visibilityButtonActive)
											}}
										>
											{visibilitySettings.hideAll ? "Show All" : "Hide All"}
										</button>
									</View>
								</View>

								{/* Add to Catalogue Section */}
								<View>
									<TextInput placeholder="Affiliate URL" value={affiliateUrl} onChangeText={setAffiliateUrl} style={styles.input} />
									<View>
										<select
											value={category}
											onChange={(e) => {
												setCategory(e.target.value);
												setSelectedSubCategory("");
											}}
											style={categorySelectStyle}
										>
											<option value="">Select a Category</option>
											{categories.map((cat) => (
												<option key={cat} value={cat}>
													{cat}
												</option>
											))}
										</select>

										<select
											value={selectedSubCategory}
											onChange={(e) => setSelectedSubCategory(e.target.value)}
											style={styles.input}
										>
											<option value="">Select a Subcategory</option>
											{subCategories[category]?.map((subCat) => (
												<option key={subCat} value={subCat}>
													{subCat}
												</option>
											))}
										</select>
									</View>

									{!manualEntry ? (
										<>
											<button onClick={fetchItemPreview} style={styles.buttonApprove}>
												Fetch Preview
											</button>
											<button onClick={toggleManualEntry} style={styles.buttonDeny}>
												Enter Details Manually
											</button>
										</>
									) : (
										<>
											<TextInput placeholder="Item Name" value={itemName} onChangeText={setItemName} style={styles.input} />
											<TextInput placeholder="Item Description" value={itemDescription} onChangeText={setItemDescription} style={styles.input} multiline />
											<TextInput placeholder="Item Price" value={itemPrice} onChangeText={setItemPrice} style={styles.input} />
											<TextInput placeholder="Item Image URL" value={itemImage} onChangeText={setItemImage} style={styles.input} />
											<button onClick={toggleManualEntry} style={styles.buttonDeny}>
												Fetch Preview Instead
											</button>
											<button
												onClick={submitItemToDatabase}
												style={category && selectedSubCategory && itemName && itemDescription && itemPrice && itemImage ? styles.buttonApprove : styles.buttonDisabled}
												disabled={!category || !selectedSubCategory || !itemName || !itemDescription || !itemPrice || !itemImage}
											>
												Add to Catalogue
											</button>
										</>
									)}
									{!isFetching && itemPreview && (
										<button
											onClick={submitItemToDatabase}
											style={category && selectedSubCategory ? styles.buttonApprove : styles.buttonDisabled}
											disabled={!category || !selectedSubCategory}
										>
											Add to Catalogue
										</button>
									)}

									{loadingStep && <View style={{ padding: 10, backgroundColor: "#f0f0f0", margin: 10, borderRadius: 5 }}><Text>{loadingStep}</Text></View>}
									{showStillWorking && <View style={{ padding: 10, backgroundColor: "#f0f0f0", margin: 10, borderRadius: 5 }}><Text>Still working...</Text></View>}
								</View>

								{itemPreview && (
									<View>
										<Text style={{fontSize: 18, marginBottom: 10}}>Item Preview:</Text>
										<img src={itemPreview.imageUrl} alt="Preview" style={{ width: 250, height: 250 }} />
										<Text>Name: {itemPreview.title}</Text>
										<Text>Description: {itemPreview.description}</Text>
										<Text>Price: {itemPreview.price}</Text>
										<button onClick={submitItemToDatabase} style={styles.buttonApprove}>
											Add to Catalogue
										</button>
									</View>
								)}

								{/* Catalogue Items Section with delete button */}
								<Text style={styles.subHeader}>Catalogue Items</Text>

								{/* UI for selecting filters and sorting methods */}
								<View style={styles.filterContainer}>
								<select
									value={filter}
									onChange={(e) => {
										setFilter(e.target.value);
										setCurrentPage(1);
										}}
										style={styles.filterSelect}
									>
									<option value="all">All Categories</option>
									{categories.map((category) => (
										<option key={category} value={category}>
											{category}
										</option>
									))}
								</select>

									<select 
										value={sort} 
										onChange={(e) => setSort(e.target.value)}
										style={styles.filterSelect}
									>
										<option value="none">Sort by: Default</option>
									<option value="priceLowHigh">Price: Low to High</option>
									<option value="priceHighLow">Price: High to Low</option>
								</select>
									
									<View style={{ flexDirection: 'row', alignItems: 'center', flex: 2 }}>
										<TextInput
											style={[styles.input, { flex: 1, marginRight: 10, marginBottom: 0 }]}
											placeholder="Search items..."
											value={searchInput}
											onChangeText={setSearchInput}
										/>
										<button 
											onClick={handleSearch}
											disabled={searchInput.trim().length < 3}
											style={searchInput.trim().length < 3 ? styles.buttonDisabled : styles.buttonApprove}
										>
											Search
										</button>
									</View>
								</View>

								{/* Pagination Controls */}
								<View style={styles.paginationContainer}>
									{totalPages > 10 ? (
										<>
											{/* Compact pagination for many pages */}
											<button
												style={styles.pageButton}
												onClick={() => handlePageChange(Math.max(1, currentPage - 1))}
												disabled={currentPage === 1}
											>
												&laquo;
											</button>
											
											{currentPage > 3 && (
												<button style={styles.pageButton} onClick={() => handlePageChange(1)}>1</button>
											)}
											
											{currentPage > 4 && <span style={styles.pageEllipsis}>...</span>}
											
											{Array.from({ length: 5 }, (_, i) => {
												const pageNum = Math.max(2, Math.min(currentPage - 2 + i, totalPages - 1));
												if (pageNum <= 1 || pageNum >= totalPages) return null;
												if (i === 0 && pageNum > 2) return null;
												if (i === 4 && pageNum < totalPages - 1) return null;
												return (
													<button
														key={pageNum}
														style={currentPage === pageNum ? styles.activePage : styles.pageButton}
														onClick={() => handlePageChange(pageNum)}
													>
														{pageNum}
													</button>
												);
											})}
											
											{currentPage < totalPages - 3 && <span style={styles.pageEllipsis}>...</span>}
											
											{currentPage < totalPages - 2 && (
												<button style={styles.pageButton} onClick={() => handlePageChange(totalPages)}>
													{totalPages}
												</button>
											)}
											
											<button
												style={styles.pageButton}
												onClick={() => handlePageChange(Math.min(totalPages, currentPage + 1))}
												disabled={currentPage === totalPages}
											>
												&raquo;
											</button>
										</>
									) : (
										// Simple pagination for few pages
										Array.from({ length: totalPages }, (_, index) => (
										<button
											key={index + 1}
											style={currentPage === index + 1 ? styles.activePage : styles.pageButton}
											onClick={() => handlePageChange(index + 1)}
										>
											{index + 1}
										</button>
										))
									)}
								</View>

								{/* Bulk Edit Section */}
								<View style={styles.bulkEdit}>
									<Text style={styles.bulkEditHeader}>Bulk Edit Selected Items</Text>
									
									<View style={{ display: 'grid', gridTemplateColumns: width < 768 ? '1fr' : 'repeat(2, 1fr)', gap: 10 }}>
									<select
										value={selectedCategory}
										onChange={(e) => setSelectedCategory(e.target.value)}
										style={styles.input}
									>
										<option value="">Change Category</option>
										{categories.map((category) => (
											<option key={category} value={category}>{category}</option>
										))}
									</select>

									<select
										value={selectedSubCategory}
										onChange={(e) => setSelectedSubCategory(e.target.value)}
										style={styles.input}
											disabled={!selectedCategory}
									>
										<option value="">Select a Subcategory</option>
										{subCategories[selectedCategory]?.map((subCat) => (
											<option key={subCat} value={subCat}>
												{subCat}
											</option>
										))}
									</select>
									</View>

									<View style={{ display: 'grid', gridTemplateColumns: width < 768 ? '1fr' : 'repeat(2, 1fr)', gap: 10 }}>
										<TextInput
										placeholder="New Price"
										value={bulkPrice}
											onChangeText={setBulkPrice}
										style={styles.input}
									/>
										<TextInput
										placeholder="New Description"
										value={bulkDescription}
											onChangeText={setBulkDescription}
										style={styles.input}
											multiline
										/>
									</View>
									
									<View style={styles.bulkEditButtons}>
										<button onClick={toggleSelectAllProducts} style={styles.buttonEdit}>
											{selectedProducts.length > 0 ? 'Deselect All' : 'Select All in Category'}
										</button>
										<button 
											onClick={updateProducts} 
											style={selectedProducts.length > 0 ? styles.buttonApprove : styles.buttonDisabled}
											disabled={selectedProducts.length === 0}
										>
											Apply Changes to {selectedProducts.length} item{selectedProducts.length !== 1 ? 's' : ''}
										</button>
								</View>
								</View>

								{/* Displaying Items */}
								<View style={styles.tableWrapper}>
								<table style={styles.table}>
										<thead>
											<tr>
												<th style={styles.tableHeader}>
													<input
														type="checkbox"
														checked={selectedProducts.length > 0 && selectedProducts.length === currentItems.length}
														onChange={toggleSelectAllProducts}
													/>
												</th>
												<th style={styles.tableHeader}>Image</th>
												<th style={styles.tableHeader}>Item Name</th>
												<th style={styles.tableHeader}>URL</th>
												<th style={styles.tableHeader}>Description</th>
												<th style={styles.tableHeader}>Price</th>
												<th style={styles.tableHeader}>Category</th>
												<th style={styles.tableHeader}>Subcategory</th>
												<th style={styles.tableHeader}>Action</th>
										</tr>
									</thead>
									<tbody>
											{currentItems.length > 0 ? (
												currentItems.map((item) => (
											<tr key={item.id} style={styles.tableRow}>
														<td style={styles.tableCell}>
													<input
														type="checkbox"
														checked={selectedProducts.includes(item.id)}
														onChange={() => handleSelectProduct(item.id)}
													/>
												</td>
														<td style={styles.tableCell}>
															<img src={item.image} alt={item.name} style={{ width: 80, height: 80, objectFit: 'cover' }} />
												</td>
														<td style={styles.tableCell}>{item.name}</td>
														<td style={styles.tableCell}>
															<a href={item.url} target="_blank" rel="noopener noreferrer" style={{ color: '#4c956c', textDecoration: 'underline' }}>
																{item.url.length > 30 ? item.url.substring(0, 30) + '...' : item.url}
															</a>
														</td>
														<td style={styles.tableCell}>{item.description.length > 50 ? `${item.description.substring(0, 50)}...` : item.description}</td>
														<td style={styles.tableCell}>{item.price.includes("$") ? item.price : `$${item.price}`}</td>
														<td style={styles.tableCell}>{item.category}</td>
														<td style={styles.tableCell}>
													<select
														value={item.subCategory || ""}
														onChange={(e) => handleSubCategoryChange(item.id, e.target.value)}
																style={styles.tableSelect}
													>
														<option value="">Select a Subcategory</option>
														{subCategories[item.category]?.map((subCat) => (
															<option key={subCat} value={subCat}>
																{subCat}
															</option>
														))}
													</select>
												</td>
														<td style={styles.tableCell}>
													<button
														onClick={() => deleteItem(item.id)}
														style={styles.buttonDeny}
													>
														Delete
													</button>
													<button
														onClick={() => window.open(item.url, '_blank')}
														style={styles.buttonApprove}
													>
																View
													</button>
												</td>
											</tr>
												))
											) : (
												<tr>
													<td colSpan="9" style={{ textAlign: 'center', padding: 20 }}>
														No items found. Try changing your search or filter criteria.
													</td>
												</tr>
											)}
									</tbody>
								</table>
								</View>
							</View>
						)}

						{activeTab === "blog" && (
							<View style={styles.section}>
								<View style={styles.blogSection}>
									{/* Blog Form */}
									<View style={styles.blogFormContainer}>
										<View style={styles.blogForm}>
											<Text style={{fontSize: 20, marginBottom: 15}}>{editingBlogId ? 'Edit Blog Post' : 'Create Blog Post'}</Text>
											<View>
												<TextInput
												placeholder="Blog Title"
												value={blogTitle}
													onChangeText={setBlogTitle}
												style={styles.input}
											/>
											
											<select 
												value={blogCategory}
												onChange={(e) => setBlogCategory(e.target.value)}
												style={styles.input}
											>
												<option value="">Select Category</option>
												{blogCategories.map(cat => (
													<option key={cat} value={cat}>{cat}</option>
												))}
											</select>

												<TextInput
												placeholder="Image URL"
												value={blogImage}
													onChangeText={setBlogImage}
												style={styles.input}
												/>

												<View style={styles.dateInputContainer}>
													<View style={styles.dateField}>
														<Text style={styles.dateLabel}>Publish Date:</Text>
													<input
														type="datetime-local"
														value={publishDate ? new Date(publishDate).toISOString().slice(0, 16) : ""}
														onChange={(e) => setPublishDate(new Date(e.target.value).toISOString())}
														style={styles.dateInput}
														/>
													</View>
													<View style={styles.dateField}>
														<Text style={styles.dateLabel}>Last Updated:</Text>
													<input
														type="datetime-local"
														value={lastUpdatedDate ? new Date(lastUpdatedDate).toISOString().slice(0, 16) : ""}
														onChange={(e) => setLastUpdatedDate(new Date(e.target.value).toISOString())}
														style={styles.dateInput}
													/>
													</View>
												</View>

												<View style={styles.blogContentContainer}>
													<TextInput
												placeholder="Blog content will appear here..."
												value={blogContent}
														onChangeText={setBlogContent}
														style={{...styles.input, minHeight: 300}}
														multiline
													/>
												</View>

											{/* Update the tag input section in the blog form */}
												<View style={styles.tagSection}>
													<Text style={styles.tagSectionLabel}>Tags</Text>
													<View style={styles.presetTagsContainer}>
													{presetBlogTags.map((tag) => (
														<button
															key={tag}
															type="button"
															onClick={() => handlePresetTagClick(tag)}
															style={{
																...styles.presetTagButton,
																	...(blogTags.includes(tag) && styles.presetTagButtonSelected)
															}}
														>
															{tag}
														</button>
													))}
													</View>
													<View style={styles.tagList}>
													{blogTags.map((tag, index) => (
															<View key={index} style={styles.tagPill}>
																<Text>{tag}</Text>
															<button 
																type="button"
																onClick={() => setBlogTags(blogTags.filter((_, i) => i !== index))}
																style={styles.tagRemove}
															>
																×
															</button>
															</View>
														))}
													</View>
													<TextInput
														placeholder="Add a tag and press Enter..."
														onKeyPress={handleTagInput}
														style={styles.tagInput}
													/>
												</View>

												<View style={styles.buttonRow}>
												<button 
														type="button"
														onClick={handleBlogSubmit}
													style={styles.primaryButton}
												>
													{editingBlogId ? 'Update Post' : 'Create Post'}
												</button>
												{editingBlogId && (
													<button 
														type="button"
															onClick={resetForm}
															style={styles.secondaryButton}
													>
														Cancel Edit
													</button>
												)}
												</View>
											</View>
										</View>

										{/* Blog Preview */}
										<View style={styles.blogPreview}>
											<Text style={{fontSize: 20, marginBottom: 15}}>Preview</Text>
											<View style={styles.previewContainer}>
											{blogImage && (
													<img 
														src={blogImage} 
														alt="Blog preview" 
														style={styles.previewImage} 
														onError={(e) => e.target.src = 'https://via.placeholder.com/800x400?text=Image+Not+Found'}
													/>
												)}
												<Text style={styles.previewTitle}>{blogTitle || 'Your Blog Title'}</Text>
												{blogCategory && <Text style={styles.previewCategory}>Category: {blogCategory}</Text>}
												
												<View style={styles.previewTagList}>
												{blogTags.map((tag, index) => (
														<View key={index} style={styles.previewTagPill}>
															<Text style={styles.previewTagText}>{tag}</Text>
														</View>
													))}
												</View>
												
												<View style={styles.previewContent}>
													{blogContent ? (
														<Text>{blogContent}</Text>
													) : (
														<Text style={styles.placeholderText}>Your blog content will appear here...</Text>
													)}
												</View>
												
												<View style={styles.previewMeta}>
													<Text style={styles.previewMetaText}>
														{publishDate ? `Published: ${new Date(publishDate).toLocaleDateString()}` : 'Not yet published'}
													</Text>
													{lastUpdatedDate && (
														<Text style={styles.previewMetaText}>
															Last updated: {new Date(lastUpdatedDate).toLocaleDateString()}
														</Text>
													)}
												</View>
											</View>
										</View>
									</View>

									{/* Blog List */}
									<View style={styles.blogList}>
										<Text style={{fontSize: 20, marginBottom: 15}}>Published Blog Posts</Text>
										{blogPosts.map((post) => (
											<View key={post.id} style={styles.blogPost}>
												<Text style={styles.blogTitle}>{post.title}</Text>
												<Text style={styles.blogMeta}>
													Category: {post.category} | Published: {formatDate(post.createdAt)}
												</Text>
												<View style={styles.tagList}>
													{post.tags?.map((tag, index) => (
														<View key={index} style={styles.tagPill}>
															<Text>{tag}</Text>
														</View>
													))}
												</View>
												<View style={styles.blogActions}>
												<button
													onClick={() => {
														setBlogTitle(post.title);
														setBlogContent(post.content);
														setBlogCategory(post.category);
														setBlogImage(post.imageUrl);
														setBlogTags(post.tags || []);
														setPublishDate(post.createdAt);
															setLastUpdatedDate(post.updatedAt || post.createdAt);
															setEditingBlogId(post.id);
													}}
													style={styles.buttonEdit}
												>
													Edit
												</button>
												<button
													onClick={async () => {
														if (window.confirm("Are you sure you want to delete this post?")) {
															await deleteDoc(doc(db, "blog", post.id));
															fetchBlogPosts();
														}
													}}
													style={styles.buttonDeny}
												>
													Delete
												</button>
												</View>
											</View>
									))}
									</View>
								</View>
							</View>
						)}

						{activeTab === "maintenance" && (
							<View style={styles.section}>
								<View style={styles.maintenanceTools}>
									{/* Maintenance Section */}
									<View style={styles.maintenanceSection}>
										<Text style={styles.subHeader}>System Maintenance</Text>
										<View style={styles.maintenanceStatusContainer}>
											{isMaintenance && (
												<View style={styles.maintenanceAlert}>
													<Text style={styles.maintenanceAlertText}>
														<span style={{ fontWeight: 'bold' }}>Maintenance Mode Active</span> - Started At: {new Date().toLocaleString()}
													</Text>
												</View>
											)}
											<button 
												style={isMaintenance ? styles.buttonDeny : styles.buttonApprove} 
												onClick={toggleMaintenance}
											>
												{isMaintenance ? "End Maintenance" : "Start Maintenance"}
											</button>
										</View>
										
										<View style={styles.maintenanceToggles}>
											<View style={styles.toggleItem}>
												<label style={styles.toggleLabel}>
													<input
														type="checkbox"
														checked={maintenanceMode}
														onChange={(e) => toggleMaintenanceMode(e.target.checked)}
														style={styles.toggleInput}
													/>
													<span style={styles.toggleText}>Maintenance Mode</span>
													<span style={styles.toggleDescription}>
														Display maintenance message to all users
													</span>
												</label>
											</View>
											<View style={styles.toggleItem}>
												<label style={styles.toggleLabel}>
													<input
														type="checkbox"
														checked={disableRegistration}
														onChange={(e) => toggleRegistration(e.target.checked)}
														style={styles.toggleInput}
													/>
													<span style={styles.toggleText}>Disable New Registrations</span>
													<span style={styles.toggleDescription}>
														Prevent new users from registering
													</span>
												</label>
											</View>
										</View>
									</View>

									<View style={styles.maintenanceSection}>
										{/* Email Test Form */}
										<Text style={styles.subHeader}>Test Emails</Text>
										<View id="email-test-form" style={styles.emailTestForm}>
											<View style={styles.formGroup}>
												<Text style={styles.formLabel}>Recipient Email:</Text>
												<TextInput
													type="email"
													value={recipientEmail}
													placeholder="Enter recipient's email address"
													onChangeText={setRecipientEmail}
													style={styles.input}
												/>
											</View>

											<View style={styles.formGroup}>
												<Text style={styles.formLabel}>Email Type:</Text>
												<select
													value={emailType}
													onChange={(e) => setEmailType(e.target.value)}
													style={styles.input}
												>
													<option style={styles.option} value="genericWelcome">Generic Welcome Email</option>
													<option style={styles.option} value="graduateWelcome">Graduate Welcome Email</option>
													<option style={styles.option} value="contribution">Contribution Email</option>
													<option style={styles.option} value="purchaseConfirmation">Purchase Confirmation Email</option>
													<option style={styles.option} value="yearbookMessage">Yearbook Message Notification</option>
													<option style={styles.option} value="accessRequest">Access Request Email</option>
													<option style={styles.option} value="goalReached">Goal Reached Email</option>
													<option style={styles.option} value="ecard">E-Card Email</option>
													<option style={styles.option} value="invitation">Invitation Email</option>
													<option style={styles.option} value="mismatchedUsersAlert">Mismatched Users Alert</option>
													<option style={styles.option} value="contact">Contact Form Email</option>
												</select>
											</View>

												{/* Conditional input fields based on email type */}
															<View style={styles.emailParamsContainer}>
																{emailType === "genericWelcome" && (
																	<View style={styles.formGroup}>
																		<Text style={styles.formLabel}>First Name:</Text>
																		<TextInput
																			placeholder="First Name"
																			onChangeText={(value) => handleEmailParamChange("firstName", value)}
																			style={styles.input}
																		/>
																	</View>
																)}

																{emailType === "graduateWelcome" && (
																	<View style={styles.formGroup}>
																		<Text style={styles.formLabel}>First Name:</Text>
																		<TextInput
																			placeholder="First Name"
																			onChangeText={(value) => handleEmailParamChange("firstName", value)}
																			style={styles.input}
																		/>
																	</View>
																)}

																{emailType === "contribution" && (
																	<>
																		<View style={styles.formGroup}>
																			<Text style={styles.formLabel}>Contribution Amount:</Text>
																			<TextInput
																			placeholder="Contribution Amount"
																				onChangeText={(value) => handleEmailParamChange("contributionAmount", value)}
																			style={styles.input}
																		/>
																		</View>
																		<View style={styles.formGroup}>
																			<Text style={styles.formLabel}>Contributor Name:</Text>
																			<TextInput
																			placeholder="Contributor Name"
																				onChangeText={(value) => handleEmailParamChange("contributorName", value)}
																			style={styles.input}
																		/>
																		</View>
																	</>
																)}

																{emailType === "purchaseConfirmation" && (
																	<>
																		<View style={styles.formGroup}>
																			<Text style={styles.formLabel}>Purchase Amount:</Text>
																			<TextInput
																			placeholder="Purchase Amount"
																				onChangeText={(value) => handleEmailParamChange("purchaseAmount", value)}
																			style={styles.input}
																		/>
																		</View>
																		<View style={styles.formGroup}>
																			<Text style={styles.formLabel}>Product Name:</Text>
																			<TextInput
																			placeholder="Product Name"
																				onChangeText={(value) => handleEmailParamChange("productName", value)}
																			style={styles.input}
																		/>
																		</View>
																	</>
																)}

																{emailType === "yearbookMessage" && (
																	<>
																		<View style={styles.formGroup}>
																			<Text style={styles.formLabel}>Yearbook Message:</Text>
																			<TextInput
																			placeholder="Yearbook Message"
																				onChangeText={(value) => handleEmailParamChange("yearbookMessage", value)}
																			style={styles.input}
																		/>
																		</View>
																		<View style={styles.formGroup}>
																			<Text style={styles.formLabel}>Recipient Name:</Text>
																			<TextInput
																			placeholder="Recipient Name"
																				onChangeText={(value) => handleEmailParamChange("recipientName", value)}
																			style={styles.input}
																		/>
																		</View>
																	</>
																)}

																{emailType === "accessRequest" && (
																	<>
																		<View style={styles.formGroup}>
																			<Text style={styles.formLabel}>Access Request:</Text>
																			<TextInput
																			placeholder="Access Request"
																				onChangeText={(value) => handleEmailParamChange("accessRequest", value)}
																			style={styles.input}
																		/>
																		</View>
																		<View style={styles.formGroup}>
																			<Text style={styles.formLabel}>Recipient Name:</Text>
																			<TextInput
																			placeholder="Recipient Name"
																				onChangeText={(value) => handleEmailParamChange("recipientName", value)}
																			style={styles.input}
																		/>
																		</View>
																	</>
																)}

																{emailType === "goalReached" && (
																	<>
																		<View style={styles.formGroup}>
																			<Text style={styles.formLabel}>Goal Reached:</Text>
																			<TextInput
																			placeholder="Goal Reached"
																				onChangeText={(value) => handleEmailParamChange("goalReached", value)}
																			style={styles.input}
																		/>
																		</View>
																		<View style={styles.formGroup}>
																			<Text style={styles.formLabel}>Recipient Name:</Text>
																			<TextInput
																			placeholder="Recipient Name"
																				onChangeText={(value) => handleEmailParamChange("recipientName", value)}
																			style={styles.input}
																		/>
																		</View>
																	</>
																)}

																{emailType === "ecard" && (
																	<>
																		<View style={styles.formGroup}>
																			<Text style={styles.formLabel}>E-Card Message:</Text>
																			<TextInput
																			placeholder="E-Card Message"
																				onChangeText={(value) => handleEmailParamChange("ecardMessage", value)}
																			style={styles.input}
																		/>
																		</View>
																		<View style={styles.formGroup}>
																			<Text style={styles.formLabel}>Recipient Name:</Text>
																			<TextInput
																			placeholder="Recipient Name"
																				onChangeText={(value) => handleEmailParamChange("recipientName", value)}
																			style={styles.input}
																		/>
																		</View>
																	</>
																)}

																{emailType === "invitation" && (
																	<>
																		<View style={styles.formGroup}>
																			<Text style={styles.formLabel}>Invitation Message:</Text>
																			<TextInput
																			placeholder="Invitation Message"
																				onChangeText={(value) => handleEmailParamChange("invitationMessage", value)}
																			style={styles.input}
																		/>
																		</View>
																		<View style={styles.formGroup}>
																			<Text style={styles.formLabel}>Recipient Name:</Text>
																			<TextInput
																			placeholder="Recipient Name"
																				onChangeText={(value) => handleEmailParamChange("recipientName", value)}
																			style={styles.input}
																		/>
																		</View>
																	</>
																)}

																{emailType === "mismatchedUsersAlert" && (
																	<>
																		<View style={styles.formGroup}>
																			<Text style={styles.formLabel}>Mismatched Users Alert:</Text>
																			<TextInput
																			placeholder="Mismatched Users Alert"
																				onChangeText={(value) => handleEmailParamChange("mismatchedUsersAlert", value)}
																			style={styles.input}
																		/>
																		</View>
																		<View style={styles.formGroup}>
																			<Text style={styles.formLabel}>Recipient Name:</Text>
																			<TextInput
																			placeholder="Recipient Name"
																				onChangeText={(value) => handleEmailParamChange("recipientName", value)}
																			style={styles.input}
																		/>
																		</View>
																	</>
																)}

																{emailType === "contact" && (
																	<View style={styles.emailParamsContainer}>
																		<View style={styles.formGroup}>
																			<Text style={styles.formLabel}>Name:</Text>
																			<TextInput
																				placeholder="Test User Name"
																				onChangeText={(value) => handleEmailParamChange("name", value)}
																				style={styles.input}
																			/>
																		</View>
																		<View style={styles.formGroup}>
																			<Text style={styles.formLabel}>Subject:</Text>
																			<TextInput
																				placeholder="Test Subject"
																				onChangeText={(value) => handleEmailParamChange("subject", value)}
																				style={styles.input}
																			/>
																		</View>
																		<View style={styles.formGroup}>
																			<Text style={styles.formLabel}>Message:</Text>
																			<TextInput
																				placeholder="Test Message"
																				onChangeText={(value) => handleEmailParamChange("message", value)}
																				style={styles.input}
																				multiline
																			/>
																		</View>
																		<View style={styles.formGroup}>
																			<Text style={styles.formLabel}>Phone:</Text>
																			<TextInput
																				placeholder="Test Phone Number"
																				onChangeText={(value) => handleEmailParamChange("phone", value)}
																				style={styles.input}
																			/>
																		</View>
																	</View>
																)}
															</View>

															<button 
																type="button" 
																onClick={handleTestEmailSubmit} 
																style={styles.buttonApprove}
															>
																Send Test Email
															</button>
															
															{emailTestResult && (
																<View style={styles.testResultContainer}>
																	<Text style={styles.testResultText}>{emailTestResult}</Text>
																</View>
															)}
														</View>
													</View>

													<View style={styles.maintenanceSection}>
														{/* SMS Testing Section */}
														<Text style={styles.subHeader}>SMS Testing</Text>
														<View style={styles.smsTestForm}>
															<View style={styles.formGroup}>
																<Text style={styles.formLabel}>Test Phone Number:</Text>
																<TextInput
															placeholder="Enter test phone number"
															value={testPhone}
																	onChangeText={setTestPhone}
															style={styles.input}
														/>
															</View>
															
															<View style={styles.formGroup}>
																<Text style={styles.formLabel}>SMS Type:</Text>
														<select
															value={smsType}
															onChange={(e) => setSmsType(e.target.value)}
															style={styles.input}
														>
															<option value="welcome">Welcome Message</option>
															<option value="invitation">Invitation Message</option>
															<option value="yearbook">Yearbook Notification</option>
														</select>
															</View>
															
														<button
															onClick={async () => {
																try {
																	const response = await fetch(`${SERVER_URL}/test-sms`, {
																		method: "POST",
																		headers: {
																			"Content-Type": "application/json",
																		},
																			body: JSON.stringify({ phone: testPhone, smsType }),
																	});
																	const result = await response.text();
																	setSmsTestResult(result);
																} catch (error) {
																		setSmsTestResult(`Error: ${error.message}`);
																}
															}}
																style={styles.buttonApprove}
														>
															Send Test SMS
														</button>
															
														{smsTestResult && (
																<View style={styles.testResultContainer}>
																	<Text style={styles.testResultText}>{smsTestResult}</Text>
																</View>
															)}
														</View>
													</View>

													<View style={styles.maintenanceSection}>
														{/* Mismatched Users Section */}
														<Text style={styles.subHeader}>Mismatched Users</Text>
														<View style={styles.mismatchedUsersContainer}>
															<button 
																onClick={checkMismatchedUsers} 
																style={styles.buttonApprove}
															>
																Scan for Mismatched Users
															</button>
															
															{mismatchedUsers.length > 0 ? (
																<View style={styles.tableWrapper}>
														<table style={styles.table}>
															<thead>
																<tr>
																						<th style={styles.tableHeader}>First Name</th>
																						<th style={styles.tableHeader}>Last Name</th>
																	<th style={styles.tableHeader}>Email</th>
																						<th style={styles.tableHeader}>UID</th>
																						<th style={styles.tableHeader}>Events URL</th>
																						<th style={styles.tableHeader}>Registry URL</th>
																</tr>
															</thead>
															<tbody>
																					{mismatchedUsers.map((user) => (
																						<tr key={user.uid} style={styles.tableRow}>
																							<td style={styles.tableCell}>{user.firstName}</td>
																							<td style={styles.tableCell}>{user.lastName}</td>
																			<td style={styles.tableCell}>{user.email}</td>
																							<td style={styles.tableCell}>{user.uid}</td>
																							<td style={styles.tableCell}>{user.eventsUrl}</td>
																							<td style={styles.tableCell}>{user.registryUrl}</td>
																						</tr>
																					))}
															</tbody>
														</table>
																	</View>
															) : (
																<View style={styles.noDataContainer}>
																	<Text style={styles.noDataText}>No mismatched users found</Text>
																</View>
															)}
														</View>
													</View>
												</View>
											</View>
										)}
									</View>
								</>
							)}
							{isUserModalOpen && selectedUser && (
								<View style={styles.modalOverlay}>
									<View style={styles.modalContent}>
										<Text style={styles.modalTitle}>Share Analytics for {selectedUser.firstName} {selectedUser.lastName}</Text>
										
										<View style={styles.shareStatsContainer}>
											<View style={styles.statPill}>
												<Text style={styles.statPillValue}>{shareAnalytics[selectedUser.email?.toLowerCase()]?.totalShares || 0}</Text>
												<Text style={styles.statPillLabel}>Total Shares</Text>
											</View>
											<View style={styles.statPill}>
												<Text style={styles.statPillValue}>{shareAnalytics[selectedUser.email?.toLowerCase()]?.emailShares?.length || 0}</Text>
												<Text style={styles.statPillLabel}>Email Shares</Text>
											</View>
											<View style={styles.statPill}>
												<Text style={styles.statPillValue}>{shareAnalytics[selectedUser.email?.toLowerCase()]?.smsShares?.length || 0}</Text>
												<Text style={styles.statPillLabel}>SMS Shares</Text>
											</View>
										</View>
										
										{shareAnalytics[selectedUser.email?.toLowerCase()]?.lastShareDate && (
											<Text style={styles.lastShareDate}>
												Last Share: {new Date(shareAnalytics[selectedUser.email?.toLowerCase()].lastShareDate).toLocaleDateString()} at {new Date(shareAnalytics[selectedUser.email?.toLowerCase()].lastShareDate).toLocaleTimeString()}
											</Text>
										)}
										
										<ScrollView style={styles.modalScrollView}>
											<View style={styles.shareTabs}>
												<View style={styles.shareTabsHeader}>
													<Text style={styles.shareTabTitle}>Email Shares</Text>
													<Text style={styles.shareTabTitle}>SMS Shares</Text>
												</View>
												
												<View style={styles.shareTabsContent}>
													{/* Email Shares Tab */}
													<View style={styles.shareTabPanel}>
														{shareAnalytics[selectedUser.email?.toLowerCase()]?.emailShares?.length > 0 ? (
															<View style={styles.shareList}>
																<View style={styles.shareListHeader}>
																	<Text style={styles.shareListHeaderCell}>Recipient</Text>
																	<Text style={styles.shareListHeaderCell}>Date</Text>
																	<Text style={styles.shareListHeaderCell}>Time</Text>
																</View>
																{shareAnalytics[selectedUser.email?.toLowerCase()].emailShares.map((share, index) => (
																	<View key={index} style={styles.shareItem}>
																		<Text style={styles.shareRecipient} numberOfLines={1}>{share.recipient}</Text>
																		<Text style={styles.shareDate}>{new Date(share.date).toLocaleDateString()}</Text>
																		<Text style={styles.shareTime}>{new Date(share.date).toLocaleTimeString()}</Text>
																	</View>
																))}
															</View>
														) : (
															<Text style={styles.noSharesText}>No email shares yet</Text>
														)}
													</View>
													
													{/* SMS Shares Tab */}
													<View style={styles.shareTabPanel}>
														{shareAnalytics[selectedUser.email?.toLowerCase()]?.smsShares?.length > 0 ? (
															<View style={styles.shareList}>
																<View style={styles.shareListHeader}>
																	<Text style={styles.shareListHeaderCell}>Recipient</Text>
																	<Text style={styles.shareListHeaderCell}>Date</Text>
																	<Text style={styles.shareListHeaderCell}>Time</Text>
																</View>
																{shareAnalytics[selectedUser.email?.toLowerCase()].smsShares.map((share, index) => (
																	<View key={index} style={styles.shareItem}>
																		<Text style={styles.shareRecipient} numberOfLines={1}>{share.recipient}</Text>
																		<Text style={styles.shareDate}>{new Date(share.date).toLocaleDateString()}</Text>
																		<Text style={styles.shareTime}>{new Date(share.date).toLocaleTimeString()}</Text>
																	</View>
																))}
															</View>
														) : (
															<Text style={styles.noSharesText}>No SMS shares yet</Text>
														)}
													</View>
												</View>
											</View>
										</ScrollView>
										
										<button
											onClick={() => setIsUserModalOpen(false)}
											style={styles.closeModalButton}
										>
											Close
										</button>
									</View>
								</View>
							)}
						</View>
					);
				};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		width: '100vw',
		maxWidth: '100%',
		minHeight: '100vh',
		backgroundColor: '#f1f5f9',
		padding: isMobile ? 16 : 32,
		overflowX: 'hidden',
	},

	contentWrapper: {
		width: '100%',
		maxWidth: '100%',
		margin: '0 auto',
		display: 'flex',
		flexDirection: 'column',
		gap: 24,
	},

	section: {
		backgroundColor: 'white',
		padding: isMobile ? 20 : 30,
		borderRadius: 16,
		marginBottom: 24,
		boxShadow: '0 4px 10px rgba(0,0,0,0.05)',
		width: '100%',
		maxWidth: '100%',
		overflowX: 'auto',
	},

	// Update any nested containers to ensure they respect the full width
	mainContent: {
		width: '100%',
		maxWidth: '100%',
		display: 'flex',
		flexDirection: 'column',
		gap: 24,
	},

	header: {
		fontSize: 24,
		fontWeight: 'bold',
		color: '#2c6e49',
		marginBottom: 30,
		textAlign: 'center',
	},
	tabContainer: {
		flexDirection: 'row',
		marginBottom: 30,
		borderBottomWidth: 1,
		borderBottomColor: '#e2e8f0',
		flexWrap: 'wrap',
		justifyContent: 'center',
	},
	tabButton: {
		paddingVertical: 12,
		paddingHorizontal: 20,
		marginRight: 8,
		borderTopLeftRadius: 8,
		borderTopRightRadius: 8,
		transition: 'all 0.2s ease',
	},
	activeTabButton: {
		backgroundColor: '#4c956c',
		borderBottomWidth: 0,
		boxShadow: '0 -4px 6px rgba(0,0,0,0.05)',
	},
	tabButtonText: {
		fontSize: 16,
		fontWeight: '600',
		color: '#64748b',
	},
	activeTabButtonText: {
		color: '#ffffff',
	},
	tabContent: {
		display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			width: '100%',
	},
	subHeader: {
		fontSize: 20,
		fontWeight: 'bold',
		color: '#4b5563',
		textAlign: 'center',
		marginBottom: 30,
	},
	tableWrapper: {
		width: '100%',
		overflowX: 'auto',
		padding: isMobile ? 5 : '10px',
		marginBottom: '20px',
		borderRadius: 8,
	},
	table: {
		width: '100%',
		borderCollapse: 'separate',
		borderSpacing: '0 8px',
		marginTop: 25,
		tableLayout: 'fixed',
		minWidth: isMobile ? 800 : 'auto', // Ensures table doesn't get too squeezed on mobile
	},
	tableHeader: {
		backgroundColor: '#f8fafc',
		padding: isMobile ? '12px 16px' : '15px 20px',
		textAlign: 'left',
		fontSize: isMobile ? 14 : 16,
		color: '#4b5563',
		fontWeight: '600',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		borderTopLeftRadius: 8,
		borderTopRightRadius: 8,
	},
	tableRow: {
		backgroundColor: 'white',
		boxShadow: '0 2px 4px rgba(0,0,0,0.02)',
		borderRadius: 8,
		transition: 'transform 0.2s ease, box-shadow 0.2s ease',
		':hover': {
			boxShadow: '0 4px 8px rgba(0,0,0,0.05)',
			transform: 'translateY(-2px)',
		}
	},
	tableCell: {
		padding: isMobile ? '12px 16px' : '15px 20px',
		fontSize: isMobile ? 14 : 16,
		color: '#4b5563',
		whiteSpace: 'normal',
		wordBreak: 'break-word',
		verticalAlign: 'middle',
	},
	input: {
		width: '100%',
		height: 45,
		padding: '12px 16px',
		margin: '8px 0',
		border: '1px solid #e2e8f0',
		borderRadius: 8,
		fontSize: 15,
		color: '#4b5563',
		backgroundColor: '#f9fafb',
		transition: 'all 0.2s ease',
		outline: 'none',
		':focus': {
			borderColor: '#4c956c',
			boxShadow: '0 0 0 3px rgba(76, 149, 108, 0.1)',
		},
	},
	// Updated button base styles
	buttonBase: {
		padding: '12px 24px',
		borderRadius: 8,
		fontSize: 15,
		fontWeight: '600',
		cursor: 'pointer',
		transition: 'all 0.2s ease',
		display: 'inline-flex',
		alignItems: 'center',
		justifyContent: 'center',
		minWidth: 120,
		border: 'none',
		boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
		margin: '4px',
		height: 45,
	},
	// Updated specific button styles
	buttonApprove: {
		backgroundColor: '#4c956c',
		color: 'white',
		padding: '12px 24px',
		borderRadius: 8,
		fontSize: 15,
		fontWeight: '600',
		cursor: 'pointer',
		transition: 'all 0.2s ease',
		display: 'inline-flex',
		alignItems: 'center',
		justifyContent: 'center',
		minWidth: 120,
		border: 'none',
		boxShadow: '0 2px 4px rgba(76, 149, 108, 0.2)',
		margin: '4px',
		height: 45,
		':hover': {
			backgroundColor: '#3a7254',
			transform: 'translateY(-1px)',
		},
	},
	buttonDeny: {
		backgroundColor: '#ef4444',
		color: 'white',
		padding: '12px 24px',
		borderRadius: 8,
		fontSize: 15,
		fontWeight: '600',
		cursor: 'pointer',
		transition: 'all 0.2s ease',
		display: 'inline-flex',
		alignItems: 'center',
		justifyContent: 'center',
		minWidth: 120,
		border: 'none',
		boxShadow: '0 2px 4px rgba(239, 68, 68, 0.2)',
		margin: '4px',
		height: 45,
		':hover': {
			backgroundColor: '#dc2626',
			transform: 'translateY(-1px)',
		},
	},
	buttonEdit: {
		backgroundColor: '#f59e0b',
		color: 'white',
		padding: '12px 24px',
		borderRadius: 8,
		fontSize: 15,
		fontWeight: '600',
		cursor: 'pointer',
		transition: 'all 0.2s ease',
		display: 'inline-flex',
		alignItems: 'center',
		justifyContent: 'center',
		minWidth: 120,
		border: 'none',
		boxShadow: '0 2px 4px rgba(245, 158, 11, 0.2)',
		margin: '4px',
		height: 45,
		':hover': {
			backgroundColor: '#d97706',
			transform: 'translateY(-1px)',
		},
	},
	buttonDisabled: {
		backgroundColor: '#e5e7eb',
		color: '#9ca3af',
		padding: '12px 24px',
		borderRadius: 8,
		fontSize: 15,
		fontWeight: '600',
		cursor: 'not-allowed',
		display: 'inline-flex',
		alignItems: 'center',
		justifyContent: 'center',
		minWidth: 120,
		border: 'none',
		margin: '4px',
		height: 45,
	},
	// Page navigation buttons
	pageButton: {
		backgroundColor: '#f9fafb',
		color: '#4b5563',
		padding: '8px 12px',
		border: '1px solid #e5e7eb',
		borderRadius: 6,
		cursor: 'pointer',
		fontSize: 14,
		fontWeight: '500',
		transition: 'all 0.2s ease',
		margin: '0 4px',
		minWidth: 36,
		textAlign: 'center',
		':hover': {
			backgroundColor: '#f3f4f6',
			borderColor: '#d1d5db',
		},
	},
	activePage: {
		backgroundColor: '#4c956c',
		color: 'white',
		border: '1px solid #4c956c',
		padding: '8px 12px',
		borderRadius: 6,
		cursor: 'pointer',
		fontSize: 14,
		fontWeight: '500',
		margin: '0 4px',
		minWidth: 36,
		textAlign: 'center',
	},
	maintenanceSection: {
		marginTop: 40,
		padding: 30,
		backgroundColor: 'white',
		borderRadius: 16,
		boxShadow: '0 4px 10px rgba(0,0,0,0.05)',
	},
	maintenanceToggles: {
		marginTop: 25,
		display: 'flex',
		flexDirection: 'column',
		gap: 20,
	},
	toggleItem: {
		flexDirection: 'row',
		alignItems: 'center',
		padding: '16px 20px',
		backgroundColor: '#f9fafb',
		borderRadius: 12,
		marginBottom: 15,
		boxShadow: '0 1px 2px rgba(0,0,0,0.05)',
	},
	toggleLabel: {
		marginLeft: 15,
		fontSize: 16,
		color: '#4b5563',
		fontWeight: '500',
	},
	blogSection: {
		width: '100%',
	},
	blogFormContainer: {
		flexDirection: width < 1024 ? 'column' : 'row',
		justifyContent: 'space-between',
		marginBottom: 30,
		gap: 20,
	},
	blogForm: {
		flex: 1,
		marginRight: width < 1024 ? 0 : 20,
		padding: 25,
		backgroundColor: '#fff',
		borderRadius: 12,
		boxShadow: '0 4px 10px rgba(0,0,0,0.05)',
	},
	blogContentContainer: {
		marginBottom: 20,
	},
	blogPreview: {
		flex: 1,
		marginTop: width < 1024 ? 20 : 0,
		padding: 25,
		backgroundColor: '#fff',
		borderRadius: 12,
		boxShadow: '0 4px 10px rgba(0,0,0,0.05)',
	},
	previewContainer: {
		padding: 20,
		backgroundColor: '#f9fafb',
		borderRadius: 8,
		minHeight: 400,
	},
	previewImage: {
		width: '100%',
		height: 200,
		objectFit: 'cover',
		borderRadius: 8,
		marginBottom: 20,
	},
	previewTitle: {
		fontSize: 24,
		fontWeight: 'bold',
		marginBottom: 12,
		color: '#1f2937',
	},
	previewCategory: {
		fontSize: 16,
		color: '#4b5563',
		marginBottom: 16,
	},
	previewTagList: {
		flexDirection: 'row',
		flexWrap: 'wrap',
		marginBottom: 20,
		gap: 8,
	},
	previewTagPill: {
		backgroundColor: '#ecfdf5',
		paddingVertical: 6,
		paddingHorizontal: 12,
		borderRadius: 20,
		display: 'inline-flex',
		alignItems: 'center',
	},
	previewTagText: {
		color: '#065f46',
		fontSize: 14,
		fontWeight: '500',
	},
	previewContent: {
		fontSize: 16,
		lineHeight: 1.6,
		color: '#4b5563',
		marginTop: 20,
	},
	// Tag input section
	tagSection: {
		marginTop: 20,
		marginBottom: 25,
	},
	tagSectionLabel: {
		fontSize: 16,
		fontWeight: '600',
		color: '#4b5563',
		marginBottom: 12,
	},
	presetTagsContainer: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		gap: 8,
		marginBottom: 16,
	},
	presetTagButton: {
		backgroundColor: '#f3f4f6',
		color: '#4b5563',
		paddingVertical: 6,
		paddingHorizontal: 12,
		borderRadius: 20,
		fontSize: 14,
		border: 'none',
		cursor: 'pointer',
		transition: 'all 0.2s ease',
		fontWeight: '500',
		':hover': {
			backgroundColor: '#e5e7eb',
		},
	},
	presetTagButtonSelected: {
		backgroundColor: '#dcfce7',
		color: '#065f46',
	},
	tagInputContainer: {
		marginBottom: isMobile ? 15 : 20,
	},
	tagList: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		gap: 8,
		marginBottom: 16,
	},
	tagPill: {
		backgroundColor: '#ecfdf5',
		paddingVertical: 6,
		paddingHorizontal: 12,
		borderRadius: 20,
		display: 'inline-flex',
		alignItems: 'center',
		gap: 8,
		flexDirection: 'row',
	},
	tagText: {
		color: '#065f46',
		fontSize: 14,
		fontWeight: '500',
	},
	tagRemove: {
		background: 'none',
		border: 'none',
		color: '#059669',
		cursor: 'pointer',
		padding: 0,
		margin: 0,
		fontSize: 16,
		display: 'inline-flex',
		alignItems: 'center',
		justifyContent: 'center',
		':hover': {
			color: '#ef4444',
		},
	},
	tagInput: {
		width: '100%',
		padding: '12px 16px',
		border: '1px solid #e5e7eb',
		borderRadius: 8,
		fontSize: 14,
		backgroundColor: '#f9fafb',
		color: '#4b5563',
		outline: 'none',
		transition: 'all 0.2s ease',
		':focus': {
			borderColor: '#4c956c',
			boxShadow: '0 0 0 3px rgba(76, 149, 108, 0.1)',
		},
	},
	searchContainer: {
		marginBottom: 24,
	},
	searchInput: {
		width: '100%',
		padding: '12px 16px',
		border: '1px solid #e5e7eb',
		borderRadius: 8,
		fontSize: 15,
		backgroundColor: '#f9fafb',
		color: '#4b5563',
		outline: 'none',
		transition: 'all 0.2s ease',
		':focus': {
			borderColor: '#4c956c',
			boxShadow: '0 0 0 3px rgba(76, 149, 108, 0.1)',
		},
	},
	// Action buttons in tables
	actionButtons: {
		display: 'flex',
		gap: isMobile ? 8 : 10,
		justifyContent: 'flex-start',
		flexWrap: 'wrap',
		flexDirection: isMobile ? 'column' : 'row',
		width: isMobile ? '100%' : 'auto',
	},
	actionButton: {
		backgroundColor: '#f9fafb',
		color: '#4b5563',
		padding: '8px 12px',
		borderRadius: 6,
		fontSize: 14,
		fontWeight: '500',
		border: '1px solid #e5e7eb',
		cursor: 'pointer',
		transition: 'all 0.2s ease',
		textAlign: 'center',
		display: 'inline-flex',
		alignItems: 'center',
		justifyContent: 'center',
		margin: '0 0 6px 0',
		minWidth: isMobile ? '100%' : 'auto',
		boxShadow: '0 1px 2px rgba(0,0,0,0.05)',
		':hover': {
			backgroundColor: '#f3f4f6',
			borderColor: '#d1d5db',
		},
	},
	statusBadge: {
		padding: '4px 10px',
		borderRadius: 12,
		fontSize: 13,
		fontWeight: '600',
		color: 'white',
		display: 'inline-block',
	},
	sectionHeader: {
		marginBottom: 30,
	},
	userStats: {
		flexDirection: isMobile ? 'column' : 'row',
		gap: isMobile ? 15 : 20,
		marginTop: 20,
		marginBottom: 30,
	},
	statCard: {
		backgroundColor: 'white',
		padding: isMobile ? 20 : 25,
		borderRadius: 12,
		boxShadow: '0 4px 10px rgba(0,0,0,0.05)',
		flex: isMobile ? 'auto' : 1,
		width: isMobile ? '100%' : 'auto',
	},
	statNumber: {
		fontSize: 32,
		fontWeight: 'bold',
		color: '#4c956c',
		marginBottom: 10,
	},
	statLabel: {
		fontSize: 16,
		fontWeight: '500',
		color: '#4b5563',
	},
	errorContainer: {
		flex: 1,
		padding: 40,
		alignItems: 'center',
		justifyContent: 'center',
	},
	errorTitle: {
		fontSize: 24,
		fontWeight: 'bold',
		color: '#ef4444',
		marginBottom: 12,
	},
	errorText: {
		fontSize: 16,
		color: '#4b5563',
		marginBottom: 20,
		textAlign: 'center',
	},
	errorButton: {
		backgroundColor: '#3b82f6',
		color: 'white',
		padding: '10px 16px',
		borderRadius: 8,
		fontSize: 15,
		fontWeight: '600',
	},
	errorButtonText: {
		color: '#ffffff',
	},
	tableContainer: {
		backgroundColor: 'white',
		borderRadius: 12,
		boxShadow: '0 4px 10px rgba(0,0,0,0.05)',
		overflow: 'hidden',
		marginBottom: 30,
	},
	tableHeader: {
		flexDirection: 'row',
		backgroundColor: '#f9fafb',
		paddingVertical: 12,
		paddingHorizontal: 16,
		borderBottomWidth: 1,
		borderBottomColor: '#e5e7eb',
	},
	tableHeaderCell: {
		fontSize: 14,
		fontWeight: 'bold',
		color: '#4b5563',
	},
	tableBody: {
		maxHeight: 500,
	},
	tableRow: {
		flexDirection: 'row',
		paddingVertical: 12,
		paddingHorizontal: 16,
		borderBottomWidth: 1,
		borderBottomColor: '#f3f4f6',
		transition: 'background-color 0.2s ease',
		':hover': {
			backgroundColor: '#f9fafb',
		},
	},
	tableCell: {
		fontSize: 14,
		color: '#4b5563',
	},
	// Visibility toggle buttons
	visibilityButtonContainer: {
		display: 'flex',
		flexDirection: isMobile ? 'column' : 'row',
		gap: 10,
		marginTop: 15,
		flexWrap: 'wrap',
	},
	visibilityButton: {
		backgroundColor: '#f9fafb',
		color: '#4b5563',
		padding: '10px 16px',
		borderRadius: 8,
		border: '1px solid #e5e7eb',
		fontSize: 15,
		fontWeight: '500',
		cursor: 'pointer',
		transition: 'all 0.2s ease',
		textAlign: 'center',
		margin: '0 0 6px 0',
		minWidth: isMobile ? '100%' : 'auto',
		boxShadow: '0 1px 2px rgba(0,0,0,0.05)',
		':hover': {
			backgroundColor: '#f3f4f6',
			borderColor: '#d1d5db',
		},
	},
	visibilityButtonActive: {
		backgroundColor: '#4c956c',
		color: 'white',
		borderColor: '#4c956c',
	},
	// Added styles for form layouts
	formGroup: {
		marginBottom: 20,
		width: '100%',
	},
	formLabel: {
		fontSize: 15,
		fontWeight: '600',
		color: '#4b5563',
		marginBottom: 8,
		display: 'block',
	},
	formInput: {
		width: '100%',
		padding: '12px 16px',
		border: '1px solid #e5e7eb',
		borderRadius: 8,
		fontSize: 15,
		backgroundColor: '#f9fafb',
		color: '#4b5563',
		outline: 'none',
		transition: 'all 0.2s ease',
		':focus': {
			borderColor: '#4c956c',
			boxShadow: '0 0 0 3px rgba(76, 149, 108, 0.1)',
		},
	},
	formSelect: {
		width: '100%',
		padding: '12px 16px',
		border: '1px solid #e5e7eb',
		borderRadius: 8,
		fontSize: 15,
		backgroundColor: '#f9fafb',
		color: '#4b5563',
		outline: 'none',
		appearance: 'none',
		transition: 'all 0.2s ease',
		':focus': {
			borderColor: '#4c956c',
			boxShadow: '0 0 0 3px rgba(76, 149, 108, 0.1)',
		},
	},
	contentContainer: {
		padding: '0 20px',
		width: '100%',
		maxWidth: isMobile ? '100%' : isTablet ? '90%' : '1400px',
		margin: '0 auto',
	},
	analyticsContainer: {
		backgroundColor: 'white',
		padding: isMobile ? 20 : isTablet ? 30 : 40,
		borderRadius: 16,
		marginBottom: isMobile ? 20 : isTablet ? 30 : 40,
		boxShadow: '0 4px 10px rgba(0,0,0,0.05)',
		width: '100%',
	},
	loading: {
		textAlign: 'center',
		padding: 40,
		fontSize: 18,
		color: '#64748b',
	},
	loginContainer: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
	},
	loginText: {
		fontSize: 18,
		color: '#4b5563',
		marginBottom: 20,
	},
	controlsContainer: {
		marginBottom: 20,
	},
	filterContainer: {
		flexDirection: isMobile ? 'column' : 'row',
		justifyContent: 'space-between',
		gap: 16,
	},
	selectContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		flexWrap: 'wrap',
		gap: 8,
	},
	selectLabel: {
		fontSize: 14,
		fontWeight: '500',
		color: '#64748b',
		marginRight: 8,
	},
	filterButton: {
		paddingVertical: 8,
		paddingHorizontal: 12,
		borderRadius: 6,
		backgroundColor: '#f8fafc',
		borderWidth: 1,
		borderColor: '#e2e8f0',
	},
	activeFilterButton: {
		backgroundColor: '#4c956c',
		borderColor: '#4c956c',
	},
	filterButtonText: {
		fontSize: 14,
		color: '#64748b',
	},
	activeFilterButtonText: {
		color: '#ffffff',
	},
	tableContainer: {
		backgroundColor: 'white',
		borderRadius: 12,
		shadowColor: '#000',
		shadowOffset: { width: 0, height: 1 },
		shadowOpacity: 0.1,
		shadowRadius: 2,
		elevation: 2,
		overflow: 'hidden',
	},
	tableHeader: {
		flexDirection: 'row',
		backgroundColor: '#f8fafc',
		paddingVertical: 12,
		paddingHorizontal: 16,
		borderBottomWidth: 1,
		borderBottomColor: '#e2e8f0',
	},
	tableHeaderCell: {
		fontSize: 14,
		fontWeight: 'bold',
		color: '#64748b',
	},
	tableBody: {
		maxHeight: 500,
	},
	tableRow: {
		flexDirection: 'row',
		paddingVertical: 12,
		paddingHorizontal: 16,
		borderBottomWidth: 1,
		borderBottomColor: '#f1f5f9',
	},
	tableCell: {
		fontSize: 14,
		color: '#334155',
	},
	actionButtons: {
		flexDirection: 'row',
		gap: 8,
		flexWrap: 'wrap',
		marginTop: 8,
		marginBottom: 8,
	},
	actionButton: {
		backgroundColor: '#f8fafc',
		paddingVertical: 6,
		paddingHorizontal: 10,
		borderRadius: 6,
		borderWidth: 1,
		borderColor: '#e2e8f0',
	},
	actionButtonText: {
		fontSize: 12,
		color: '#475569',
	},
	dangerButton: {
		backgroundColor: '#fee2e2',
		borderColor: '#fecaca',
	},
	dangerButtonText: {
		color: '#ef4444',
	},
	warningButton: {
		backgroundColor: '#fef3c7',
		borderColor: '#fde68a',
	},
	warningButtonText: {
		color: '#d97706',
	},
	dateInputContainer: {
		display: 'flex',
		gap: 20,
		marginBottom: 20,
		flexDirection: isMobile ? 'column' : 'row',
	},
	dateField: {
		flex: 1,
	},
	dateLabel: {
		display: 'block',
		marginBottom: 8,
		color: '#475569',
		fontSize: 14,
		fontWeight: '500',
	},
	dateInput: {
		width: '100%',
		padding: '12px 16px',
		border: '2px solid #e2e8f0',
		borderRadius: 8,
		fontSize: 14,
		color: '#334155',
		backgroundColor: '#f8fafc',
		transition: 'all 0.2s ease',
	},
	blogTitle: {
		fontSize: 24,
		color: '#334155',
		marginBottom: 15,
		fontWeight: '600',
	},
	blogMeta: {
		fontSize: 16,
		color: '#64748b',
		marginBottom: 20,
	},
	previewTitle: {
		fontSize: 28,
		color: '#2c6e49',
		marginBottom: 20,
		fontFamily: 'Orpheus Pro',
		fontWeight: '600',
	},
	previewContent: {
		fontSize: 16,
		lineHeight: 1.6,
		color: '#334155',
		marginBottom: 20,
	},
	previewImage: {
		width: '100%',
		height: 'auto',
		maxHeight: 300,
		objectFit: 'cover',
		borderRadius: 8,
		marginBottom: 20,
	},
	previewMeta: {
		display: 'flex',
		gap: 15,
		marginBottom: 20,
		color: '#64748b',
		fontSize: 14,
	},
	previewTags: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: 8,
		marginTop: 20,
	},
	select: {
		width: '100%',
		height: 45,
		padding: '12px 16px',
		margin: '8px 0',
		border: '1px solid #e2e8f0',
		borderRadius: 8,
		fontSize: 15,
		color: '#4b5563',
		backgroundColor: '#f9fafb',
		transition: 'all 0.2s ease',
		cursor: 'pointer',
		appearance: 'none',
		backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24' fill='none' stroke='%23475569' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E")`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'right 16px center',
		backgroundSize: '20px',
		':focus': {
			borderColor: '#4c956c',
			boxShadow: '0 0 0 3px rgba(76, 149, 108, 0.1)',
		},
	},
	toggleContainer: {
		display: 'flex',
		alignItems: 'center',
		gap: 12,
		marginBottom: 20,
	},
	toggle: {
		position: 'relative',
		width: 52,
		height: 28,
		borderRadius: 14,
		backgroundColor: '#e2e8f0',
		transition: 'background-color 0.2s ease',
		cursor: 'pointer',
	},
	toggleActive: {
		backgroundColor: '#2c6e49',
	},
	toggleHandle: {
		position: 'absolute',
		top: 2,
		left: 2,
		width: 24,
		height: 24,
		borderRadius: 12,
		backgroundColor: 'white',
		transition: 'transform 0.2s ease',
		boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
	},
	toggleHandleActive: {
		transform: 'translateX(24px)',
	},
	actionContainer: {
		display: 'flex',
		gap: 15,
		marginTop: 25,
		marginBottom: 25,
	},
	option: {
		padding: '12px 20px',
		textAlign: 'center',
		whiteSpace: 'normal',
		lineHeight: '1.4',
	},
	primaryButton: {
		backgroundColor: '#2c6e49',
		color: 'white',
		padding: '16px 32px',
		borderRadius: 8,
		border: 'none',
		fontSize: 16,
		fontWeight: '500',
		cursor: 'pointer',
		transition: 'all 0.2s ease',
		minWidth: 140,
		textAlign: 'center',
		boxShadow: '0 2px 4px rgba(44, 110, 73, 0.2)',
		':hover': {
			backgroundColor: '#235c3d',
			transform: 'translateY(-2px)',
		},
	},
	secondaryButton: {
		backgroundColor: '#f8fafc',
		color: '#475569',
		padding: '16px 32px',
		borderRadius: 8,
		border: '2px solid #e2e8f0',
		fontSize: 16,
		fontWeight: '500',
		cursor: 'pointer',
		transition: 'all 0.2s ease',
		minWidth: 140,
		textAlign: 'center',
		':hover': {
			backgroundColor: '#f1f5f9',
			borderColor: '#cbd5e1',
		},
	},
	paginationContainer: {
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		marginVertical: 15,
		flexWrap: 'nowrap',
		overflowX: 'auto',
		padding: 5,
	},
	pageEllipsis: {
		margin: '0 5px',
		color: '#666',
	},
	filterContainer: {
		flexDirection: width < 768 ? 'column' : 'row',
		marginBottom: 15,
		alignItems: width < 768 ? 'stretch' : 'center',
		gap: 10,
	},
	filterSelect: {
		padding: 8,
		borderRadius: 4,
		borderWidth: 1,
		borderColor: '#ddd',
		backgroundColor: '#fff',
		marginRight: width < 768 ? 0 : 10,
		marginBottom: width < 768 ? 10 : 0,
		flex: width < 768 ? 'auto' : 1,
		maxWidth: width < 768 ? '100%' : 200,
	},
	bulkEdit: {
		backgroundColor: '#f8fafc',
		padding: 24,
		borderRadius: 12,
		marginBottom: 24,
		border: '1px solid #e2e8f0',
		display: 'grid',
		gridTemplateColumns: isMobile ? '1fr' : 'repeat(auto-fit, minmax(250px, 1fr))',
		gap: 16,
	},
	bulkEditHeader: {
		fontSize: 16,
		fontWeight: 'bold',
		marginBottom: 10,
		gridColumn: '1 / -1',
	},
	bulkEditButtons: {
		display: 'flex',
		flexDirection: width < 768 ? 'column' : 'row',
		gap: 10,
		marginTop: 10,
		gridColumn: '1 / -1',
	},
	mismatchedUsersContainer: {
		marginTop: 15,
	},
	userListContainer: {
		padding: 20,
		backgroundColor: '#fff',
		borderRadius: 8,
		margin: 20,
	},
	userTable: {
		width: '100%',
		borderCollapse: 'collapse',
		marginTop: 20,
	},
	testResultContainer: {
		padding: 15,
		borderRadius: 8,
		marginTop: 15,
		marginBottom: 15,
	},
	successResult: {
		backgroundColor: '#ecfdf5',
		borderLeft: '4px solid #059669',
	},
	errorResult: {
		backgroundColor: '#fef2f2',
		borderLeft: '4px solid #dc2626',
	},
	testResultText: {
		fontSize: 14,
		lineHeight: 1.5,
		whiteSpace: 'pre-wrap',
	},
	successText: {
		color: '#065f46',
	},
	errorText: {
		color: '#991b1b',
	},
	shareAnalytics: {
		display: 'flex',
		alignItems: 'center',
		gap: 10,
	},
	shareCount: {
		fontSize: 14,
		fontWeight: 'bold',
		color: '#4b5563',
	},
	viewDetailsButton: {
		backgroundColor: '#4c956c',
		color: 'white',
		padding: '6px 12px',
		borderRadius: 4,
		fontSize: 12,
		fontWeight: 'bold',
		cursor: 'pointer',
		border: 'none',
		transition: 'all 0.2s ease',
		':hover': {
			backgroundColor: '#2c6e49',
			transform: 'translateY(-1px)',
		},
	},
	noShares: {
		color: '#94a3b8',
		fontSize: 14,
		fontStyle: 'italic',
	},
	modalOverlay: {
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		backgroundColor: 'rgba(0, 0, 0, 0.5)',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		zIndex: 1000,
	},
	modalContent: {
		backgroundColor: '#fff',
		borderRadius: 12,
		padding: 24,
		width: isMobile ? '90%' : '600px',
		maxWidth: '90%',
		maxHeight: '90vh',
		boxShadow: '0 10px 25px rgba(0,0,0,0.1)',
		display: 'flex',
		flexDirection: 'column',
	},
	modalTitle: {
		fontSize: 22,
		fontWeight: 'bold',
		color: '#2c6e49',
		marginBottom: 16,
		textAlign: 'center',
	},
	modalScrollView: {
		maxHeight: '60vh',
		marginBottom: 16,
	},
	closeModalButton: {
		marginTop: 16,
		padding: '10px 0',
		backgroundColor: '#f8fafc',
		border: 'none',
		borderRadius: 8,
		color: '#4b5563',
		fontWeight: 'bold',
		cursor: 'pointer',
		transition: 'all 0.2s',
		':hover': {
			backgroundColor: '#e2e8f0',
		},
	},
	shareStatsContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginBottom: 20,
		gap: 12,
	},
	statPill: {
		backgroundColor: '#f1f5f9',
		padding: 16,
		borderRadius: 10,
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		boxShadow: '0 2px 5px rgba(0,0,0,0.05)',
	},
	statPillValue: {
		fontSize: 24,
		fontWeight: 'bold',
		color: '#2c6e49',
		marginBottom: 8,
	},
	statPillLabel: {
		fontSize: 14,
		color: '#64748b',
		textAlign: 'center',
	},
	lastShareDate: {
		fontSize: 14,
		color: '#64748b',
		marginBottom: 20,
		textAlign: 'center',
		fontStyle: 'italic',
	},
	shareTabs: {
		display: 'flex',
		flexDirection: 'column',
		gap: 16,
	},
	shareTabsHeader: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-around',
		marginBottom: 10,
		paddingBottom: 10,
		borderBottomWidth: 1,
		borderBottomColor: '#e2e8f0',
	},
	shareTabTitle: {
		fontSize: 16,
		fontWeight: 'bold',
		color: '#4c956c',
		padding: '8px 16px',
		borderRadius: 8,
		backgroundColor: '#f1f5f9',
		textAlign: 'center',
	},
	shareTabsContent: {
		display: 'flex',
		flexDirection: 'column',
		gap: 24,
	},
	shareTabPanel: {
		backgroundColor: '#fff',
		padding: 16,
		borderRadius: 8,
		borderWidth: 1,
		borderColor: '#e2e8f0',
		boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
	},
	shareList: {
		maxHeight: 300,
		overflow: 'auto',
	},
	shareListHeader: {
		display: 'flex',
		flexDirection: 'row',
		padding: '8px 0',
		borderBottomWidth: 2,
		borderBottomColor: '#e2e8f0',
		marginBottom: 8,
	},
	shareListHeaderCell: {
		flex: 1,
		fontSize: 14,
		fontWeight: 'bold',
		color: '#4b5563',
		textAlign: 'left',
	},
	shareItem: {
		display: 'flex',
		flexDirection: 'row',
		padding: '8px 0',
		borderBottomWidth: 1,
		borderBottomColor: '#f1f5f9',
	},
	shareRecipient: {
		flex: 1,
		fontSize: 14,
		color: '#4b5563',
		textAlign: 'left',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	shareDate: {
		flex: 1,
		fontSize: 14,
		color: '#64748b',
		textAlign: 'left',
	},
	shareTime: {
		flex: 1,
		fontSize: 14,
		color: '#64748b',
		textAlign: 'left',
	},
	noSharesText: {
		fontSize: 14,
		color: '#94a3b8',
		textAlign: 'center',
		padding: 16,
		fontStyle: 'italic',
	},
	shareAnalyticsCell: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		gap: 10,
	},
	shareAnalyticsPills: {
		display: 'flex',
		flexDirection: 'row',
		gap: 8,
	},
	sharePill: {
		backgroundColor: '#f1f5f9',
		padding: 8,
		borderRadius: 16,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		boxShadow: '0 2px 5px rgba(0,0,0,0.05)',
	},
	sharePillCount: {
		fontSize: 16,
		fontWeight: 'bold',
		color: '#2c6e49',
		marginBottom: 4,
	},
	sharePillLabel: {
		fontSize: 14,
		color: '#64748b',
		textAlign: 'center',
	},
});

// Utility function to generate institution code
const generateInstitutionCode = (type) => {
	const prefix = type === "University or College" ? "U" : type === "High School" ? "H" : "I";
	return `${prefix}${Math.floor(10000 + Math.random() * 90000)}`;
};

export default Admin;
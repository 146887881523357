// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent, setUserId, setUserProperties } from "firebase/analytics";
import { getAuth, setPersistence, browserLocalPersistence } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBoQXdL0TRJtNLtHR-zPWEDQ9LBXaG0Ox4",
  authDomain: "my-tassel.firebaseapp.com",
  projectId: "my-tassel",
  storageBucket: "my-tassel.appspot.com",
  messagingSenderId: "882425732978",
  appId: "1:882425732978:web:65d36b686a533d1ac607eb",
  measurementId: "G-7YPHD9TT5T"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

// Set persistence to local
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    // console.log("Persistence set to local");
  })
  .catch((error) => {
    console.error("Error setting persistence:", error);
  });

const analytics = getAnalytics(app);

// Helper function to log analytics events
const logAnalyticsEvent = (eventName, eventParams = {}) => {
  try {
    logEvent(analytics, eventName, eventParams);
  } catch (error) {
    console.error("Error logging analytics event:", error);
  }
};

// Helper function to set user ID for analytics
const setAnalyticsUserId = (userId) => {
  try {
    setUserId(analytics, userId);
  } catch (error) {
    console.error("Error setting analytics user ID:", error);
  }
};

// Helper function to set user properties for analytics
const setAnalyticsUserProperties = (properties) => {
  try {
    setUserProperties(analytics, properties);
  } catch (error) {
    console.error("Error setting analytics user properties:", error);
  }
};

export { firebaseConfig, app, auth, analytics, logAnalyticsEvent, setAnalyticsUserId, setAnalyticsUserProperties };
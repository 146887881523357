import React, { useState, useEffect, useContext } from "react";
import { View, Text, Linking, StyleSheet, Dimensions, FlatList, Image, TextInput, Pressable, ImageBackground, Input, Modal, ScrollView } from "react-native";
import { getFirestore, collection, query, where, getDoc, getDocs, doc, updateDoc, arrayUnion, setDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useUser } from "../Widgets/UserContext";
import ECardSelectionModal from "../Screens/RegistryWidgets/eCards";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from '@stripe/stripe-js';
import Checkout from "../Screens/RegistryWidgets/Checkout";
import { ConnectedAccountModal, ConnectedAccount } from "../Screens/PaymentProcessing/ConnectedAccount";
import { useBackground } from '../Screens/Helpers/BackgroundContext';
import Catalogue from "../Screens/RegistryWidgets/Catalogue";
import { useNavigation, useFocusEffect } from "@react-navigation/native";
import { useTutorial, TutorialPopup } from "../Screens/Helpers/Tutorials";
import { REACT_APP_STRIPE_PERSH_KEY, REACT_APP_STRIPE_PERSH_TEST_KEY, REACT_APP_AWS_SERVER_URL } from '@env';
import SharedModal from './SharedModal';

import exampleBackground1 from "../assets/exBackground1.jpg";
import exampleBackground2 from "../assets/exBackground2.jpg";
import exampleBackground3 from "../assets/exBackground3.jpg";
import exampleBackground4 from "../assets/exBackground4.jpg";
import exampleBackground5 from "../assets/exBackground5.jpg";
import exampleBackground6 from "../assets/exBackground6.jpg";
import exampleBackground7 from "../assets/exBackground7.jpg";
import genericProfilePic from "../assets/generic.jpg";
import logo from "../assets/logo.png"; // Import the logo

let LOCAL_SERVER_URL = "http://localhost:3300";
let AWS_SERVER_URL = REACT_APP_AWS_SERVER_URL;

const isDevelopment = process.env.NODE_ENV === "development"; // Adjust this condition based on your setup
const SERVER_URL = isDevelopment ? LOCAL_SERVER_URL : AWS_SERVER_URL;

const STRIPE_PERSH_KEY = REACT_APP_STRIPE_PERSH_KEY;
const STRIPE_PERSH_TEST_KEY = REACT_APP_STRIPE_PERSH_TEST_KEY;
// console.log("STRIPE_PERSH_KEY:", STRIPE_PERSH_KEY);
// console.log("STRIPE_PERSH_TEST_KEY:", STRIPE_PERSH_TEST_KEY);

const { width, height } = Dimensions.get('window');
const isMobile = width < 768;
const isTablet = width >= 768 && width < 1024;
const isSmallDesktop = width >= 1024 && width < 1440;
const isLargeDesktop = width >= 1440;

const CombinedCard = ({ pageOwnerId, pageOwner, isVisitorView }) => {
   const navigation = useNavigation();
   const { backgroundImage } = useBackground();
   const { width } = Dimensions.get('window');
   const isMobile = width < 768;
   const db = getFirestore();
   const { currentUser, currentUserData } = useUser();
   const [messages, setMessages] = useState([]);
   const [goalData, setGoalData] = useState({});
   const [message, setMessage] = useState("");
   const [signature, setSignature] = useState({ firstName: "", lastName: "" });
   const [image, setImage] = useState(null); // State to store the uploaded image
   const [activeTab, setActiveTab] = useState("Yearbook");
   const [profilePictures, setProfilePictures] = useState({});
   const [showEditGoal, setShowEditGoal] = useState(false);
   const [showContribution, setShowContribution] = useState(false);
   const [contributionAmount, setContributionAmount] = useState("");
   const [showECardModal, setShowECardModal] = useState(false);
   const [showCheckoutModal, setShowCheckoutModal] = useState(false);
   const [selectedECard, setSelectedECard] = useState(null);
   const [showConnectedAccountModal, setShowConnectedAccountModal] = useState(false);
   const stripePromise = loadStripe(STRIPE_PERSH_KEY);

   const [showShareForm, setShowShareForm] = useState(false);
   const [recipientEmails, setRecipientEmails] = useState("");
   const [loading, setLoading] = useState(false); // State to manage loading
   const [error, setError] = useState(""); // State to manage error messages
   const [goalImage, setGoalImage] = useState(null); // State to store the uploaded goal image
   const [uploadStatus, setUploadStatus] = useState({ loading: false, error: "", success: "" });
   const [showCatalogueModal, setShowCatalogueModal] = useState(false);
   const [showEmailWarning, setShowEmailWarning] = useState(false);
	const {
		setShowTutorial,
		setActivePopupId,
		showTutorial,
		isLoading,
		tutorialsToShow,
		setClosedTutorials,
		setTutorialsToShow
	} = useTutorial();

   const [recipientType, setRecipientType] = useState("email");
   const [phoneNumbers, setPhoneNumbers] = useState("");
   const [isError, setIsError] = useState(false);
   const [isSharing, setIsSharing] = useState(false);
   const [isShared, setIsShared] = useState(false);

   useEffect(() => {
      loadMessages();
      loadGoalData();
   }, [pageOwnerId]);

   useEffect(() => {
      messages.forEach((message) => {
         if (message.senderUID && !profilePictures[message.senderUID]) {
            fetchProfilePictureUrl(message.senderUID);
         }
      });
   }, [messages]);

   const loadMessages = async () => {
      const q = query(collection(db, "users"), where("uid", "==", pageOwnerId));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
         querySnapshot.forEach((doc) => {
            const data = doc.data();
            const messages = data.messages?.yearbookMessages || [];
            setMessages(messages.reverse()); // Reverse the order of messages
         });
      }
   };

   const loadGoalData = async () => {
      const usersRef = collection(db, "users");
      const q = query(usersRef, where("uid", "==", pageOwnerId));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
         querySnapshot.forEach((docSnap) => {
            const userData = docSnap.data();
            if (userData.pages?.registryPageConfig?.goalTracker) {
               const goalTrackerData = userData.pages.registryPageConfig.goalTracker;
               setGoalData({
                  ...goalTrackerData,
                  amountContributed: parseFloat(goalTrackerData.amountContributed || 0),
                  targetAmount: parseFloat(goalTrackerData.targetAmount || 0)
               });
            } else {
               // Initialize with default values
               setGoalData({
                  targetAmount: 0,
                  amountContributed: 0,
                  description: "",
                  title: "",
                  goalPictureURL: "",
                  goalURL: "",
                  accountURLs: [],
                  contributions: [],
               });
            }
         });
      }
   };

   const fetchProfilePictureUrl = async (uid) => {
      if (!profilePictures[uid]) {
         try {
            const usersRef = collection(db, "users");
            const q = query(usersRef, where("uid", "==", uid));
            const querySnapshot = await getDocs(q);
            if (!querySnapshot.empty) {
               querySnapshot.forEach((docSnap) => {
                  const data = docSnap.data();
                  setProfilePictures((prevPics) => ({ ...prevPics, [uid]: data.profilePictureURL }));
               });
            }
         } catch (error) {
            console.error("Error fetching profile picture:", error);
         }
      }
   };

   const handleSend = async () => {
      if (!message.trim() && !image) return;
      const newMessage = {
         text: message.trim(),
         image: image || null,
         senderFirstName: currentUserData?.firstName || signature.firstName.trim() || "Anonymous",
         senderLastName: currentUserData?.lastName || signature.lastName.trim() || "",
         senderUID: currentUserData?.uid || "anonymous",
         senderPhotoURL: currentUserData?.photoURL || "",
         timestamp: new Date(),
      };
      const q = query(collection(db, "users"), where("uid", "==", pageOwnerId));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
         querySnapshot.forEach(async (docSnapshot) => {
            const userDocRef = doc(db, "users", docSnapshot.id);
            const docData = await getDoc(userDocRef);
            if (docData.exists() && docData.data().messages && docData.data().messages.yearbookMessages) {
               await updateDoc(userDocRef, {
                  "messages.yearbookMessages": arrayUnion(newMessage),
               });
            } else {
               const messagesObj = { yearbookMessages: [newMessage] };
               await setDoc(userDocRef, { messages: messagesObj }, { merge: true });
            }
            setMessage("");
            setImage(null); // Clear the image after sending
            setSignature({ firstName: "", lastName: "" });
            loadMessages();
         });
      }
   };

   const handleYearbookImageUpload = async (event) => {
      setUploadStatus({ loading: true, error: "", success: "" });
      const file = event.target.files[0];
      if (file && file.type.startsWith("image/")) {
         try {
            const storageRef = ref(getStorage(), `yearbookImages/${file.name}-${Date.now()}`);
            const uploadResult = await uploadBytes(storageRef, file);
            const publicUrl = await getDownloadURL(uploadResult.ref);
            setImage(publicUrl);
            setUploadStatus({ loading: false, error: "", success: "Image uploaded successfully!" });
         } catch (err) {
            console.error("Error uploading image:", err);
            setUploadStatus({ loading: false, error: "Failed to upload image. Please try again.", success: "" });
         }
      } else {
         setUploadStatus({ loading: false, error: "Please select a valid image file.", success: "" });
      }
   };

   const handleGoalImageUpload = async (event) => {
      setUploadStatus({ loading: true, error: "", success: "" });
      const file = event.target.files[0];
      if (file && file.type.startsWith("image/")) {
         try {
            const storageRef = ref(getStorage(), `goalImages/${file.name}-${Date.now()}`);
            const uploadResult = await uploadBytes(storageRef, file);
            const publicUrl = await getDownloadURL(uploadResult.ref);
            setGoalImage(publicUrl);
            setGoalData((prevData) => ({ ...prevData, goalPictureURL: publicUrl })); // Update goalData with new image URL
            setUploadStatus({ loading: false, error: "", success: "Image uploaded successfully!" });
         } catch (err) {
            console.error("Error uploading image:", err);
            setUploadStatus({ loading: false, error: "Failed to upload image. Please try again.", success: "" });
         }
      } else {
         setUploadStatus({ loading: false, error: "Please select a valid image file.", success: "" });
      }
   };

   const handleContribution = () => {
      setShowECardModal(true);
   };

   const handleSaveGoal = async () => {
      const q = query(collection(db, "users"), where("uid", "==", pageOwnerId));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
         querySnapshot.forEach(async (docSnapshot) => {
            const userDocRef = doc(db, "users", docSnapshot.id);
            await updateDoc(userDocRef, {
               "pages.registryPageConfig.goalTracker": goalData,
            });
            setShowEditGoal(false);
         });
      }
   };

   const handleResetContributions = async () => {
      const confirmReset = window.confirm("Are you sure you want to reset all contributions? This action cannot be undone.");
      if (confirmReset) {
         const updatedGoalData = { ...goalData, amountContributed: 0 };
         const q = query(collection(db, "users"), where("uid", "==", pageOwnerId));
         const querySnapshot = await getDocs(q);
         if (!querySnapshot.empty) {
            querySnapshot.forEach(async (docSnapshot) => {
               const userDocRef = doc(db, "users", docSnapshot.id);
               await updateDoc(userDocRef, {
                  "pages.registryPageConfig.goalTracker": updatedGoalData,
               });
            });
         }
         setGoalData(updatedGoalData);
         setShowEditGoal(false);
      }
   };

   const handleECardSelection = (eCardData) => {
      console.log("ECard selected:", eCardData);
      setSelectedECard(eCardData);
      setShowECardModal(false);
      setShowCheckoutModal(true);
      console.log("Show Checkout Modal:", showCheckoutModal);
   };

   const submitContribution = async () => {
      const newAmount = parseFloat(contributionAmount) || 0;
      const updatedAmount = parseFloat(goalData.amountContributed || 0) + newAmount; // Ensure we're working with numbers

      const usersRef = collection(db, "users");
      const q = query(usersRef, where("uid", "==", pageOwnerId));
      const querySnapshot = await getDocs(q);
      
      if (!querySnapshot.empty) {
         querySnapshot.forEach(async (docSnapshot) => {
            const userDocRef = doc(db, "users", docSnapshot.id);
            
            // Update only the amountContributed field
            await updateDoc(userDocRef, {
               "pages.registryPageConfig.goalTracker.amountContributed": updatedAmount
            });
            
            // Add the contribution to the contributions array
            await updateDoc(userDocRef, {
               "pages.registryPageConfig.goalTracker.contributions": arrayUnion({
                  amount: newAmount,
                  contributorName: currentUserData?.firstName + " " + currentUserData?.lastName,
                  contributorId: currentUserData?.uid,
                  timestamp: new Date()
               })
            });
         });
      }

      // Update local state
      setGoalData(prevData => ({
         ...prevData,
         amountContributed: updatedAmount
      }));

      setShowContribution(false);
      setContributionAmount("");
      setShowCheckoutModal(false);
   };

   const handleShare = async () => {
      if (recipientType === "email") {
         setShowEmailWarning(true); // Show warning first
      } else {
         // SMS is coming soon, no action needed
      }
   };

   const handleShareTab = () => {
      setActiveTab("Share");
   };

   const trackShare = async (shareData) => {
      try {
         if (!currentUser || !currentUserData) {
            // If no user is logged in, just proceed without tracking
            return;
         }

         // Determine pageType based on active tab
         const pageType = activeTab.toLowerCase();

         // Use email as document ID and include more user information
         const shareAnalyticsRef = doc(db, "shareAnalytics", currentUserData.email.toLowerCase());
         const shareAnalyticsDoc = await getDoc(shareAnalyticsRef);
         
         const newShare = {
            recipient: shareData.recipients.join(", "),
            date: new Date().toISOString(),
            type: shareData.type,
            sharedPageType: pageType,
            pageOwnerUid: pageOwner.uid,
            senderName: `${currentUserData.firstName} ${currentUserData.lastName}`,
            senderEmail: currentUserData.email.toLowerCase()
         };

         if (shareAnalyticsDoc.exists()) {
            const currentData = shareAnalyticsDoc.data();
            await updateDoc(shareAnalyticsRef, {
               totalShares: (currentData.totalShares || 0) + 1,
               [`${shareData.type}Shares`]: arrayUnion(newShare),
               lastShareDate: new Date().toISOString(),
               userId: currentUser.uid,
               userEmail: currentUserData.email.toLowerCase(),
               firstName: currentUserData.firstName,
               lastName: currentUserData.lastName
            });
         } else {
            await setDoc(shareAnalyticsRef, {
               userId: currentUser.uid,
               userEmail: currentUserData.email.toLowerCase(),
               firstName: currentUserData.firstName,
               lastName: currentUserData.lastName,
               totalShares: 1,
               emailShares: shareData.type === 'email' ? [newShare] : [],
               smsShares: shareData.type === 'sms' ? [newShare] : [],
               lastShareDate: new Date().toISOString()
            });
         }

         console.log(`Successfully tracked ${shareData.type} share for ${pageType} page`);
      } catch (error) {
         console.error("Error tracking share:", error);
      }
   };

   const shareAnnouncementPage = async () => {
      setIsSharing(true);
      const emailsArray = recipientEmails.split(",").map(email => email.trim());
      const payload = {
         recipients: emailsArray,
         senderFirstName: pageOwner.firstName,
         senderLastName: pageOwner.lastName,
         uic: pageOwner.uic,
      };

      if (pageOwner.pages.events) {
         payload.eventsPageUrl = pageOwner.pages.events;
      }

      if (pageOwner.pages.registry) {
         payload.registryPageUrl = pageOwner.pages.registry;
      }

      try {
         const response = await fetch(`${SERVER_URL}/share-user-pages`, {
            method: "POST",
            headers: {
               "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
         });

         const data = await response.json();

         if (response.ok) {
            // Track share analytics
            await trackShare({
               type: 'email',
               recipients: emailsArray
            });

            if (data.errors && data.errors.length > 0) {
               if (data.results && data.results.length > 0) {
                  setIsShared(true);
                  setError(`${data.results.length} email(s) sent successfully, but ${data.errors.length} failed. Please check the email addresses and try again for the failed ones.`);
               } else {
                  setError("Failed to send any emails. Please check the addresses and try again.");
               }
               setIsError(true);
            } else {
               setIsShared(true);
               setIsError(false);
               setError("");
               setRecipientEmails("");
            }
         } else {
            setError(data.error || "Failed to send invitations. Please try again.");
            setIsError(true);
         }
      } catch (error) {
         console.error("Error:", error);
         setError("An error occurred while sending invitations. Please try again.");
         setIsError(true);
      } finally {
         setIsSharing(false);
      }
   };

   const handleRegistryPress = () => {
      if (pageOwner.pages.registry) {
         const urlParams = new URLSearchParams(window.location.search);
         const uic = urlParams.get('uic');

         let registryUrl = pageOwner.pages.registry;
         if (uic) {
            registryUrl += `?uic=${uic}`; // Append the UIC to the registry URL
         }

         Linking.openURL(registryUrl);
      } else {
         setActiveTab("Registry");
      }
   };

   const shareViaSMS = async (phoneList) => {
      try {
         // Basic phone number validation before sending
         const validPhoneList = phoneList.filter(phone => {
            const cleaned = phone.replace(/\D/g, '');
            return cleaned.length >= 10 && cleaned.length <= 11;
         });

         if (validPhoneList.length === 0) {
            setError("Please enter valid phone numbers (e.g., 123-456-7890)");
            setIsError(true);
            return;
         }

         const response = await fetch(`${SERVER_URL}/share-via-sms`, {
            method: "POST",
            headers: {
               "Content-Type": "application/json",
            },
            body: JSON.stringify({
               recipients: validPhoneList,
               senderFirstName: pageOwner.firstName,
               senderLastName: pageOwner.lastName,
               eventsPageUrl: pageOwner.pages.events,
               registryPageUrl: pageOwner.pages.registry,
               uic: pageOwner.uic,
            }),
         });

         const data = await response.json();

         if (response.ok) {
            // Track share analytics
            await trackShare({
               type: 'sms',
               recipients: validPhoneList
            });

            if (data.errors && data.errors.length > 0) {
               if (data.results && data.results.length > 0) {
                  setIsShared(true);
                  setError(`${data.results.length} message(s) sent successfully, but ${data.errors.length} failed. Please check the phone numbers and try again for the failed ones.`);
               } else {
                  setError("Failed to send messages. Please check the phone numbers and try again.");
               }
               setIsError(true);
            } else {
               setIsShared(true);
               setIsError(false);
               setError("");
               setPhoneNumbers("");
            }
         } else {
            setError(data.error || "Failed to send SMS invitations. Please try again.");
            setIsError(true);
         }
      } catch (error) {
         console.error("Error:", error);
         setError("An error occurred while sending invitations. Please try again.");
         setIsError(true);
      }
   };

   return (
      <View style={styles.container}>
         <View style={styles.innerContainer}>
            <View style={styles.tabContainer}>
               {/* Yearbook Tutorial */}
               <TutorialPopup
                  id="combinedCardYearbook"
                  position={{
                     top: isMobile ? -25 : isSmallDesktop ? -25 : -25,
                     left: isMobile ? 750 : isSmallDesktop ? 575 : 755
                  }}
                  title="Yearbook"
                  openDirection="topLeft"
               />
               
               {/* Yearbook Tab */}
               <Pressable
                  style={[styles.tabButton, activeTab === "Yearbook" && styles.activeTabButton]}
                  onPress={() => setActiveTab("Yearbook")}
               >
                  <Text style={activeTab === "Yearbook" ? styles.activeTabButtonText : styles.tabButtonText}>{pageOwner?.firstName}'s Yearbook</Text>
               </Pressable>

               {/* Goal Tracker Tutorial */}
               <TutorialPopup
                  id="combinedCardGoalTracker"
                  position={{
                     top: isMobile ? -25 : isSmallDesktop ? -25 : -25,
                     left: isMobile ? 875 : isSmallDesktop ? 700 : 925
                  }}
                  title="Goal Tracker"
                  openDirection="topLeft"
               />
               
               {/* Goal Tracker Tab */}
               <Pressable
                  style={[styles.tabButton, activeTab === "GoalTracker" && styles.activeTabButton]}
                  onPress={() => setActiveTab("GoalTracker")}
               >
                  <Text style={activeTab === "GoalTracker" ? styles.activeTabButtonText : styles.tabButtonText}>Goal Tracker</Text>
               </Pressable>

               {/* Registry Tutorial */}
               <TutorialPopup
                  id="combinedCardRegistry"
                  position={{
                     top: isMobile ? -25 : isSmallDesktop ? -25 : -25,
                     right: isMobile ? 700 : isSmallDesktop ? 500 : 980
                  }}
                  title="Registry"
                  openDirection="topLeft"
               />
               
               {/* Registry Tab */}
               <Pressable
                  style={[styles.tabButton, activeTab === "Registry" && styles.activeTabButton]}
                  onPress={handleRegistryPress}
               >
                  <Text style={activeTab === "Registry" ? styles.activeTabButtonText : styles.tabButtonText}>{pageOwner?.firstName}'s Registry</Text>
               </Pressable>

               {/* Share Tutorial */}
               <TutorialPopup
                  id="combinedCardShare"
                  position={{
                     top: isMobile ? -25 : isSmallDesktop ? -25 : -25,
                     right: isMobile ? 625 : isSmallDesktop ? 425 : 825
                  }}
                  title="Share"
                  openDirection="topLeft"
               />
               
               {/* Share Tab */}
               <Pressable
                  style={[styles.tabButton, activeTab === "Share" && styles.activeTabButton]}
                  onPress={handleShareTab}
               >
                  <Text style={activeTab === "Share" ? styles.activeTabButtonText : styles.typeButtonText}>Share</Text>
               </Pressable>
            </View>

            {activeTab === "Yearbook" && (
               <ImageBackground
                  source={{ uri: backgroundImage }}
                  style={styles.yearbookBackground}
                  imageStyle={styles.yearbookImage}
               >
                  <View style={styles.yearbookContainer}>
                     <ScrollView style={styles.messagesContainer}>

                        {messages.map((msg, index) => (
                           <View
                              key={index}
                              style={[
                                 styles.message,
                                 msg.senderUID === currentUserData?.uid ? styles.currentUserMessage : styles.otherUserMessage,
                              ]}
                           >
                              <Image source={{ uri: profilePictures[msg.senderUID] || genericProfilePic }} style={styles.profileImage} />
                              <View style={styles.messageContent}>
                                 <Text style={[styles.messageSender, msg.senderUID !== currentUserData?.uid && styles.otherUserText]}>{`${msg.senderFirstName} ${msg.senderLastName}`}</Text>
                                 {msg.image && <Image source={{ uri: msg.image }} style={styles.messageImage} />}
                                 <Text style={[styles.messageText, msg.senderUID !== currentUserData?.uid && styles.otherUserText]}>{msg.text}</Text>
                                 <Text style={[styles.messageTimestamp, msg.senderUID !== currentUserData?.uid && styles.otherUserText]}>{new Date(msg.timestamp.seconds ? msg.timestamp.seconds * 1000 : msg.timestamp).toLocaleString()}</Text>
                              </View>
                           </View>
                        ))}
                        <View style={[styles.message, styles.otherUserMessage, styles.alignLeft]}>
                           <Image source={logo} style={styles.profileImage} />
                           <View style={styles.messageContent}>
                              <Text style={[styles.messageSender, styles.otherUserText]}>The MyTassel Team</Text>
                              <Text style={[styles.messageText, styles.otherUserText]}>
                                 Congratulations on your accomplishment! Your hard work and perseverance paid off! As you share your page, Friends and Family can use this space to share messages, memories, and upload their favorite photos to celebrate YOU!
                              </Text>
                           </View>
                        </View>


                     </ScrollView>
                     <View style={styles.yearbookInputContainer}>
                        <TextInput
                           style={styles.yearbookInput}
                           placeholder="Write a message..."
                           placeholderStyle={styles.yearbookInputPlaceholder}
                           value={message}
                           onChangeText={setMessage}
                        />
                        <Pressable style={styles.uploadButton} onPress={() => document.getElementById('yearbookFileInput').click()}>
                           <Text style={styles.uploadButtonText}>{isMobile ? "Photo" : "Upload Photo"}</Text>
                        </Pressable>
                        <input
                           id="yearbookFileInput"
                           type="file"
                           onChange={handleYearbookImageUpload}
                           accept="image/*"
                           style={{ display: 'none' }}
                        />
                        <Pressable style={styles.sendButton} onPress={handleSend}>
                           <Text style={styles.sendButtonText}>Send</Text>
                        </Pressable>
                     </View>
                     {uploadStatus.loading && <Text style={styles.loadingText}>Uploading image...</Text>}
                     {uploadStatus.error && <Text style={styles.errorText}>{uploadStatus.error}</Text>}
                     {uploadStatus.success && <Text style={styles.successText}>{uploadStatus.success}</Text>}
                     {image && (
                        <View style={styles.previewContainer}>
                           <Image source={{ uri: image }} style={styles.previewImage} />
                        </View>
                     )}
                     {!currentUser && (
                        <View style={styles.signatureContainer}>
                           <TextInput
                              style={styles.signatureInput}
                              placeholder="First Name"
                              value={signature.firstName}
                              onChangeText={(text) => setSignature({ ...signature, firstName: text })}
                           />
                           <TextInput
                              style={styles.signatureInput}
                              placeholder="Last Name"
                              value={signature.lastName}
                              onChangeText={(text) => setSignature({ ...signature, lastName: text })}
                           />
                        </View>
                     )}
                  </View>
               </ImageBackground>
            )}

            {activeTab === "GoalTracker" && (
               <ImageBackground
                  source={{ uri: goalData?.goalPictureURL || backgroundImage }}
                  style={styles.goalTrackerBackground}
                  imageStyle={styles.goalTrackerImage}
               >
                  <View style={styles.goalTrackerContent}>
                     {showEditGoal && !isVisitorView ? (
                        <View style={styles.editGoalContainer}>
                           <Pressable style={styles.closeButton} onPress={() => setShowEditGoal(false)}>
                              <Text style={styles.closeButtonText}>X</Text>
                           </Pressable>

                           <TextInput
                              style={styles.input}
                              placeholder="Goal Title"
                              value={goalData.title}
                              onChangeText={(text) => setGoalData({ ...goalData, title: text })}
                           />
                           <TextInput
                              style={styles.input}
                              placeholder="Goal Description"
                              value={goalData.description}
                              onChangeText={(text) => setGoalData({ ...goalData, description: text })}
                           />
                           <TextInput
                              style={styles.input}
                              placeholder="Target Amount"
                              value={goalData.targetAmount}
                              onChangeText={(text) => setGoalData({ ...goalData, targetAmount: text })}
                              keyboardType="numeric"
                           />
                           <Pressable style={styles.uploadButtonGoal} onPress={() => document.getElementById('goalImageInput').click()}>
                              <Text style={styles.uploadButtonText}>Upload Photo</Text>
                           </Pressable>
                           <input
                              id="goalImageInput"
                              type="file"
                              onChange={handleGoalImageUpload}
                              accept="image/*"
                              style={{ display: 'none' }}
                           />
                           {uploadStatus.loading && <Text style={styles.loadingText}>Uploading image...</Text>}
                           {uploadStatus.error && <Text style={styles.errorText}>{uploadStatus.error}</Text>}
                           {uploadStatus.success && <Text style={styles.successText}>{uploadStatus.success}</Text>}
                           {goalImage && (
                              <View style={styles.previewContainer}>
                                 <Image source={{ uri: goalImage }} style={styles.previewImage} />
                              </View>
                           )}
                           <Pressable style={styles.button} onPress={handleSaveGoal}>
                              <Text style={styles.buttonText}>Save Goal</Text>
                           </Pressable>
                           <Pressable style={styles.buttonRed} onPress={handleResetContributions}>
                              <Text style={styles.buttonText}>Reset Contributions</Text>
                           </Pressable>
                        </View>
                     ) : (
                        <View style={styles.goalDetails}>
                           {goalData.goalPictureURL && (
                              <Image source={{ uri: goalData.goalPictureURL }} style={styles.goalImage} />
                           )}

                           <View style={styles.goalTextContainer}>
                              <Text style={styles.title}>{goalData.title}</Text>
                              <Text style={styles.description}>{goalData.description}</Text>

                              {goalData.targetAmount ? (
                                 <Text style={styles.amount}>
                                    ${goalData.amountContributed} of ${goalData.targetAmount}
                                 </Text>
                              ) : (
                                 null
                              )}

                              {goalData.targetAmount ? (
                                 <View style={styles.contributionBar}>
                                    <View style={{ ...styles.filledBar, width: `${(goalData.amountContributed / goalData.targetAmount) * 100}%` }} />
                                 </View>
                              ) : (
                                 null
                              )}

                              {pageOwnerId === currentUserData?.uid && !isVisitorView ? (
                                 <Pressable style={styles.button} onPress={() => setShowEditGoal(true)}>
                                    <Text style={styles.buttonText}>Edit Goal</Text>
                                 </Pressable>
                              ) : (
                                 goalData.targetAmount ? (
                                    <Pressable style={styles.button} onPress={handleContribution}>
                                       <Text style={styles.contributionButtonText}>Make a Contribution</Text>
                                    </Pressable>
                                 ) : (
                                    <View style={styles.noGoalContainer}>
                                       <Text style={styles.noGoalText}>This graduate hasn't set a goal yet. Please check back later.</Text>
                                    </View>
                                 )
                              )}
                              {pageOwnerId === currentUserData?.uid && !pageOwner?.stripeAccountId && !isVisitorView &&
                                 <Pressable style={styles.button} onPress={() => setShowConnectedAccountModal(true)}>
                                    <Text style={styles.buttonText}>Start Accepting Contributions</Text>
                                 </Pressable>
                              }
                           </View>
                        </View>
                     )}
                  </View>
               </ImageBackground>
            )}

            {activeTab === "Registry" && (
               <ImageBackground
                  source={{ uri: backgroundImage }}
                  style={styles.yearbookBackground}
                  imageStyle={styles.yearbookImage}
               >
                  <View style={styles.registryContainer}>
                     {pageOwner.pages.registry ? (
                        <Text style={styles.registryText}>Redirecting to the registry...</Text>
                     ) : (
                        <View style={styles.noRegistryContainer}>
                           <Text style={styles.noRegistryText}>
                              {isVisitorView ? (
                                 "This graduate hasn't created a registry yet, but you can click here for gift ideas and to make a purchase for them."
                              ) : (
                                 pageOwnerId === currentUserData?.uid
                                    ? "You haven't created your registry yet. Start creating it here."
                                    : "This graduate hasn't created a registry yet, but you can click here for gift ideas and to make a purchase for them."
                              )}
                           </Text>
                           <Pressable
                              style={styles.catalogueButton}
                              onPress={() => {
                                 if (!isVisitorView && pageOwnerId === currentUserData?.uid) {
                                    navigation.navigate("Hub", { showRegistryModal: true });
                                 } else {
                                    setShowCatalogueModal(true);
                                 }
                              }}
                           >
                              <Text style={styles.catalogueButtonText}>
                                 {!isVisitorView && pageOwnerId === currentUserData?.uid ? "Create Registry" : "Go to Catalogue"}
                              </Text>
                           </Pressable>
                        </View>
                     )}
                  </View>
               </ImageBackground>
            )}

            {activeTab === "Share" && (
               <ImageBackground
                  source={{ uri: backgroundImage }}
                  style={styles.yearbookBackground}
                  imageStyle={styles.yearbookImage}
               >
                  <View style={styles.shareFormContainer}>
                     <Text style={styles.shareFormTitle}>Share Announcement Page</Text>
                     
                     {isSharing && <Text style={styles.successMessage}>Sending invitations...</Text>}
                     {isShared && !isError && <Text style={styles.successMessage}>Shared successfully!</Text>}
                     {isError && <Text style={styles.errorMessage}>{error}</Text>}
                     
                     <View style={styles.recipientTypeContainer}>
                        <Pressable 
                           onPress={() => {
                              setRecipientType("email");
                              setError("");
                              setIsError(false);
                           }}
                           style={{
                              ...styles.typeButton,
                              ...(recipientType === "email" ? styles.activeTypeButton : {})
                           }}
                        >
                           <Text style={recipientType === "email" ? styles.activeTypeButtonText : styles.typeButtonText}>Email</Text>
                        </Pressable>
                        <Pressable 
                           onPress={() => {
                              setRecipientType("phone");
                              setError("");
                              setIsError(false);
                           }}
                           style={{
                              ...styles.typeButton,
                              ...(recipientType === "phone" ? styles.activeTypeButton : {})
                           }}
                        >
                           <Text style={recipientType === "phone" ? styles.activeTypeButtonText : styles.typeButtonText}>SMS</Text>
                        </Pressable>
                     </View>

                     {recipientType === "email" ? (
                        <>
                           <TextInput
                              style={{
                                 ...styles.input,
                                 ...(isError && styles.inputError)
                              }}
                              placeholder="Enter recipient emails, separated by commas"
                              value={recipientEmails}
                              onChangeText={(text) => {
                                 setError("");
                                 setIsError(false);
                                 setRecipientEmails(text);
                              }}
                           />
                           <Pressable 
                              style={styles.button} 
                              onPress={handleShare}
                              disabled={isSharing || !recipientEmails.trim()}
                           >
                              <Text style={styles.buttonText}>Share via Email</Text>
                           </Pressable>
                        </>
                     ) : (
                        <>
                           <TextInput
                              style={{
                                 ...styles.input,
                                 ...(isError && styles.inputError)
                              }}
                              placeholder="Enter phone numbers, separated by commas"
                              value={phoneNumbers}
                              onChangeText={(text) => {
                                 setError("");
                                 setIsError(false);
                                 setPhoneNumbers(text);
                              }}
                           />
                           <Pressable 
                              style={styles.button} 
                              onPress={() => {
                                 const phoneList = phoneNumbers.split(',').map(phone => phone.trim());
                                 shareViaSMS(phoneList);
                              }}
                              disabled={isSharing || !phoneNumbers.trim()}
                           >
                              <Text style={styles.buttonText}>Share via SMS</Text>
                           </Pressable>
                        </>
                     )}
                  </View>
               </ImageBackground>
            )}
         </View>

         {/* eCard Selection Modal */}
         <ECardSelectionModal
            isVisible={showECardModal}
            onSelect={handleECardSelection}
            onClose={() => setShowECardModal(false)}
         />

         {/* Checkout Modal */}
         <Elements stripe={stripePromise}>
            <Checkout
               isVisible={showCheckoutModal}
               onClose={() => setShowCheckoutModal(false)}
               onContribute={submitContribution}
               selectedECard={selectedECard}
               pageOwner={pageOwner}
            />
         </Elements>

         {/* Connected Account Modal */}
         {showConnectedAccountModal &&
            <>
               <ConnectedAccount pageOwner={pageOwner} />
               <ConnectedAccountModal
                  visible={showConnectedAccountModal}
                  onClose={() => setShowConnectedAccountModal(false)}
                  pageOwner={pageOwner}
               />
            </>
         }

         {/* Catalogue Modal */}
         {showCatalogueModal && (
            <View style={styles.modalOverlay}>
               <View style={[styles.modalContent, styles.catalogueModalContent]}>
                  <Pressable style={styles.closeButton} onPress={() => setShowCatalogueModal(false)}>
                     <Text style={styles.closeButtonText}>X</Text>
                  </Pressable>
                  <ScrollView style={styles.catalogueScrollView}>
                     <Catalogue
                        isVisible={showCatalogueModal}
                        onClose={() => setShowCatalogueModal(false)}
                     />
                  </ScrollView>
               </View>
            </View>
         )}

         <SharedModal 
            isVisible={showEmailWarning}
            onClose={() => setShowEmailWarning(false)}
            title="Heads Up! Check That Junk Folder!"
            message="Sometimes our emails like to play hide and seek and might end up in a spam or junk folder! Let your friends and family know to give that folder a quick peek. We promise we're not spam - just here to celebrate your big moment!"
            confirmText="Got it, Send Email"
            onConfirm={() => {
               setShowEmailWarning(false);
               shareAnnouncementPage();
            }}
         />
      </View>
   );
};

const styles = StyleSheet.create({
   container: {
      flex: 1,
      minHeight: isMobile ? "80vh" : 400,
      height: isMobile ? "80vh" : Dimensions.get("window").height - 100,
      width: isMobile ? "90vw" : Dimensions.get("window").width - 100,
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
      padding: 20,
      backgroundColor: "transparent",
      borderRadius: 20,
      shadowColor: "rgba(0, 0, 0, 0)",
      shadowOffset: { width: 0, height: 6 },
      shadowOpacity: 0.25,
      shadowRadius: 3.84,
      elevation: 5,
      marginVertical: 10,
      marginTop: 50,
      zIndex: 2,
   },
   innerContainer: {
      minHeight: 400,
      height: isMobile ? "80vh" : Dimensions.get("window").height - 500,
      width: isMobile ? "90vw" : Dimensions.get("window").width - 100,
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
      borderRadius: 20,
      shadowColor: "rgba(0, 0, 0, 0.5)",
      shadowOffset: { width: 0, height: 6 },
      shadowOpacity: 0.25,
      shadowRadius: 3.84,
      elevation: 5,
      marginVertical: 10,
      marginTop: 50,
      zIndex: 2,
      backgroundColor: "rgba(254, 253, 226, 1)",
   },
   tabContainer: {
      flexDirection: "row",
      justifyContent: "center",
      position: "absolute",
      top: -45,
      zIndex: 1,
      width: "100vw",
      zIndex: 1,
   },
   tabButton: {
      padding: isMobile ? 7.5 : 10,
      borderRadius: 5,
      marginHorizontal: 5,
      borderTopLeftRadius: 15,
      borderTopRightRadius: 15,
      borderRadius: 15,
      borderBottomWidth: 0,
      zIndex: 1,
      backgroundColor: "rgba(254, 253, 226, .90)",
   },
   activeTabButton: {
      backgroundColor: "rgba(254, 253, 226, 1)",
      borderBottomWidth: 0,
      borderTopLeftRadius: 15,
      borderTopRightRadius: 15,
      zIndex: 1,
   },
   tabButtonText: {
      fontWeight: "bold",
      color: "#ccc",
      fontSize: isMobile ? 10 : 16,
   },
   activeTabButtonText: {
      fontWeight: "bold",
      color: "#4c956c",
      fontSize: isMobile ? 10 : 16,
   },
   yearbookBackground: {
      flex: 1,
      width: "100%",
      padding: 20,
      borderRadius: 15,
      borderTopWidth: 0,
      zIndex: 0,
   },
   yearbookImage: {
      flex: 1,
      resizeMode: "cover",
      borderRadius: 15,
   },
   yearbookContainer: {
      flex: 1,
      padding: 20,
      backgroundColor: "rgba(255, 255, 255, 0.8)",
      borderRadius: 15,
      overflow: "hidden",
      justifyContent: "space-between",
      borderRadius: 15,
   },
   messagesContainer: {
      flex: 1,
      marginBottom: 10,
      borderRadius: 15,
   },
   message: {
      marginBottom: 10,
      padding: isMobile ? 7.5 : 10,
      borderRadius: 10,
      flexDirection: "row",
      alignItems: "center",
   },
   currentUserMessage: {
      backgroundColor: "#4c956c",
      alignSelf: "flex-end",
   },
   otherUserMessage: {
      backgroundColor: "rgba(254, 253, 226, 1)",
      alignSelf: "flex-start",
   },
   messageImage: {
      width: "100%",
      height: isMobile ? 50 : 200,
      borderRadius: 10,
      marginBottom: 10,
   },
   profileImage: {
      width: isMobile ? 20 : 25,
      height: isMobile ? 20 : 25,
      borderRadius: 25,
      marginRight: 10,
   },
   messageContent: {
      flex: 1,
   },
   messageSender: {
      fontWeight: "bold",
      marginBottom: 5,
      color: "#000",
      fontSize: isMobile ? 10 : 16,
   },
   messageText: {
      fontSize: isMobile ? 10 : 16,
      color: "#000",
   },
   messageTimestamp: {
      color: "#666",
      marginTop: 2,
      fontSize: isMobile ? 8 : 12,
   },
   yearbookInputContainer: {
      flexDirection: "row",
      alignItems: "center",
      backgroundColor: "rgba(254, 253, 226, 1)",
      borderRadius: 10,
      padding: isMobile ? 7.5 : 10,
      marginBottom: isMobile ? 5 : 10, // Ensure spacing at the bottom
      width: isMobile ? "100%" : "100%",
   },
   yearbookInput: {
      flex: 1,
      marginRight: 10,
      padding: 10,
      borderRadius: 10,
      borderColor: "#ccc",
      backgroundColor: "#fff",
      borderWidth: 1,
      width: "70%",
      height: 40,
      marginRight: 10,
      fontSize: isMobile ? 10 : 16,
   },
   yearbookInputPlaceholder: {
      color: "#ccc",
      fontSize: isMobile ? 10 : 16,
   },
   uploadButton: {
      backgroundColor: "#4c956c",
      padding: isMobile ? 7.5 : 10,
      borderRadius: 10,
      marginRight: isMobile ? 5 : 10,
      justifyContent: "center",
      alignItems: "center",
   },
   uploadButtonGoal: {
      backgroundColor: "#4c956c",
      padding: 10,
      borderRadius: 10,
      marginRight: 10,
      marginBottom: 10,
      justifyContent: "center",
      alignItems: "center",
   },
   uploadButtonText: {
      color: "#fff",
      fontWeight: "bold",
      fontSize: isMobile ? 10 : 16,
   },
   sendButton: {
      backgroundColor: "#4c956c",
      padding: isMobile ? 7.5 : 10,
      borderRadius: 10,
      width: isMobile ? "20%" : "10%",
      justifyContent: "center",
      alignItems: "center",
   },
   sendButtonText: {
      color: "#fff",
      fontWeight: "bold",
      fontSize: isMobile ? 10 : 16,
   },
   signatureContainer: {
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: 10,
   },
   signatureInput: {
      flex: 1,
      borderWidth: 1,
      borderColor: "#ddd",
      padding: isMobile ? 7.5 : 10,
      fontSize: isMobile ? 10 : 16,
      borderRadius: 10,
      marginHorizontal: 5,
      backgroundColor: "#fff",
   },
   goalTrackerBackground: {
      flex: 1,
      width: "100%",
      // padding: 20,
      borderRadius: 15,
      borderTopWidth: 0,
      zIndex: 0,
   },
   goalTrackerImage: {
      borderRadius: 15,
      zIndex: 3,
   },
   goalTrackerContent: {
      flexDirection: "row",
      alignItems: "center",
      backgroundColor: "rgba(255, 255, 255, 0.2)",
      borderRadius: 15,
      padding: isMobile ? 10 : 20,
      height: "100%",
      width: "100%",
      zIndex: 3,
   },
   goalDetails: {
      flexDirection: "row",
      alignItems: "center",
      width: "80%",
   },
   goalTextContainer: {
      flex: 1,
      marginLeft: 20,
      width: isMobile ? "100%" : "80%",
   },
   goalImage: {
      width: isMobile ? "0%" : 400,
      height: isMobile ? "0%" : 400,
      borderRadius: 10,
   },
   title: {
      fontSize: 24,
      fontWeight: "bold",
      marginBottom: 10,
      color: "#fff",
      textShadowColor: "rgba(0, 0, 0, 0.5)",
      textShadowRadius: 3.84,
   },
   description: {
      fontSize: 16,
      marginBottom: 10,
      color: "#fff",
      textShadowColor: "rgba(0, 0, 0, 0.5)",
      textShadowRadius: 3.84,
   },
   amount: {
      fontSize: 18,
      marginBottom: 10,
      color: "#fff",
      textShadowColor: "rgba(0, 0, 0, 0.5)",
      textShadowRadius: 3.84,
   },
   contributionBar: {
      height: 20,
      backgroundColor: "#ddd",
      borderRadius: 10,
      overflow: "hidden",
      marginBottom: 10,
      shadowColor: "rgba(0, 0, 0, 0.5)",
      shadowOffset: { width: 0, height: 6 },
      shadowOpacity: 0.25,
      shadowRadius: 3.84,
      elevation: 5,
      width: "100%",
   },
   filledBar: {
      height: "100%",
      backgroundColor: "#4c956c",
      shadowColor: "rgba(0, 0, 0, 0.5)",
      shadowOffset: { width: 0, height: 6 },
      shadowOpacity: 0.25,
      shadowRadius: 3.84,
      elevation: 5,
      width: "100%",
   },
   messageCard: {
      flexDirection: "row",
      backgroundColor: "#4c956c",
      padding: 10,
      borderRadius: 5,
      marginBottom: 10,
   },
   pageOwnerMessage: {
      alignSelf: "flex-end",
   },
   userMessage: {
      alignSelf: "flex-start",
   },
   profilePhoto: {
      width: 40,
      height: 40,
      borderRadius: 20,
      marginRight: 10,
   },
   messageContent: {
      flex: 1,
   },
   messageSender: {
      fontWeight: "bold",
      color: "#fff",
   },
   messageText: {
      marginTop: 5,
      color: "#fff",
   },
   messageImage: {
      width: 100,
      height: 100,
      marginTop: 5,
      borderRadius: 5,
   },
   messageTimestamp: {
      marginTop: 5,
      fontSize: 10,
      color: "#fff",
   },
   modalContent: {
      backgroundColor: "#fff",
      padding: 20,
      borderRadius: 20,
      shadowColor: "rgba(0, 0, 0, 0.5)",
      shadowOffset: { width: 0, height: 6 },
      shadowOpacity: 0.25,
      shadowRadius: 3.84,
      elevation: 5,
   },
   text: {
      fontSize: 18,
      marginBottom: 10,
   },
   buttonRed: {
      backgroundColor: "#cc0000",
      padding: 10,
      borderRadius: 5,
      alignItems: "center",
      marginTop: 10,
   },
   modalContainer: {
      position: "absolute",
      top: 20,
      left: 20,
      right: 20,
      bottom: 20,
      backgroundColor: "rgba(255, 255, 255, 0.9)",
      borderRadius: 15,
      padding: 20,
      zIndex: 1000,
   },
   closeButton: {
      position: "absolute",
      top: 20,
      left: 20,
      width: 30,
      height: 30,
      borderRadius: 15,
      backgroundColor: "red",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 3,
   },
   closeButtonText: {
      color: "#fff",
      fontSize: 20,
   },
   otherUserText: {
      color: "#333",
   },
   editGoalContainer: {
      flex: 1,
      padding: 20,
      backgroundColor: "rgba(255, 255, 255, 0.9)",
      borderRadius: 15,
   },
   contributionContainer: {
      flex: 1,
      padding: 20,
      backgroundColor: "rgba(255, 255, 255, 0.9)",
      borderRadius: 15,
   },
   previewContainer: {
      marginTop: 10,
      alignItems: 'center',
      paddingBottom: 10
   },
   previewImage: {
      width: 100,
      height: 100,
      borderRadius: 10,
   },
   modalContainer: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
   },
   modalContent: {
      backgroundColor: '#fff',
      padding: 30,
      borderRadius: 15,
      width: '95%',
      maxWidth: isMobile ? '95%' : '90%',
      maxHeight: '90vh',
      alignItems: 'center',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      zIndex: 100000,
      position: 'fixed',
      left: '50%',
      top: '50%',
      transform: [{ translateX: '-50%' }, { translateY: '-50%' }],
      overflow: 'auto'
   },
   modalTitle: {
      fontSize: 18,
      fontWeight: "bold",
      marginBottom: 10,
   },
   input: {
      width: "100%",
      padding: 10,
      borderColor: "#ccc",
      borderWidth: 1,
      borderRadius: 5,
      marginBottom: 10,
      fontSize: isMobile ? 10 : 16,
   },
   button: {
      backgroundColor: "#4c956c",
      padding: isMobile ? 7.5 : 10,
      borderRadius: 5,
      alignItems: "center",
      marginBottom: 10,
   },
   buttonText: {
      color: "#fff",
      fontWeight: "bold",
      fontSize: isMobile ? 10 : 16,
   },
   contributionButtonText: {
      color: "#fff",
      fontWeight: "bold",
      fontSize: isMobile ? 12 : 16,
   },
   shareFormContainer: {
      padding: 20,
      backgroundColor: "#fefde2",
      borderRadius: 10,
      alignItems: "center",
      marginTop: 50,
      width: "100%",
   },
   shareFormTitle: {
      fontSize: isMobile ? 16 : 22,
      fontWeight: "bold",
      marginBottom: isMobile ? 5 : 10,
   },
   noGoalContainer: {
      justifyContent: 'center',
      alignItems: 'center',
   },
   noGoalText: {
      fontSize: isMobile ? 16 : 20,
      textAlign: "center",
      marginTop: isMobile ? 10 : 20,
      color: "#fefde2",
      fontWeight: "bold",
      position: "absolute",
      justifyContent: "center",
      alignItems: "center",
      textShadowColor: "rgba(0, 0, 0, 0.25)",
      textShadowRadius: 3.84,
      textShadowOffset: { width: 0, height: 4 },
      textShadowRadius: 3.84,
      textAlign: "center",
   },
   loadingText: {
      color: "blue",
      fontSize: isMobile ? 10 : 16,
      marginTop: 10,
   },
   errorText: {
      color: "red",
      fontSize: isMobile ? 10 : 16,
      marginTop: 10,
   },
   successText: {
      color: "green",
      fontSize: isMobile ? 10 : 16,
      marginTop: 10,
   },
   registryContainer: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      padding: 18,
   },
   noRegistryContainer: {
      justifyContent: 'center',
      alignItems: 'center',
   },
   noRegistryText: {
      fontSize: 20,
      textAlign: 'center',
      marginBottom: 20,
      color: "#fefde2",
      fontWeight: "bold",
   },
   catalogueButton: {
      backgroundColor: '#4c956c',
      padding: 10,
      borderRadius: 5,
      // backgroundColor: "red"
   },
   catalogueButtonText: {
      color: '#fff',
      fontWeight: 'bold',
   },
   blurBackground: {
      filter: 'blur(5px)',
      zIndex: 1,
      width: "100%",
      height: "100%",
   },
   modalOverlay: {
      position: 'fixed',
      inset: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      backdropFilter: 'blur(10px)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 99999,
      overflow: 'hidden',
   },
   successMessage: {
      color: "green",
      fontSize: isMobile ? 12 : 16,
      marginBottom: 10,
   },
   errorMessage: {
      color: "red",
      fontSize: isMobile ? 12 : 16,
      marginBottom: 10,
   },
   recipientTypeContainer: {
      flexDirection: "row",
      justifyContent: "center",
      marginBottom: 10,
   },
   typeButton: {
      padding: isMobile ? 7.5 : 10,
      borderRadius: 5,
      marginHorizontal: 5,
   },
   activeTypeButton: {
      backgroundColor: "#4c956c",
      borderBottomWidth: 0,
      borderTopLeftRadius: 15,
      borderTopRightRadius: 15,
      zIndex: 1,
   },
   typeButtonText: {
      fontWeight: "bold",
      color: "#ccc",
      fontSize: isMobile ? 10 : 16,
   },
   activeTypeButtonText: {
      fontWeight: "bold",
      color: "#fff",
      fontSize: isMobile ? 10 : 16,
   },
   inputError: {
      borderColor: "red",
   },
   comingSoonContainer: {
      alignItems: 'center',
      padding: 20,
      backgroundColor: '#fefde2',
      borderRadius: 10,
      margin: 20,
   },
   comingSoonText: {
      fontSize: 24,
      color: '#4c956c',
      fontWeight: 'bold',
      marginBottom: 10,
      textAlign: 'center',
   },
   comingSoonSubtext: {
      fontSize: 16,
      color: '#666',
      textAlign: 'center',
   },
   modalTitle: {
      color: '#4c956c',
      marginBottom: 20,
      fontSize: 24,
      fontWeight: 'bold',
      textAlign: 'center',
      width: '100%',
   },
   modalText: {
      color: '#333',
      marginBottom: 25,
      lineHeight: 24,
      textAlign: 'center',
      fontSize: 16,
      width: '100%',
   },
   modalButtons: {
      flexDirection: 'row',
      justifyContent: 'center',
      gap: 15,
      width: '100%',
      marginTop: 10,
   },
   modalButton: {
      padding: 10,
      paddingHorizontal: 20,
      borderRadius: 5,
      backgroundColor: '#4c956c',
      minWidth: 120,
      alignItems: 'center',
   },
   modalButtonText: {
      color: 'white',
      fontSize: 16,
      fontWeight: 'bold',
   },
   catalogueModalContent: {
      padding: 15,
      width: '95vw',
      maxWidth: '95vw',
      height: '90vh',
      maxHeight: '90vh',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column'
   },
   catalogueScrollView: {
      flex: 1,
      width: '100%',
      marginTop: 40
   },
});

export default CombinedCard;







import React from "react";
import { View, Text, ScrollView, StyleSheet, Platform } from "react-native";
import { REACT_APP_AWS_SERVER_URL } from '@env';	

const Terms = () => {
	let LOCAL_SERVER_URL = "http://localhost:3300";
	let AWS_SERVER_URL = REACT_APP_AWS_SERVER_URL;

	const isDevelopment = process.env.NODE_ENV === "development";
	const SERVER_URL = isDevelopment ? LOCAL_SERVER_URL : AWS_SERVER_URL;

	const pdfFileUrl = `${SERVER_URL}/pdfs/Terms of Service.pdf`;

	return (
		<View style={styles.body}>
			<ScrollView style={styles.pdfContainer}>
				<View style={styles.pdfWrapper}>
					{Platform.OS === "web" ? (
						<iframe 
							src={pdfFileUrl} 
							style={styles.pdf} 
							frameBorder="0"
						>
							Your browser does not support iframes.
						</iframe>
					) : (
						<Text style={styles.text}>PDF display is not supported on this platform.</Text>
					)}
				</View>
			</ScrollView>
		</View>
	);
};

// Styles
const styles = StyleSheet.create({
	body: {
		flex: 1,
		alignItems: "center",
		backgroundColor: "#4c956c",
		height: "100vh"
	},
	pdfContainer: {
		flex: 1,
		width: "90vw",
		borderWidth: 1,
		borderColor: "#fff",
		borderRadius: 8,
		overflow: "hidden",
	},
	pdfWrapper: {
		paddingTop: 20,
	},
	pdf: {
		width: "100%",
		height: "100vh",
		minHeight: 500,
	},
	text: {
		fontFamily: "Bodoni72Smallcaps",
		textAlign: "center",
		margin: 20,
	}
});

export default Terms;

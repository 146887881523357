import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, Dimensions, Pressable, ActivityIndicator, ScrollView, Switch, Platform, Image } from 'react-native';
import { getAnalyticsData } from '../../services/AnalyticsService';
import getFirebaseAnalyticsData from '../../services/FirebaseAnalyticsService';

// Safely import chart components with error handling
let LineChart, BarChart, PieChart;
try {
  const chartKit = require('react-native-chart-kit');
  // Create wrapped versions of the charts that handle touch events properly
  const createWebCompatibleChart = (ChartComponent) => {
    return React.forwardRef((props, ref) => {
      // Remove touch-specific props when running in web
      const webProps = { ...props };
      
      // List of all React Native touch responder props to remove for web
      const touchProps = [
        'onStartShouldSetResponder',
        'onMoveShouldSetResponder',
        'onResponderGrant',
        'onResponderMove',
        'onResponderRelease',
        'onResponderTerminate',
        'onResponderTerminationRequest',
        'onStartShouldSetResponderCapture',
        'onMoveShouldSetResponderCapture',
        'onResponderEnd',
        'onResponderStart'
      ];
      
      // Remove all touch responder props for web environment
      if (Platform.OS === 'web') {
        touchProps.forEach(prop => {
          if (webProps[prop]) delete webProps[prop];
          if (webProps.style && webProps.style[prop]) delete webProps.style[prop];
        });

        // Add web-specific styles properly
        webProps.style = {
          ...(webProps.style || {}),
          // Replace outline with borderWidth and borderColor
          borderWidth: 0,
          borderColor: 'transparent',
          // Use proper React Native style properties
          userSelect: 'none',
          cursor: 'default'
        };
      }
      
      return <ChartComponent {...webProps} ref={ref} />;
    });
  };
  
  // Wrap all chart components
  LineChart = createWebCompatibleChart(chartKit.LineChart);
  BarChart = createWebCompatibleChart(chartKit.BarChart);
  PieChart = createWebCompatibleChart(chartKit.PieChart);
} catch (error) {
  console.error('Error importing chart components:', error);
  // Create placeholder components
  const PlaceholderChart = ({ children }) => (
    <View style={{ height: 220, backgroundColor: '#f5f5f5', justifyContent: 'center', alignItems: 'center' }}>
      <Text>Chart component not available</Text>
    </View>
  );
  LineChart = PlaceholderChart;
  BarChart = PlaceholderChart;
  PieChart = PlaceholderChart;
}

// Safely import date-fns
let format, subDays, subWeeks, subMonths, startOfDay, endOfDay, startOfWeek, endOfWeek, startOfMonth, endOfMonth;
try {
  // Import the entire date-fns library to ensure all functions are available
  const dateFns = require('date-fns');
  
  // Explicitly check if each function exists before assigning
  format = typeof dateFns.format === 'function' ? dateFns.format : null;
  subDays = typeof dateFns.subDays === 'function' ? dateFns.subDays : null;
  subWeeks = typeof dateFns.subWeeks === 'function' ? dateFns.subWeeks : null;
  subMonths = typeof dateFns.subMonths === 'function' ? dateFns.subMonths : null;
  startOfDay = typeof dateFns.startOfDay === 'function' ? dateFns.startOfDay : null;
  endOfDay = typeof dateFns.endOfDay === 'function' ? dateFns.endOfDay : null;
  startOfWeek = typeof dateFns.startOfWeek === 'function' ? dateFns.startOfWeek : null;
  endOfWeek = typeof dateFns.endOfWeek === 'function' ? dateFns.endOfWeek : null;
  startOfMonth = typeof dateFns.startOfMonth === 'function' ? dateFns.startOfMonth : null;
  endOfMonth = typeof dateFns.endOfMonth === 'function' ? dateFns.endOfMonth : null;
  
  // Log which functions were successfully imported
  console.log('Date-fns functions loaded:', {
    format: !!format,
    subDays: !!subDays,
    subWeeks: !!subWeeks,
    subMonths: !!subMonths,
    startOfDay: !!startOfDay,
    endOfDay: !!endOfDay,
    startOfWeek: !!startOfWeek,
    endOfWeek: !!endOfWeek,
    startOfMonth: !!startOfMonth,
    endOfMonth: !!endOfMonth
  });
} catch (error) {
  console.error('Error importing date-fns:', error);
}

// Define fallback functions regardless of whether the import succeeded
// This ensures we always have these functions available
if (!format) format = (date, formatStr) => date.toLocaleDateString();
if (!subDays) subDays = (date, days) => new Date(date.getTime() - days * 24 * 60 * 60 * 1000);
if (!subWeeks) subWeeks = (date, weeks) => new Date(date.getTime() - weeks * 7 * 24 * 60 * 60 * 1000);
if (!subMonths) subMonths = (date, months) => {
  const newDate = new Date(date);
  newDate.setMonth(date.getMonth() - months);
  return newDate;
};
if (!startOfDay) startOfDay = (date) => {
  const newDate = new Date(date);
  newDate.setHours(0, 0, 0, 0);
  return newDate;
};
if (!endOfDay) endOfDay = (date) => {
  const newDate = new Date(date);
  newDate.setHours(23, 59, 59, 999);
  return newDate;
};
if (!startOfWeek) startOfWeek = (date, options = { weekStartsOn: 0 }) => {
  try {
    if (!date) return new Date();
    const newDate = new Date(date);
    const day = newDate.getDay();
    const weekStartsOn = options && options.weekStartsOn !== undefined ? options.weekStartsOn : 0;
    const diff = (day < weekStartsOn ? 7 : 0) + day - weekStartsOn;
    newDate.setDate(newDate.getDate() - diff);
    newDate.setHours(0, 0, 0, 0);
    return newDate;
  } catch (err) {
    console.error('Error in startOfWeek fallback:', err);
    // Return current date as absolute fallback
    const fallback = new Date();
    fallback.setHours(0, 0, 0, 0);
    return fallback;
  }
};
if (!endOfWeek) endOfWeek = (date, options = { weekStartsOn: 0 }) => {
  try {
    if (!date) return new Date();
    const newDate = new Date(date);
    const day = newDate.getDay();
    const weekStartsOn = options && options.weekStartsOn !== undefined ? options.weekStartsOn : 0;
    const diff = 6 - day + weekStartsOn;
    newDate.setDate(newDate.getDate() + diff);
    newDate.setHours(23, 59, 59, 999);
    return newDate;
  } catch (err) {
    console.error('Error in endOfWeek fallback:', err);
    // Return current date as absolute fallback
    const fallback = new Date();
    fallback.setHours(23, 59, 59, 999);
    return fallback;
  }
};
if (!startOfMonth) startOfMonth = (date) => {
  const newDate = new Date(date);
  newDate.setDate(1);
  newDate.setHours(0, 0, 0, 0);
  return newDate;
};
if (!endOfMonth) endOfMonth = (date) => {
  const newDate = new Date(date);
  newDate.setMonth(newDate.getMonth() + 1);
  newDate.setDate(0);
  newDate.setHours(23, 59, 59, 999);
  return newDate;
};

const { width } = Dimensions.get('window');
const isMobile = width < 768;
const isTablet = width >= 768 && width < 1024;
const isSmallDesktop = width >= 1024 && width < 1440;
const isLargeDesktop = width >= 1440;

const chartConfig = {
  backgroundColor: '#ffffff',
  backgroundGradientFrom: '#ffffff',
  backgroundGradientTo: '#ffffff',
  decimalPlaces: 0,
  color: (opacity = 1) => `rgba(44, 110, 73, ${opacity})`,
  labelColor: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
  style: {
    borderRadius: 16,
  },
  propsForDots: {
    r: '6',
    strokeWidth: '2',
    stroke: '#4c956c',
  },
};

const metricDescriptions = {
  totalUsers: "Total number of registered users on MyTassel",
  newUsers: "Users who registered in the last 24 hours",
  activeUsers: "Users who logged in within the last 24 hours",
  pageViews: "Total page views across all MyTassel pages in the last 24 hours"
};

const renderMetric = (title, value, description) => (
  <View style={styles.metricContainer}>
    <Text style={styles.metricTitle}>{title}</Text>
    <Text style={styles.metricValue}>{value}</Text>
    <Text style={styles.metricDescription}>{description}</Text>
  </View>
);

// Add default values and type checking for metrics
const safeNumber = (value) => {
  const num = Number(value);
  return !isNaN(num) ? num : 0;
};

const Analytics = () => {
  // State for time period selection
  const [timePeriod, setTimePeriod] = useState('week');
  
  // State for analytics data
  const [totalUsers, setTotalUsers] = useState(0);
  const [newUsers, setNewUsers] = useState(0);
  const [activeUsers, setActiveUsers] = useState(0);
  const [pageViews, setPageViews] = useState(0);
  const [avgSessionDuration, setAvgSessionDuration] = useState(0);
  const [bounceRate, setBounceRate] = useState(0);
  const [conversionRate, setConversionRate] = useState(0);
  const [popularPages, setPopularPages] = useState([]);
  const [popularItems, setPopularItems] = useState([]);
  const [dailyStats, setDailyStats] = useState([]);
  
  // Add error state
  const [error, setError] = useState(null);
  
  // State for loading
  const [loading, setLoading] = useState(true);
  
  // Add pagination state for registry items
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  
  // Add a safe wrapper for date range functions
  const safeGetDateRange = (rangeType, now) => {
    try {
      switch (rangeType) {
        case 'day':
          return {
            start: startOfDay(now),
            end: endOfDay(now),
            label: 'Today',
            previous: {
              start: startOfDay(subDays(now, 1)),
              end: endOfDay(subDays(now, 1))
            }
          };
        case 'week':
          return {
            start: startOfWeek(now, { weekStartsOn: 0 }),
            end: endOfWeek(now, { weekStartsOn: 0 }),
            label: 'This Week',
            previous: {
              start: startOfWeek(subWeeks(now, 1), { weekStartsOn: 0 }),
              end: endOfWeek(subWeeks(now, 1), { weekStartsOn: 0 })
            }
          };
        case 'month':
          return {
            start: startOfMonth(now),
            end: endOfMonth(now),
            label: 'This Month',
            previous: {
              start: startOfMonth(subMonths(now, 1)),
              end: endOfMonth(subMonths(now, 1))
            }
          };
        case 'quarter':
          return {
            start: startOfMonth(subMonths(now, 3)),
            end: endOfMonth(now),
            label: 'Last 3 Months',
            previous: {
              start: startOfMonth(subMonths(now, 4)),
              end: endOfMonth(subMonths(now, 3))
            }
          };
        case 'year':
          return {
            start: startOfMonth(subMonths(now, 12)),
            end: endOfMonth(now),
            label: 'Last 12 Months',
            previous: {
              start: startOfMonth(subMonths(now, 24)),
              end: endOfMonth(subMonths(now, 12))
            }
          };
        default:
          return {
            start: startOfDay(now),
            end: endOfDay(now),
            label: 'Today',
            previous: {
              start: startOfDay(subDays(now, 1)),
              end: endOfDay(subDays(now, 1))
            }
          };
      }
    } catch (error) {
      console.error(`Error getting date range for ${rangeType}:`, error);
      // Return a safe fallback
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(yesterday.getDate() - 1);
      
      return {
        start: today,
        end: today,
        label: 'Today (Fallback)',
        previous: {
          start: yesterday,
          end: yesterday
        }
      };
    }
  };
  
  // Fetch analytics data
  const fetchAnalyticsData = async () => {
    setLoading(true);
    setError(null);
    setCurrentPage(1);

    try {
      const now = new Date();
      const { start, end, previous } = safeGetDateRange(timePeriod, now);

      // Create a safe data fetching function that returns a default value if the fetch fails
      const safeDataFetch = async (fetchFunction, defaultValue) => {
        try {
          const result = await fetchFunction;
          return safeNumber(result ?? defaultValue);
        } catch (error) {
          console.error(`Error fetching analytics data: ${error.message}`);
          return defaultValue;
        }
      };

      // Fetch current period data with safe number conversion
      const [
        totalUsersCount,
        newUsersCount,
        activeUsersCount,
        pageViewsCount,
        avgSessionDurationMs,
        bounceRatePercent,
        conversionRatePercent,
        popularPagesData,
        popularItemsData,
        dailyStatsData
      ] = await Promise.all([
        safeDataFetch(getFirebaseAnalyticsData.getTotalUserCount(start, end), 0),
        safeDataFetch(getFirebaseAnalyticsData.getNewUserCount(start, end), 0),
        safeDataFetch(getFirebaseAnalyticsData.getActiveUserCount(start, end), 0),
        safeDataFetch(getFirebaseAnalyticsData.getPageViewCount(start, end), 0),
        safeDataFetch(getFirebaseAnalyticsData.getAverageSessionDuration(start, end), 0),
        safeDataFetch(getFirebaseAnalyticsData.getBounceRate(start, end), 0),
        safeDataFetch(getFirebaseAnalyticsData.getConversionRate(start, end), 0),
        getFirebaseAnalyticsData.getPopularPages(start, end),
        getFirebaseAnalyticsData.getPopularRegistryItems(start, end),
        getFirebaseAnalyticsData.getDailyStats(start, end)
      ]);

      // Update state with safe numeric values
      setTotalUsers(safeNumber(totalUsersCount));
      setNewUsers(safeNumber(newUsersCount));
      setActiveUsers(safeNumber(activeUsersCount));
      setPageViews(safeNumber(pageViewsCount));
      setAvgSessionDuration(safeNumber(avgSessionDurationMs));
      setBounceRate(safeNumber(bounceRatePercent));
      setConversionRate(safeNumber(conversionRatePercent));
      setPopularPages(Array.isArray(popularPagesData) ? popularPagesData : []);
      setPopularItems(Array.isArray(popularItemsData) ? popularItemsData : []);
      setDailyStats(Array.isArray(dailyStatsData) ? dailyStatsData : []);

    } catch (error) {
      console.error('Error in analytics data fetching process:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };
  
  // Format time in minutes and seconds
  const formatTime = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    
    return `${minutes}m ${seconds}s`;
  };
  
  // Prepare chart data for daily stats
  const prepareChartData = () => {
    if (dailyStats.length === 0) {
      return {
        labels: [],
        datasets: [
          {
            data: [],
            color: (opacity = 1) => `rgba(44, 110, 73, ${opacity})`,
            strokeWidth: 2,
          },
        ],
      };
    }
    
    const labels = dailyStats.map(stat => format(new Date(stat.date), 'MM/dd'));
    const pageViewsData = dailyStats.map(stat => stat.pageViews);
    const activeUsersData = dailyStats.map(stat => stat.activeUserCount);
    const newUsersData = dailyStats.map(stat => stat.newUserCount);
    
    return {
      labels,
      datasets: [
        {
          data: pageViewsData,
          color: (opacity = 1) => `rgba(44, 110, 73, ${opacity})`,
          strokeWidth: 2,
        },
        {
          data: activeUsersData,
          color: (opacity = 1) => `rgba(65, 105, 225, ${opacity})`,
          strokeWidth: 2,
        },
        {
          data: newUsersData,
          color: (opacity = 1) => `rgba(255, 99, 71, ${opacity})`,
          strokeWidth: 2,
        },
      ],
      legend: ['Page Views', 'Active Users', 'New Users'],
    };
  };
  
  // Prepare bar chart data for popular pages
  const preparePopularPagesData = () => {
    // Ensure popularPages is an array and has items
    if (!Array.isArray(popularPages) || popularPages.length === 0) {
      return {
        labels: [],
        datasets: [
          {
            data: [],
          },
        ],
      };
    }
    
    // Keep only top 5 for chart visualization
    const topPages = popularPages.slice(0, 5);
    const labels = topPages.map(page => {
      const name = (page.pagePath || page.pageName || '').replace(/^\//, '').split('/')[0];
      return name || 'Home';
    });
    const data = topPages.map(page => page.views || page.count || 0);
    
    return {
      labels,
      datasets: [
        {
          data,
        },
      ],
    };
  };
  
  // Prepare pie chart data for popular registry items
  const preparePopularItemsData = () => {
    if (popularItems.length === 0) {
      return [];
    }
    
    // Keep only top 5 for chart visualization
    return popularItems.slice(0, 5).map((item, index) => {
      const colors = [
        '#4c956c',
        '#2c6e49',
        '#ffc857',
        '#e84855',
        '#2d3047'
      ];
      
      return {
        name: item.name.substring(0, 15) + (item.name.length > 15 ? '...' : ''),
        count: item.views || 0,
        color: colors[index % colors.length],
        legendFontColor: '#7F7F7F',
        legendFontSize: 12,
      };
    });
  };
  
  // Add pagination controls component
  const PaginationControls = ({ totalItems, currentPage, setCurrentPage, itemsPerPage }) => {
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    
    return (
      <View style={styles.tablePagination}>
        {Array.from({ length: totalPages }, (_, i) => i + 1).map((pageNum) => (
          <Pressable
            key={pageNum}
            style={[
              styles.paginationButton,
              currentPage === pageNum && styles.paginationButtonActive,
            ]}
            onPress={() => setCurrentPage(pageNum)}
          >
            <Text
              style={[
                styles.paginationText,
                currentPage === pageNum && styles.paginationTextActive,
              ]}
            >
              {pageNum}
            </Text>
          </Pressable>
        ))}
      </View>
    );
  };

  // Calculate current items to display based on pagination
  const getCurrentItems = () => {
    if (!popularItems || popularItems.length === 0) return [];
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    // No longer slice the data to 5 items here
    return popularItems.slice(startIndex, endIndex);
  };
  
  // Effect to fetch data when time period changes
  useEffect(() => {
    fetchAnalyticsData();
  }, [timePeriod]);
  
  // Update the chart rendering functions
  const renderLineChart = () => {
    try {
      return (
        <LineChart
          data={prepareChartData()}
          width={isMobile ? width - 40 : width - 100}
          height={220}
          chartConfig={{
            ...chartConfig,
            useShadowColorFromDataset: true,
            propsForLabels: {
              fontSize: 12,
            }
          }}
          bezier
          style={{
            ...styles.chart,
            borderRadius: 16,
            padding: 10,
          }}
          withInnerLines={false}
          withOuterLines={true}
          fromZero={true}
        />
      );
    } catch (error) {
      console.error('Error rendering LineChart:', error);
      return (
        <View style={styles.chartPlaceholder}>
          <Text style={styles.chartPlaceholderText}>Chart could not be rendered</Text>
        </View>
      );
    }
  };

  const renderBarChart = () => {
    try {
      return (
        <BarChart
          data={preparePopularPagesData()}
          width={isMobile ? width - 40 : (width - 120) / 2}
          height={220}
          chartConfig={{
            ...chartConfig,
            useShadowColorFromDataset: true,
            propsForLabels: {
              fontSize: 12,
            }
          }}
          style={{
            ...styles.chart,
            borderRadius: 16,
            padding: 10,
          }}
          verticalLabelRotation={30}
          showValuesOnTopOfBars={true}
          fromZero={true}
        />
      );
    } catch (error) {
      console.error('Error rendering BarChart:', error);
      return (
        <View style={styles.chartPlaceholder}>
          <Text style={styles.chartPlaceholderText}>Chart could not be rendered</Text>
        </View>
      );
    }
  };

  const renderPieChart = () => {
    try {
      return (
        <PieChart
          data={preparePopularItemsData()}
          width={isMobile ? width - 40 : (width - 120) / 2}
          height={220}
          chartConfig={{
            ...chartConfig,
            useShadowColorFromDataset: true,
            propsForLabels: {
              fontSize: 12,
            }
          }}
          accessor="count"
          backgroundColor="transparent"
          paddingLeft="15"
          absolute
          style={{
            ...styles.chart,
            borderRadius: 16,
            padding: 10,
          }}
        />
      );
    } catch (error) {
      console.error('Error rendering PieChart:', error);
      return (
        <View style={styles.chartPlaceholder}>
          <Text style={styles.chartPlaceholderText}>Chart could not be rendered</Text>
        </View>
      );
    }
  };
  
  const renderPopularItems = (items) => {
    if (!items || items.length === 0) {
      return (
        <View style={styles.emptyStateContainer}>
          <Text style={styles.emptyStateText}>No registry items data available for the selected time period.</Text>
          <Text style={styles.emptyStateSubtext}>Items will appear here once they receive views or purchases.</Text>
        </View>
      );
    }

    return (
      <View style={styles.tableContainer}>
        <View style={styles.tableHeader}>
          <Text style={styles.headerCell}>#</Text>
          <Text style={styles.headerCell}>Item</Text>
          <Text style={styles.headerCell}>Category</Text>
          <Text style={styles.headerCell}>Views</Text>
          <Text style={styles.headerCell}>Purchases</Text>
          <Text style={styles.headerCell}>Price</Text>
        </View>
        {items.map((item, index) => (
          <View key={index} style={styles.tableRow}>
            <Text style={styles.tableCell}>{index + 1}</Text>
            <View style={styles.itemCell}>
              {item.image && (
                <Image source={{ uri: item.image }} style={styles.itemThumbnail} />
              )}
              <Text style={styles.itemName}>{item.name}</Text>
            </View>
            <Text style={styles.tableCell}>{item.category}</Text>
            <Text style={styles.tableCell}>{item.views}</Text>
            <Text style={styles.tableCell}>{item.purchases}</Text>
            <Text style={styles.tableCell}>{item.price}</Text>
          </View>
        ))}
      </View>
    );
  };

  const renderPopularPages = (pages) => {
    if (!pages || pages.length === 0) {
      return (
        <View style={styles.emptyStateContainer}>
          <Text style={styles.emptyStateText}>No page view data available for the selected time period.</Text>
          <Text style={styles.emptyStateSubtext}>Pages will appear here once they receive views.</Text>
        </View>
      );
    }

    return (
      <View style={styles.tableContainer}>
        <View style={styles.tableHeader}>
          <Text style={styles.headerCell}>#</Text>
          <Text style={styles.headerCell}>Page</Text>
          <Text style={styles.headerCell}>Type</Text>
          <Text style={styles.headerCell}>Owner</Text>
          <Text style={styles.headerCell}>Views</Text>
          <Text style={styles.headerCell}>Last Accessed</Text>
        </View>
        {pages.map((page, index) => (
          <View key={index} style={styles.tableRow}>
            <Text style={styles.tableCell}>{index + 1}</Text>
            <Text style={styles.tableCell}>{page.pagePath}</Text>
            <Text style={styles.tableCell}>{page.pageType}</Text>
            <Text style={styles.tableCell}>{page.ownerName}</Text>
            <Text style={styles.tableCell}>{page.views}</Text>
            <Text style={styles.tableCell}>
              {page.lastAccessed ? new Date(page.lastAccessed).toLocaleDateString() : 'N/A'}
            </Text>
          </View>
        ))}
      </View>
    );
  };
  
  return (
    <ScrollView style={styles.container}>
      <View style={styles.header}>
        <Text style={styles.title}>Analytics Dashboard</Text>
        <Text style={styles.subtitle}>{safeGetDateRange(timePeriod, new Date()).label}</Text>
      </View>
      
      <View style={styles.timePeriodSelector}>
        <Pressable
          style={[styles.timePeriodButton, timePeriod === 'day' && styles.activeTimePeriod]}
          onPress={() => setTimePeriod('day')}
        >
          <Text style={[styles.timePeriodText, timePeriod === 'day' && styles.activeTimePeriodText]}>Day</Text>
        </Pressable>
        <Pressable
          style={[styles.timePeriodButton, timePeriod === 'week' && styles.activeTimePeriod]}
          onPress={() => setTimePeriod('week')}
        >
          <Text style={[styles.timePeriodText, timePeriod === 'week' && styles.activeTimePeriodText]}>Week</Text>
        </Pressable>
        <Pressable
          style={[styles.timePeriodButton, timePeriod === 'month' && styles.activeTimePeriod]}
          onPress={() => setTimePeriod('month')}
        >
          <Text style={[styles.timePeriodText, timePeriod === 'month' && styles.activeTimePeriodText]}>Month</Text>
        </Pressable>
        <Pressable
          style={[styles.timePeriodButton, timePeriod === 'quarter' && styles.activeTimePeriod]}
          onPress={() => setTimePeriod('quarter')}
        >
          <Text style={[styles.timePeriodText, timePeriod === 'quarter' && styles.activeTimePeriodText]}>Quarter</Text>
        </Pressable>
        <Pressable
          style={[styles.timePeriodButton, timePeriod === 'year' && styles.activeTimePeriod]}
          onPress={() => setTimePeriod('year')}
        >
          <Text style={[styles.timePeriodText, timePeriod === 'year' && styles.activeTimePeriodText]}>Year</Text>
        </Pressable>
      </View>
      
      {loading ? (
        <View style={styles.loadingContainer}>
          <ActivityIndicator size="large" color="#4c956c" />
          <Text style={styles.loadingText}>Loading analytics data...</Text>
        </View>
      ) : (
        <>
          <View style={styles.metricsContainer}>
            {renderMetric("Total Users", totalUsers, metricDescriptions.totalUsers)}
            {renderMetric("New Users Today", newUsers, metricDescriptions.newUsers)}
            {renderMetric("Active Users Today", activeUsers, metricDescriptions.activeUsers)}
            {renderMetric("Page Views Today", pageViews, metricDescriptions.pageViews)}
          </View>
          
          <View style={styles.statsGrid}>
            <View style={styles.statCard}>
              <Text style={styles.statValue}>{totalUsers}</Text>
              <Text style={styles.statLabel}>Total Users</Text>
            </View>
            <View style={styles.statCard}>
              <Text style={styles.statValue}>{newUsers}</Text>
              <Text style={styles.statLabel}>New Users</Text>
            </View>
            <View style={styles.statCard}>
              <Text style={styles.statValue}>{activeUsers}</Text>
              <Text style={styles.statLabel}>Active Users</Text>
            </View>
            <View style={styles.statCard}>
              <Text style={styles.statValue}>{pageViews}</Text>
              <Text style={styles.statLabel}>Page Views</Text>
            </View>
          </View>
          
          <View style={styles.advancedStatsGrid}>
            <View style={styles.statCard}>
              <Text style={styles.statValue}>{formatTime(safeNumber(avgSessionDuration))}</Text>
              <Text style={styles.statLabel}>Avg. Session Duration</Text>
            </View>
            <View style={styles.statCard}>
              <Text style={styles.statValue}>{safeNumber(bounceRate).toFixed(1)}%</Text>
              <Text style={styles.statLabel}>Bounce Rate</Text>
            </View>
            <View style={styles.statCard}>
              <Text style={styles.statValue}>{safeNumber(conversionRate).toFixed(1)}%</Text>
              <Text style={styles.statLabel}>Conversion Rate</Text>
            </View>
          </View>
          
          <View style={styles.chartContainer}>
            <Text style={styles.chartTitle}>User Activity Over Time</Text>
            {dailyStats.length > 0 ? (
              <View style={styles.chartWrapper}>
                {renderLineChart()}
              </View>
            ) : (
              <View style={styles.noDataContainer}>
                <Text style={styles.noDataText}>No data available for the selected time period</Text>
              </View>
            )}
          </View>
          
          <View style={styles.chartsRow}>
            <View style={[styles.chartContainer, styles.halfChart]}>
              <Text style={styles.chartTitle}>Popular Pages</Text>
              {renderPopularPages(popularPages)}
            </View>
            
            <View style={[styles.chartContainer, styles.halfChart]}>
              <Text style={styles.chartTitle}>Popular Registry Items</Text>
              {renderPopularItems(popularItems)}
            </View>
          </View>
          
          <View style={styles.tableContainer}>
            <Text style={styles.tableTitle}>Top Registry Items (Top {popularItems.length})</Text>
            <View style={styles.tableHeader}>
              <Text style={[styles.tableHeaderCell, { flex: 1 }]}>#</Text>
              <Text style={[styles.tableHeaderCell, { flex: 3 }]}>Item</Text>
              <Text style={[styles.tableHeaderCell, { flex: 2 }]}>Category</Text>
              <Text style={[styles.tableHeaderCell, { flex: 1 }]}>Views</Text>
              <Text style={[styles.tableHeaderCell, { flex: 1 }]}>Purchases</Text>
            </View>
            {getCurrentItems().map((item, index) => (
              <View key={item.id} style={styles.tableRow}>
                <Text style={[styles.tableCell, { flex: 1 }]}>
                  {(currentPage - 1) * itemsPerPage + index + 1}
                </Text>
                <Text style={[styles.tableCell, { flex: 3 }]}>{item.name}</Text>
                <Text style={[styles.tableCell, { flex: 2 }]}>{item.category}</Text>
                <Text style={[styles.tableCell, { flex: 1 }]}>{item.views}</Text>
                <Text style={[styles.tableCell, { flex: 1 }]}>{item.purchaseCount || 0}</Text>
              </View>
            ))}
            <PaginationControls
              totalItems={popularItems.length}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              itemsPerPage={itemsPerPage}
            />
          </View>
          
          <View style={styles.tableContainer}>
            <Text style={styles.tableTitle}>Top Pages (Top {popularPages.length})</Text>
            {renderPopularPages(popularPages)}
          </View>
        </>
      )}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: isMobile ? 10 : 20,
    backgroundColor: '#f8fafc',
    maxWidth: '100%',
    overflow: 'hidden',
  },
  header: {
    marginBottom: 20,
    width: '100%',
  },
  title: {
    fontSize: isMobile ? 20 : 24,
    fontWeight: 'bold',
    color: '#1e293b',
    marginBottom: 5,
  },
  subtitle: {
    fontSize: isMobile ? 14 : 16,
    color: '#64748b',
  },
  timePeriodSelector: {
    flexDirection: 'row',
    marginBottom: 20,
    backgroundColor: '#fff',
    borderRadius: 8,
    padding: 4,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.1,
    shadowRadius: 2,
    elevation: 2,
    width: '100%',
    flexWrap: isMobile ? 'wrap' : 'nowrap',
  },
  timePeriodButton: {
    flex: isMobile ? 1 : 0,
    minWidth: isMobile ? '45%' : 'auto',
    paddingVertical: 8,
    paddingHorizontal: 12,
    borderRadius: 6,
    alignItems: 'center',
    justifyContent: 'center',
    margin: isMobile ? 2 : 0,
  },
  activeTimePeriod: {
    backgroundColor: '#4c956c',
  },
  timePeriodText: {
    fontSize: 14,
    color: '#64748b',
    fontWeight: '500',
  },
  activeTimePeriodText: {
    color: '#fff',
  },
  loadingContainer: {
    padding: 20,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  loadingText: {
    marginTop: 10,
    fontSize: 16,
    color: '#64748b',
  },
  statsGrid: {
    flexDirection: isMobile ? 'column' : 'row',
    flexWrap: 'wrap',
    gap: 10,
    width: '100%',
    marginBottom: 20,
  },
  advancedStatsGrid: {
    flexDirection: isMobile ? 'column' : 'row',
    flexWrap: 'wrap',
    gap: 10,
    width: '100%',
    marginBottom: 20,
  },
  statCard: {
    backgroundColor: '#fff',
    borderRadius: 8,
    padding: 15,
    flex: isMobile ? 0 : 1,
    minWidth: isMobile ? '100%' : '23%',
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.1,
    shadowRadius: 2,
  },
  statValue: {
    fontSize: 28,
    fontWeight: 'bold',
    color: '#4c956c',
    marginBottom: 5,
  },
  statLabel: {
    fontSize: 14,
    color: '#64748b',
  },
  chartContainer: {
    backgroundColor: '#fff',
    borderRadius: 8,
    padding: 15,
    marginBottom: 20,
    width: '100%',
    overflow: 'hidden',
  },
  chartsRow: {
    flexDirection: isMobile ? 'column' : 'row',
    justifyContent: 'space-between',
    width: '100%',
    gap: 20,
  },
  halfChart: {
    width: isMobile ? '100%' : '48%',
  },
  chartTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#1e293b',
    marginBottom: 15,
  },
  chart: {
    marginVertical: 8,
    alignSelf: 'center',
  },
  noDataText: {
    textAlign: 'center',
    color: '#94a3b8',
    padding: 20,
  },
  tableContainer: {
    backgroundColor: '#fefde2',
    borderRadius: 10,
    padding: 15,
    marginVertical: 10,
    width: '100%',
    overflow: 'hidden',
  },
  tableTitle: {
    fontSize: 18,
    fontWeight: '600',
    marginBottom: 16,
    color: '#1f2937',
  },
  tableHeader: {
    flexDirection: 'row',
    borderBottomWidth: 2,
    borderBottomColor: '#4c956c',
    paddingBottom: 10,
    marginBottom: 10,
    width: '100%',
  },
  headerCell: {
    flex: 1,
    fontSize: isMobile ? 12 : 14,
    fontWeight: 'bold',
    color: '#4c956c',
    paddingRight: 10,
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
    paddingVertical: 10,
    width: '100%',
  },
  tableCell: {
    flex: 1,
    fontSize: isMobile ? 10 : 12,
    paddingRight: 10,
    flexWrap: 'wrap',
  },
  itemCell: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'nowrap',
    overflow: 'hidden',
  },
  itemThumbnail: {
    width: isMobile ? 20 : 30,
    height: isMobile ? 20 : 30,
    marginRight: 5,
    borderRadius: 5,
  },
  itemName: {
    flex: 1,
    fontSize: isMobile ? 10 : 12,
    flexWrap: 'wrap',
  },
  emptyStateContainer: {
    padding: 20,
    alignItems: 'center',
    backgroundColor: '#fefde2',
    borderRadius: 10,
    width: '100%',
  },
  emptyStateText: {
    fontSize: isMobile ? 14 : 16,
    color: '#4c956c',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  emptyStateSubtext: {
    fontSize: isMobile ? 12 : 14,
    color: '#666',
    textAlign: 'center',
    marginTop: 5,
  },
  tablePagination: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: 16,
    gap: 8,
  },
  paginationButton: {
    padding: '8px 12px',
    borderRadius: 6,
    backgroundColor: '#f1f5f9',
    marginHorizontal: 4,
  },
  paginationButtonActive: {
    backgroundColor: '#4c956c',
  },
  paginationText: {
    color: '#4b5563',
    fontSize: 14,
  },
  paginationTextActive: {
    color: 'white',
  },
  chartWrapper: {
    width: '100%',
    overflow: 'hidden',
  },
  chartPlaceholder: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  chartPlaceholderText: {
    fontSize: 16,
    color: '#64748b',
    marginBottom: 10,
  },
  chartPlaceholderCode: {
    fontSize: 14,
    color: '#4c956c',
    fontWeight: 'bold',
  },
  noDataContainer: {
    padding: 20,
    alignItems: 'center',
    backgroundColor: '#fefde2',
    borderRadius: 10,
    width: '100%',
  },
  metricsContainer: {
    flexDirection: isMobile ? 'column' : 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginBottom: 20,
    width: '100%',
  },
  metricContainer: {
    width: isMobile ? '100%' : '23%',
    backgroundColor: '#fefde2',
    borderRadius: 10,
    padding: 15,
    marginBottom: 10,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
  },
  metricTitle: {
    fontSize: isMobile ? 16 : 20,
    fontWeight: 'bold',
    color: '#4c956c',
    marginBottom: 5,
  },
  metricValue: {
    fontSize: isMobile ? 24 : 32,
    fontWeight: 'bold',
    color: '#333',
    marginBottom: 5,
  },
  metricDescription: {
    fontSize: isMobile ? 12 : 14,
    color: '#666',
    fontStyle: 'italic',
  },
});

export default Analytics; 
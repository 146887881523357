import React, { useState, useEffect, Alert, Platform, useCallback } from "react";
import { View, Text, Pressable, StyleSheet, Image, FlatList, TextInput, Dimensions, Picker } from "react-native";
import { getFirestore, collection, getDocs, query, where, doc, getDoc } from "firebase/firestore";
import { app } from "../../firebase";
import { useNavigation, useRoute } from '@react-navigation/native'; // Ensure you import useNavigation and useRoute
import { REACT_APP_AWS_SERVER_URL } from '@env';

const { width, height } = Dimensions.get('window');
const isMobile = width < 768;
const isTablet = width >= 768 && width < 1024;
const isSmallDesktop = width >= 1024 && width <= 1500;
const isLargeDesktop = width > 1500;

const VALID_SEARCH_INDICES = [
"Bedroom", "Bathroom", "Decor", "Health & Personal Care", "Kitchen", "Laundry & Cleaning", "Electronics & Office Supplies", "Storage & Organization"
];

const categoryImages = {
	"Bedroom": require("../../assets/Catalogue/Bedroom.jpg"),
	"Bathroom": require("../../assets/Catalogue/Bathroom.jpg"),
	"Decor": require("../../assets/Catalogue/Decor.jpg"),
	"Health & Personal Care": require("../../assets/Catalogue/Health & Personal Care.jpg"),
	"Kitchen": require("../../assets/Catalogue/Kitchen.jpg"),
	"Laundry & Cleaning": require("../../assets/Catalogue/Laundry & Cleaning.jpg"),
	"Electronics & Office Supplies": require("../../assets/Catalogue/Electronics & Office Supplies.jpg"),
	"Storage & Organization": require("../../assets/Catalogue/Storage & Organization.jpg"),
};

const subCategories = {
	"Bedroom": [
		"Sheets", "Comforter", "Bed In a Bag", "Pillow", "Pillow Protector", "Body Pillow", 
		"Mattress Topper", "Mattress Protector", "Throw Blanket", "Study Pillow", 
		"Space Heaters",
		"Misc"
	],
	"Bathroom": [
		"Towel Set", "Waterproof Speakers",
		"Shower Caddy", "Shower Shoes", "Bathrobe", "Shower Wrap", "Hair Wrap", 
		"Bathroom Rug", "Shower Curtain", "Tension Rod", "Cosmetic Organizer",
		"Misc"
	],
	"Laundry & Cleaning": [
		"Cleaning Bundle", "Laundry Basket", "Hamper", "Laundry Detergent", 
		"Odor Eliminator", "Steamer", "Iron",
		"Ironing Board", "Vacuums", "Laundry Bag", "Broom & Dust Pan", 
		"Garbage Can", "Misc"
	],
	"Electronics & Office Supplies": [
		"Television", "Laptop", "Chargers", "Flash Drive", "Hard Drive", 
		"Extension Cords", "Headphones", "Printer", "Alarm Clock", 
		"Bluetooth Speaker", "Personal Alarm", "Room Security",
		"Firestick", "Alexa/Amazon Speakers",
		"Day Planner", "Backpack", "Pens", "Pencils", "Highlighters", 
		"Notebooks", "Stapler", "Scissors", "Printer Paper", "Sticky Notes", 
		"Dry Erase Markers", "Desk Set", "Computer Mouse",
		"Misc"
	],
	"Decor": ["Throw Rug", "Throw Pillows", "Clip Lamps", "Desk Lamp", "Floor Lamp", "Lounge Seating", "Mirror", "Picture Frames", "String Lights", "Tension Rod", "Vanity Mirror", "Window Curtains", "Wall Safe Adhesive", "Dry Erase/Bulletin Board", "Standing Fan", "Bed Fan/Clip Fan", "Doorstopper", "Lap Desk", "Room Decor", "Faux Plants", "Misc"],
	"Health & Personal Care": ["Hair Dryer", "Styling Tools", "Hair Removal", "First Aid Kit", "Pain Reliever", "Sewing Kit", "Exercise Equipment", "Humidifier", "Diffuser", "Diffuser Oils", "Air Purifier", "Noise Machine", "Tool Set", "Misc"],
	"Kitchen": ["Microwave", "Refrigerator", "Personal Refrigerator", "Coffee Maker", "Rice Cooker", "Cooking Tools", "Smoothie Maker", "Air Fryer", "Dishes", "Silverware", "Water Bottles", "Travel Mug", "Can Opener", "Water Filtering", "Mugs", "Snack Bundles", "Food Storage", "Pots/Pans", "Cooking Utensils", "Misc"],
	"Storage & Organization": ["Bedside Shelf/Caddy", "Closet Organization", "Shoe Storage/Racks", "Hangers", "Trunk/Storage", "Underbed Storage", "Drawers", "Over Door Organization", "Rolling Cart", "Desk Organizer", "Wall Key Organizer", "Moving Bags", "Luggage", "Misc"]
};

const Catalogue = ({ onAddItems, isFromRegistry }) => {
	let LOCAL_SERVER_URL = "http://localhost:3300";
	let AWS_SERVER_URL = REACT_APP_AWS_SERVER_URL;
	
	const isDevelopment = process.env.NODE_ENV === "development"; // Adjust this condition based on your setup
	const SERVER_URL = isDevelopment ? LOCAL_SERVER_URL : AWS_SERVER_URL;
	

	const db = getFirestore(app);
	const [catalogueItems, setCatalogueItems] = useState([]);
	const [selectedItems, setSelectedItems] = useState([]);
	const [viewCategories, setViewCategories] = useState(true);
	const [selectedCategory, setSelectedCategory] = useState(null);
	const [searchQuery, setSearchQuery] = useState("");
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 12;
	const [totalItemsCount, setTotalItemsCount] = useState(0);
	const totalPages = Math.ceil(totalItemsCount / itemsPerPage);
	const [selectedSubCategory, setSelectedSubCategory] = useState(null);
	const [visibilitySettings, setVisibilitySettings] = useState({
		hidePrice: false,
		hideImages: false,
		hideAll: false
	});
	const [amazonSearchQuery, setAmazonSearchQuery] = useState("");
	const [amazonSearchResults, setAmazonSearchResults] = useState([]);
	const [isSearchingAmazon, setIsSearchingAmazon] = useState(false);
	const [categories] = useState(VALID_SEARCH_INDICES);
	const [mainSearchQuery, setMainSearchQuery] = useState("");
	const [showSearchResults, setShowSearchResults] = useState(false);

	const navigation = useNavigation();
	const route = useRoute();
	const { isFromLandingPage, isFromHub } = route.params || {}; // Get the parameter from the route

	const handleCategoryChange = useCallback((value) => {
		setSelectedCategory(value);
		setCurrentPage(1);
	}, []);

	const handleBackToLanding = () => {
		navigation.navigate('My Tassel - Welcome'); // Adjust 'LandingPage' to your actual landing page route name
	};

	const handleBackToHub = () => {
		navigation.navigate('Hub'); // Adjust 'Hub' to your actual hub page route name
	};

	useEffect(() => {
		if (selectedCategory) {
			const fetchCatalogueItems = async () => {
				const q = query(collection(db, "catalogueItems"), where("category", "==", selectedCategory));
				const querySnapshot = await getDocs(q);
				const items = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
					.filter((item) => 
						(!searchQuery || item.name.toLowerCase().includes(searchQuery.toLowerCase())) &&
						(!selectedSubCategory || item.subCategory === selectedSubCategory)
					);

				setCatalogueItems(items);
				setTotalItemsCount(items.length);
			};
			fetchCatalogueItems();
		}
	}, [selectedCategory, searchQuery, selectedSubCategory, db]);

	useEffect(() => {
		const fetchVisibilitySettings = async () => {
			try {
				const docRef = doc(db, "maintenance", "catalogueVisibility");
				const docSnap = await getDoc(docRef);

				if (docSnap.exists()) {
					setVisibilitySettings(docSnap.data());
				}
			} catch (error) {
				console.error("Error fetching visibility settings:", error);
			}
		};

		fetchVisibilitySettings();
	}, []);

	const handleSelectCategory = useCallback((category) => {
		setSelectedCategory(category);
		setViewCategories(false);
		setCurrentPage(1);
	}, []);

	const handleBackToCategories = () => {
		setViewCategories(true);
		// Optionally reset selected category here if needed
	};

	const handleSelectItem = (item) => {
		const isItemSelected = selectedItems.some((selectedItem) => selectedItem.id === item.id);
		if (isItemSelected) {
			setSelectedItems(selectedItems.filter((selectedItem) => selectedItem.id !== item.id));
		} else {
			setSelectedItems([...selectedItems, item]);
		}
		// Trigger a visual feedback update, e.g., by changing the state that affects the item's style
	};

	const truncateDescription = (description, wordLimit = 15) => {
		// First, trim to ensure there's no leading/trailing whitespace
		let cleanedDescription = description.trim();

		// Define the prefix to look for, in a case-insensitive manner
		const aboutThisItemPrefix = "About this item";

		// Use a regex to replace "About this item" and any number of following spaces with an empty string
		cleanedDescription = cleanedDescription.replace(new RegExp(`^${aboutThisItemPrefix}\\s*`, 'i'), '');

		// Split the remaining description into words, considering any amount of whitespace as a single splitter
		const words = cleanedDescription.split(/\s+/);

		// Truncate the description to the word limit, if necessary
		if (words.length > wordLimit) {
			return words.slice(0, wordLimit).join(" ") + "...";
		}

		// console.log(cleanedDescription)

		return cleanedDescription;
	};

	const truncateTitle = (title, wordLimit = 6) => {
		const words = title.split(" ");
		if (words.length > wordLimit) {
			return words.slice(0, wordLimit).join(" ") + "...";
		}
		return title;
	};

	const paginatedItems = useCallback(() => {
		const startIndex = (currentPage - 1) * itemsPerPage;
		
		const combinedItems = [
			...amazonSearchResults.map(item => ({
				id: item.asin,
				name: item.title,
				price: item.price,
				image: item.imageUrl,
				url: item.affiliateUrl,
				description: item.description,
				category: selectedCategory,
				subCategory: selectedSubCategory,
				isAmazonItem: true,
				relevanceScore: calculateRelevanceScore(item, searchQuery)
			})),
			...catalogueItems.map(item => ({
				...item,
				relevanceScore: calculateRelevanceScore(item, searchQuery)
			}))
		];

		const sortedItems = combinedItems.sort((a, b) => b.relevanceScore - a.relevanceScore);
		return sortedItems.slice(startIndex, startIndex + itemsPerPage);
	}, [amazonSearchResults, catalogueItems, currentPage, itemsPerPage, searchQuery, selectedCategory, selectedSubCategory]);

	const calculateRelevanceScore = (item, query) => {
		if (!query) return 0;
		
		const searchTerms = query.toLowerCase().split(' ');
		let score = 0;
		
		// Check title/name matches
		const titleWords = (item.name || item.title || '').toLowerCase();
		searchTerms.forEach(term => {
			if (titleWords.includes(term)) {
				score += 10; // Higher weight for title matches
			}
		});
		
		// Check description matches
		const description = (item.description || '').toLowerCase();
		searchTerms.forEach(term => {
			if (description.includes(term)) {
				score += 5; // Lower weight for description matches
			}
		});
		
		// Boost score for exact category/subcategory matches
		if (item.category?.toLowerCase().includes(query.toLowerCase())) {
			score += 8;
		}
		if (item.subCategory?.toLowerCase().includes(query.toLowerCase())) {
			score += 8;
		}
		
		return score;
	};

	const handleNextPage = () => {
		if (currentPage < totalPages) {
			setCurrentPage((prevCurrentPage) => prevCurrentPage + 1);
		}
	};

	const handlePreviousPage = () => {
		if (currentPage > 1) {
			setCurrentPage((prevCurrentPage) => prevCurrentPage - 1);
		}
	};

	const handleAddItems = async () => {
		if (selectedItems.length > 0) {
			await onAddItems(selectedItems);
			setSelectedItems([]); // Clear selected items after adding
		} else {
			Alert.alert("No items selected", "Please select items to add.");
		}
	};

	const handleSelectSubCategory = (subCategory) => {
		setSelectedSubCategory(subCategory);
		setCurrentPage(1); // Reset to first page when subcategory changes
	};

	const handleSearch = useCallback(async (query) => {
		if (!query.trim() || query.length < 3) return;
		
		setIsSearchingAmazon(true);
		try {
			const response = await fetch(`${SERVER_URL}/amazon-search`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ 
					query,
					category: selectedCategory || 'All'
				})
			});

			if (response.status === 429) {
				const retryAfter = response.headers.get('retry-after') || 1;
				alert(`Too many requests. Please wait ${retryAfter} second${retryAfter > 1 ? 's' : ''} before trying again.`);
				return;
			}

			if (!response.ok) {
				const errorData = await response.json();
				throw new Error(errorData.message || 'Search failed');
			}
			
			const data = await response.json();
			if (data.items && Array.isArray(data.items)) {
				setAmazonSearchResults(data.items);
				setCurrentPage(1);
			} else {
				throw new Error('No results found');
			}
		} catch (error) {
			console.error('Amazon search error:', error);
			alert(error.message || 'Failed to search Amazon products. Please try again.');
		} finally {
			setIsSearchingAmazon(false);
		}
	}, [selectedCategory, SERVER_URL]);

	const handleMainSearch = useCallback(async (query) => {
		if (!query.trim() || query.length < 3) return;
		
		setIsSearchingAmazon(true);
		try {
			const response = await fetch(`${SERVER_URL}/amazon-search`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ 
					query,
					category: 'All'
				})
			});

			if (response.status === 429) {
				const retryAfter = response.headers.get('retry-after') || 1;
				alert(`Too many requests. Please wait ${retryAfter} second${retryAfter > 1 ? 's' : ''} before trying again.`);
				return;
			}

			if (!response.ok) {
				const errorData = await response.json();
				throw new Error(errorData.message || 'Search failed');
			}
			
			const data = await response.json();
			if (data.items && Array.isArray(data.items)) {
				setAmazonSearchResults(data.items);
				setShowSearchResults(true);
				setViewCategories(false);
				setCurrentPage(1);
			} else {
				throw new Error('No results found');
			}
		} catch (error) {
			console.error('Amazon search error:', error);
			alert(error.message || 'Failed to search Amazon products. Please try again.');
		} finally {
			setIsSearchingAmazon(false);
		}
	}, [SERVER_URL]);

	const renderSubCategories = (category) => {
		return (
			<FlatList
				data={subCategories[category]}
				renderItem={({ item: subCategory }) => (
					<Pressable
						style={({ hovered }) => [
							styles.subCategoryContainer,
							hovered && styles.subCategoryContainerHover,
							selectedSubCategory === subCategory && styles.subCategoryContainerSelected
						]}
						onPress={() => handleSelectSubCategory(subCategory)}
					>
						<Text style={({ hovered }) => [
							styles.subCategoryText,
							hovered && styles.subCategoryTextHover
						]}>
							{subCategory}
						</Text>
					</Pressable>
				)}
				keyExtractor={(item) => item}
				horizontal={true}
				contentContainerStyle={{ ...styles.subCategoryListContainer }}
			/>
		);
	};

	const BackButton = ({ onPress, text }) => (
		<Pressable
			style={styles.backButton}
			onPress={onPress}
		>
			<Text style={styles.backButtonText}>{text}</Text>
		</Pressable>
	);

	const renderItem = ({ item }) => (
		<View style={styles.categoryContainer}>
			<Pressable
				onPress={() => handleSelectCategory(item)}
				style={({ hovered }) => [
					styles.categoryButton,
					hovered && styles.categoryContainerHover
				]}
			>
				<Image 
					source={categoryImages[item]} 
					style={styles.categoryImage} 
					resizeMode="cover"
				/>
				<View style={styles.textContainer}>
					<Text style={styles.categoryTitle}>{item}</Text>
				</View>
			</Pressable>
		</View>
	);

	const renderPaginationControls = () => {
		const totalPages = Math.ceil(totalItemsCount / itemsPerPage);
		const maxPagesToShow = 5;
		let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
		let endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

		// Adjust start page if we're near the end
		if (endPage - startPage + 1 < maxPagesToShow) {
			startPage = Math.max(1, endPage - maxPagesToShow + 1);
		}

		const pageNumbers = [];
		for (let i = startPage; i <= endPage; i++) {
			pageNumbers.push(i);
		}

		return (
			<View style={styles.paginationContainer}>
				<Pressable 
					onPress={() => setCurrentPage(1)} 
					disabled={currentPage === 1}
					style={[styles.paginationButton, currentPage === 1 && styles.paginationButtonDisabled]}
				>
					<Text style={styles.paginationText}>First</Text>
				</Pressable>
				
				<Pressable 
					onPress={handlePreviousPage} 
					disabled={currentPage <= 1}
					style={[styles.paginationButton, currentPage <= 1 && styles.paginationButtonDisabled]}
				>
					<Text style={styles.paginationText}>Previous</Text>
				</Pressable>

				{pageNumbers.map((pageNum) => (
					<Pressable
						key={pageNum}
						onPress={() => setCurrentPage(pageNum)}
						style={[
							styles.paginationButton,
							currentPage === pageNum && styles.paginationButtonActive
						]}
					>
						<Text style={[
							styles.paginationText,
							currentPage === pageNum && styles.paginationTextActive
						]}>
							{pageNum}
						</Text>
					</Pressable>
				))}

				<Pressable 
					onPress={handleNextPage} 
					disabled={currentPage >= totalPages}
					style={[styles.paginationButton, currentPage >= totalPages && styles.paginationButtonDisabled]}
				>
					<Text style={styles.paginationText}>Next</Text>
				</Pressable>
				
				<Pressable 
					onPress={() => setCurrentPage(totalPages)} 
					disabled={currentPage === totalPages}
					style={[styles.paginationButton, currentPage === totalPages && styles.paginationButtonDisabled]}
				>
					<Text style={styles.paginationText}>Last</Text>
				</Pressable>
			</View>
		);
	};

	return (
		<View style={styles.container}>
			{/* Only show background image when not from registry */}
			{!isFromRegistry && (
				<>
					<Image 
						source={require('../../assets/Landing Background.png')}
						style={styles.backgroundImage}
						resizeMode="cover"
					/>
					<View style={styles.blurOverlay} />
				</>
			)}
			{isFromLandingPage && (
				<BackButton 
					onPress={handleBackToLanding} 
					text="Back to Landing" 
				/>
			)}
			{isFromHub && (
				<BackButton 
					onPress={handleBackToHub} 
					text="Back to Hub" 
				/>
			)}
			{viewCategories ? (
				<>
					<View style={styles.searchContainer}>
						<View style={styles.searchInputContainer}>
							<TextInput
								style={styles.searchInput}
								placeholder="Search Amazon products..."
								value={mainSearchQuery}
								onChangeText={setMainSearchQuery}
							/>
						</View>
						<Pressable 
							style={[
								styles.searchButton,
								(!mainSearchQuery.trim() || mainSearchQuery.length < 3) && styles.searchButtonDisabled
							]}
							onPress={() => handleMainSearch(mainSearchQuery)}
							disabled={!mainSearchQuery.trim() || mainSearchQuery.length < 3 || isSearchingAmazon}
						>
							<Text style={styles.searchButtonText}>
								{isSearchingAmazon ? 'Searching...' : 'Search Amazon'}
							</Text>
						</Pressable>
					</View>

					<FlatList
						data={categories}
						renderItem={renderItem}
						keyExtractor={(item) => item}
						numColumns={isMobile ? 2 : 4}
						columnWrapperStyle={styles.columnWrapper}
						contentContainerStyle={styles.innerContainer}
						showsVerticalScrollIndicator={false}
						initialNumToRender={8}
						maxToRenderPerBatch={8}
						windowSize={5}
					/>
				</>
			) : (
				<View style={styles.itemsContainer}>
					<View style={styles.searchContainer}>
						<View style={styles.searchInputContainer}>
							<TextInput
								style={styles.searchInput}
								placeholder="Search Amazon products..."
								value={searchQuery}
								onChangeText={setSearchQuery}
							/>
							{/* <Picker
								selectedValue={selectedCategory}
								onValueChange={handleCategoryChange}
								style={styles.categoryPicker}
							>
								{categories.map(category => (
									<Picker.Item key={category} label={category} value={category} />
								))}
							</Picker> */}
						</View>
						<Pressable 
							style={[
								styles.searchButton,
								(!searchQuery.trim() || searchQuery.length < 3) && styles.searchButtonDisabled
							]}
							onPress={() => handleSearch(searchQuery)}
							disabled={!searchQuery.trim() || searchQuery.length < 3 || isSearchingAmazon}
						>
							<Text style={styles.searchButtonText}>
								{isSearchingAmazon ? 'Searching...' : 'Search Amazon'}
							</Text>
						</Pressable>
					</View>

					{selectedCategory && renderSubCategories(selectedCategory)}

					<View style={styles.paginationContainer}>
						{renderPaginationControls()}
					</View>

					<FlatList
						data={paginatedItems()}
						renderItem={({ item }) => (
							<Pressable
								onPress={() => handleSelectItem(item)}
								style={({ hovered }) => [
									styles.itemCard,
									selectedItems.find((selectedItem) => selectedItem.id === item.id) ? styles.itemSelected : {},
									hovered && styles.itemHover
								]}
							>
								{!visibilitySettings.hideAll && !visibilitySettings.hideImages && item.image && (
									<Image source={{ uri: item.image }} style={styles.itemImage} />
								)}
								{!visibilitySettings.hideAll && (
									<>
										<Text style={styles.itemName}>{truncateTitle(item.name)}</Text>
										{!visibilitySettings.hidePrice && (
											<Text style={styles.itemPrice}>{`${item.price}`}</Text>
										)}
										<Text style={styles.itemDescription}>{truncateDescription(item.description)}</Text>
										<Text style={styles.itemSubCategory}>{item.subCategory}</Text>
										{item.isAmazonItem && (
											<Text style={styles.amazonLabel}>Amazon Product</Text>
										)}
									</>
								)}
								<Pressable
									style={styles.seeProductButton}
									onPress={(e) => {
										e.stopPropagation(); // Prevent parent onPress
											window.open(item.url, '_blank');
									}}
								>
									<Text style={styles.seeProductButtonText}>View Product</Text>
								</Pressable>
							</Pressable>
						)}
						keyExtractor={(item) => item.id.toString()}
						numColumns={isMobile ? 3 : 3}
					/>

					<View style={styles.paginationContainer}>
						{renderPaginationControls()}
					</View>

					<Pressable style={styles.backToCategoriesButton} onPress={() => {
						setViewCategories(true);
						setShowSearchResults(false);
						setMainSearchQuery("");
						setSelectedCategory(null);
					}}>
						<Text style={styles.backButtonText}>Back to Categories</Text>
					</Pressable>

					<Pressable style={styles.addButton} onPress={handleAddItems}>
						<Text style={styles.addButtonText}>Add Selected Items</Text>
					</Pressable>
				</View>
			)}
			<View style={styles.disclaimerContainer}>
				<Text style={styles.disclaimer}>Disclaimer: As an Amazon Associate MyTassel earns commissions from qualifying purchases on our site.</Text>
			</View>
		</View>
	);
};

const fontFamilies = {
	Bodoni72Smallcaps: "Bodoni72Smallcaps",
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		width: "100%",
		height: "100%",
		top: 0,
		position: 'relative',
		backgroundColor: 'transparent',
	},
	backgroundImage: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		width: '100%',
		height: '100%',
	},
	blurOverlay: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		backgroundColor: 'rgba(255, 255, 255, 0.8)',
		backdropFilter: 'blur(10px)',
	},
	innerContainer: {
		flexDirection: "row",
		flexWrap: "wrap",
		justifyContent: "center",
		alignItems: "center",
		paddingHorizontal: isMobile ? 10 : 40,
		paddingVertical: isMobile ? 20 : 40,
		width: "100%",
		gap: isMobile ? 10 : 20,
	},
	categoryContainer: {
		backgroundColor: "#ffffff",
		borderRadius: 15,
		elevation: 3,
		shadowColor: "#000",
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.2,
		shadowRadius: 4,
		width: isMobile ? "45%" : "22%",
		aspectRatio: 4/3, // More rectangular aspect ratio
		overflow: "hidden",
		position: "relative",
		margin: 0, // Remove margin since we're using gap in container
		minWidth: isMobile ? 120 : isSmallDesktop ? 280 : 350, // Ensure minimum width
		maxWidth: isMobile ? 140 : isSmallDesktop ? 250 : 350, // Ensure maximum width
		height: isMobile ? 200 : isSmallDesktop ? 400 : 500,
	},
	categoryButton: {
		width: '100%',
		height: '100%',
		alignItems: 'center',
		justifyContent: 'flex-end',
		position: 'relative',
	},
	categoryImage: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		width: '100%',
		height: '100%',
	},
	textContainer: {
		backgroundColor: "rgba(255, 255, 255, 0.95)",
		width: "100%",
		paddingVertical: isMobile ? 12 : 15,
		alignItems: "center",
		borderTopWidth: 1,
		borderTopColor: "rgba(0,0,0,0.1)",
	},
	categoryTitle: {
		fontSize: isMobile ? 16 : 22,
		fontWeight: "600",
		color: "#333",
		textAlign: "center",
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		letterSpacing: 0.5,
	},
	itemsContainer: {
		marginTop: isMobile ? 25 : 50,
		flex: 1,
		flexDirection: "column",
		flexWrap: "wrap",
		justifyContent: "center", // Center items horizontally
		alignItems: "center", // Align items vertically
		paddingHorizontal: 0, // Space for widget menu
		// paddingVertical: 75, // Space for widget menu
		top: 0,
		position: "relative", // Ensure this is set
		width: "100%",
	},
	itemCard: {
		backgroundColor: "#fefde2",
		borderRadius: 10, // Rounded corners
		padding: isMobile ? 5 : 15, // Inner spacing
		marginVertical: isMobile ? 5 : 20, // Increased vertical margin for more space
		marginHorizontal: isMobile ? 5 : 20, // Horizontal spacing from screen edges
		shadowColor: "#171717", // Shadow color
		shadowOffset: { width: 0, height: 4 }, // Shadow offset
		shadowOpacity: 0.2, // Shadow opacity
		shadowRadius: 3, // Shadow blur radius
		elevation: 5, // Elevation for Android
		alignItems: "center", // Center items horizontally in the card
		justifyContent: 'space-between', // Ensures space distribution
		maxWidth: "30%",
		minWidth: "30%",

		minHeight: isMobile ? 300 : 600, // Use minHeight for flexibility
		transition: 'transform 0.3s ease',
	},
	itemSelected: {
		borderColor: "green",
		borderWidth: 3,
	},
	itemImage: {
		width: "100%",
		height: "50%", // Fixed height for item images
		resizeMode: "cover",
		borderRadius: 10, // Round corners of images
		paddingBottom: 40,
	},
	itemName: {
		fontFamily: "Orpheus Pro", // Font family
		fontSize: isMobile ? 14 : 24, // Font size
		fontWeight: "bold", // Font weight
		color: "#333", // Text color
		textAlign: "center", // Center text
		marginBottom: 5, // Spacing between name and description
	},

	itemDescription: {
		fontFamily: "Orpheus Pro", // Font family
		fontSize: isMobile ? 12 : 18, // Font size
		color: "#333", // Text color
		textAlign: "center", // Center text
		marginBottom: 10, // Spacing between description and price
	},

	itemPrice: {
		fontFamily: "Orpheus Pro", // Font family
		fontSize: isMobile ? 12 : 18, // Font size
		fontWeight: "bold", // Font weight
		color: "#4c956c", // Price text color, matching the theme
		textAlign: "center", // Center text
	},
	backButton: {
		position: 'absolute',
		top: isMobile ? 5 : isSmallDesktop ? 10 : 20,
		left: isMobile ? 5 : isSmallDesktop ? 10 : 20,
		backgroundColor: '#4c956c',
		padding: 10,
		borderRadius: 5,
		zIndex: 1000,
	},
	backToCategoriesButton: {
		position: "absolute",
		top: isMobile ? 50 : 20,
		left: 20,
		backgroundColor: "#4c956c",
		paddingVertical: 10,
		paddingHorizontal: 20,
		borderRadius: 20,
		zIndex: 10,
	},
	backButtonText: {
		color: '#ffffff',
		fontSize: isMobile ? 14 : 16,
		fontFamily: fontFamilies.Bodoni72Smallcaps,
	},
	addButton: {
		position: "absolute",
		top: isMobile ? 0 : 20, 
		right: 20,
		backgroundColor: "#4c956c",
		paddingVertical: 10,
		paddingHorizontal: 20,
		borderRadius: 20,
		zIndex: 10,
	},
	addButtonText: {
		fontSize: isMobile ? 12 : 16,
		color: "#ffffff",
		fontFamily: fontFamilies.Bodoni72Smallcaps,
	},
	searchContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		width: isMobile ? "80%" : "60%",
		marginVertical: 20,
		alignSelf: 'center',
	},
	searchInputContainer: {
		flex: 1,
		flexDirection: 'row',
		marginRight: 10,
	},
	searchInput: {
		flex: 1,
		fontSize: 18,
		padding: 10,
		borderWidth: 1,
		borderColor: "#ddd",
		borderRadius: 50,
		backgroundColor: "#fefde2",
		fontFamily: "Orpheus Pro",
	},
	searchButton: {
		backgroundColor: "#4c956c",
		paddingVertical: 10,
		paddingHorizontal: 20,
		borderRadius: 50,
		justifyContent: 'center',
		alignItems: 'center',
	},
	searchButtonDisabled: {
		backgroundColor: "#ccc",
	},
	searchButtonText: {
		color: "#ffffff",
		fontSize: isMobile ? 12 : 16,
		fontFamily: fontFamilies.Bodoni72Smallcaps,
	},
	paginationContainer: {
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		paddingVertical: 20,
		gap: 10,
		width: '100%',
	},
	paginationButton: {
		paddingHorizontal: 15,
		paddingVertical: 8,
		backgroundColor: '#4c956c',
		borderRadius: 5,
		minWidth: 40,
		alignItems: 'center',
	},
	paginationButtonDisabled: {
		backgroundColor: '#cccccc',
		opacity: 0.5,
	},
	paginationButtonActive: {
		backgroundColor: '#2c6e3e',
	},
	paginationText: {
		color: '#ffffff',
		fontSize: isMobile ? 12 : 16,
	},
	paginationTextActive: {
		fontWeight: 'bold',
	},
	seeProductButton: {
		marginTop: 10,
		paddingVertical: 8,
		paddingHorizontal: 16,
		backgroundColor: '#4c956c', // Example green color
		borderRadius: 5,
		alignSelf: 'center',
	},
	seeProductButtonText: {
		color: '#ffffff',
		fontSize: isMobile ? 12 : 16,
		textAlign: 'center',
	},
	disclaimer: {
		flex: 1,
		fontFamily: "Orpheus Pro",
		fontSize: 18,
		textAlign: "center",
		justifyContent: "center",
		color: "#4C956C", // Or any other color that fits your theme
		marginBottom: 15,
		fontStyle: "italic",
		top: 25,
	},
	disclaimerContainer: {
		justifyContent: "center",
		alignItems: "center",
		marginTop: 20,
		marginBottom: 20,
	},
	itemHover: {
		transform: [{ scale: 1.025 }],
	},
	subCategoryContainer: {
		backgroundColor: "#fefde2",
		borderRadius: 15,
		margin: 5,
		overflow: "hidden",
		elevation: 3,
		shadowColor: "#000",
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.1,
		shadowRadius: 2,
		width: isMobile ? "auto" : "auto", // Adjust width for smaller subcategory items
		height: isMobile ? 50 : 50,
		padding: 5,
		alignItems: "center",
		justifyContent: "center",
		transition: 'transform 0.3s ease',
	},
	subCategoryContainerHover: {
		transform: 'scale(1.025)',
		backgroundColor: "#4C956C",
	},
	subCategoryContainerSelected: {
		backgroundColor: "#4C956C", // Keep green when selected
	},
	subCategoryText: {
		fontSize: isMobile ? 10 : 14,
		fontWeight: "bold",
		textAlign: "center",
		fontFamily: "Orpheus Pro",
		paddingHorizontal: 10
	},
	subCategoryTextHover: {
		fontSize: isMobile ? 10 : 14,
		fontWeight: "bold",
		textAlign: "center",
		fontFamily: "Orpheus Pro",
		color: "white",
		paddingHorizontal: 10
	},
	subCategoryListContainer: {
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		paddingVertical: 10,
		flexWrap: "wrap",
		gap: 5,
		width: "100%",
		paddingHorizontal: isMobile ? "0%" : "20%",
		maxHeight: "25%",
	},
	amazonLabel: {
		fontSize: isMobile ? 12 : 14,
		color: '#4c956c',
		fontWeight: 'bold',
		marginTop: 5,
		fontFamily: "Orpheus Pro",
	},
	categoryPicker: {
		width: 150,
		marginLeft: 10,
	},
	columnWrapper: {
		justifyContent: 'center',
		gap: isMobile ? 10 : 20,
	},
});

export default Catalogue;
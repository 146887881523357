import React, { useState, useRef, useEffect } from "react";
import { View, Text, ScrollView, ImageBackground, Pressable, TouchableOpacity, Animated } from "react-native";
import { useNavigation } from "@react-navigation/native";
import styles from "../Styles/LandingStyles";
import Popup from "../Widgets/Popup";
import { useUser } from "../Widgets/UserContext";
import ComingSoon from "./ComingSoon";
import { StyleSheet, Dimensions } from "react-native";

const { width, height } = Dimensions.get('window');
const isMobile = width < 768;
const isTablet = width >= 768 && width < 1024;
const isSmallDesktop = width >= 1024 && width <= 1440;
const isLargeDesktop = width > 1440;

const Landing = () => {
	const [isBlurred, setIsBlurred] = React.useState(false);
	const [popupType, setPopupType] = React.useState(null);

	// Set to true to show Coming Soon Screen
	const [isComingSoon, setIsComingSoon] = React.useState(false);
	const { currentUser, currentUserData, setCurrentUser, setCurrentUserData } = useUser();
	// console.warn(currentUserData);

	const navigation = useNavigation();

	// State for resources overlay on mobile
	const [showResourcesOverlay, setShowResourcesOverlay] = useState(false);

	// Animation value for overlay
	const overlayAnim = useRef(new Animated.Value(0)).current;

	// Animate overlay in when it becomes visible
	useEffect(() => {
		if (showResourcesOverlay) {
			Animated.timing(overlayAnim, {
				toValue: 1,
				duration: 300,
				useNativeDriver: true,
			}).start();
		}
	}, [showResourcesOverlay]);

	// Toggle resources overlay for mobile
	const toggleResourcesOverlay = () => {
		if (isMobile) {
			if (!showResourcesOverlay) {
				setShowResourcesOverlay(true);
				// Animation will be handled in the useEffect
			} else {
				// Animate out before hiding
				Animated.timing(overlayAnim, {
					toValue: 0,
					duration: 300,
					useNativeDriver: true,
				}).start(() => {
					setShowResourcesOverlay(false);
					// Reset animation value for next time
					overlayAnim.setValue(0);
				});
			}
		}
	};

	const fadeAnim = {
		header: useRef(new Animated.Value(0)).current,
		content: useRef(new Animated.Value(0)).current,
		resources: useRef(new Animated.Value(0)).current,
		mobileButton: useRef(new Animated.Value(0)).current
	};

	const slideAnim = {
		header: useRef(new Animated.Value(-8)).current,
		content: useRef(new Animated.Value(-50)).current,
		resources: useRef(new Animated.Value(30)).current,
		mobileButton: useRef(new Animated.Value(-50)).current
	};

	useEffect(() => {
		const animations = [
			// Content area animations (first)
			Animated.parallel([
				Animated.timing(fadeAnim.content, {
					toValue: 1,
					duration: 800,
					useNativeDriver: true,
				}),
				Animated.timing(slideAnim.content, {
					toValue: 0,
					duration: 800,
					useNativeDriver: true,
				}),
			]),
			// Header animations (second)
			Animated.parallel([
				Animated.timing(fadeAnim.header, {
					toValue: 1,
					duration: 800,
					useNativeDriver: true,
				}),
				Animated.timing(slideAnim.header, {
					toValue: 0,
					duration: 800,
					useNativeDriver: true,
				}),
			]),
			// Mobile button animations (third)
			Animated.parallel([
				Animated.timing(fadeAnim.mobileButton, {
					toValue: 1,
					duration: 800,
					useNativeDriver: true,
				}),
				Animated.timing(slideAnim.mobileButton, {
					toValue: 0,
					duration: 800,
					useNativeDriver: true,
				}),
			]),
			// Resources ribbon animations (much later)
			Animated.parallel([
				Animated.timing(fadeAnim.resources, {
					toValue: 1,
					duration: 800,
					useNativeDriver: true,
				}),
				Animated.timing(slideAnim.resources, {
					toValue: 0,
					duration: 800,
					useNativeDriver: true,
				}),
			]),
		];

		Animated.stagger(600, animations).start();
	}, []);

	React.useEffect(() => {
		if (currentUserData) {
			navigation.navigate("Hub");
		}
	}, [currentUserData, navigation]);

	const navigateToChecklist = (type) => {
		navigation.navigate("Checklist", { type });
	};

	const navigateToCatalogue = () => {
		navigation.navigate("Catalogue", { isFromLandingPage: true });
	};

	// Navigation
	const handleLinkPress = (pageName, popupType = null) => {
		// For mobile devices, directly show popup instead of navigating
		if (isMobile && pageName === "Register") {
			setIsBlurred(true);
			setPopupType('register');
			return;
		}
		
		if (popupType) {
			setIsBlurred(true);
			setPopupType(popupType);
		} else {
			if (isBlurred) {
				setIsBlurred(false); // Close any currently open popup
			} else {
				toggleBlur(pageName.toLowerCase()); // 'Register' will become 'register', 'Login' will become 'login'
			}
		}
	};

	// Blur
	const toggleBlur = (type = null) => {
		setIsBlurred(!isBlurred); // Toggle the blur
		setPopupType(type); // Set the popup type
	};

	const slideAnimDorm = React.useRef(new Animated.Value(-100)).current;
	const slideAnimApartment = React.useRef(new Animated.Value(-200)).current;
	const slideAnimGraduation = React.useRef(new Animated.Value(-300)).current;
	const slideAnimCatalogue = React.useRef(new Animated.Value(-400)).current;
	const slideAnimPrivacy = React.useRef(new Animated.Value(-400)).current;
	const slideAnimTerms = React.useRef(new Animated.Value(-400)).current;
	const slideAnimBlog = React.useRef(new Animated.Value(-400)).current;
	const slideAnimTutorials = React.useRef(new Animated.Value(-400)).current;
	const slideAnimWhyMyTassel = React.useRef(new Animated.Value(-400)).current;
	const slideAnimChecklist = React.useRef(new Animated.Value(-400)).current;
	const slideAnimContactUs = React.useRef(new Animated.Value(-400)).current;
	const [isMouseNearTabs, setIsMouseNearTabs] = useState(false);

	const slideIn = () => {
		setIsMouseNearTabs(true);
		Animated.timing(slideAnimWhyMyTassel, {
			toValue: 300,
			duration: 500,
			useNativeDriver: true,
		}).start();
		Animated.timing(slideAnimTutorials, {
			toValue: 250,
			duration: 500,
			useNativeDriver: true,
		}).start();
		Animated.timing(slideAnimBlog, {
			toValue: 200,
			duration: 500,
			useNativeDriver: true,
		}).start();
		Animated.timing(slideAnimChecklist, {
			toValue: 150,
			duration: 500,
			useNativeDriver: true,
		}).start();
		Animated.timing(slideAnimCatalogue, {
			toValue: 100,
			duration: 500,
			useNativeDriver: true,
		}).start();
		Animated.timing(slideAnimPrivacy, {
			toValue: 50,
			duration: 500,
			useNativeDriver: true,
		}).start();
		Animated.timing(slideAnimTerms, {
			toValue: 0,
			duration: 500,
			useNativeDriver: true,
		}).start();
		Animated.timing(slideAnimContactUs, {
			toValue: -50,
			duration: 500,
			useNativeDriver: true,
		}).start();
	};

	const slideOut = () => {
		setTimeout(() => {
			if (!isMouseNearTabs) {
				Animated.timing(slideAnimWhyMyTassel, {
					toValue: -400,
					duration: 500,
					useNativeDriver: true,
				}).start();
				Animated.timing(slideAnimDorm, {
					toValue: -100,
					duration: 500,
					useNativeDriver: true,
				}).start();
				Animated.timing(slideAnimApartment, {
					toValue: -200,
					duration: 500,
					useNativeDriver: true,
				}).start();
				Animated.timing(slideAnimChecklist, {
					toValue: -250,
					duration: 500,
					useNativeDriver: true,
				}).start();
				Animated.timing(slideAnimGraduation, {
					toValue: -300,
					duration: 500,
					useNativeDriver: true,
				}).start();
				Animated.timing(slideAnimCatalogue, {
					toValue: -400,
					duration: 500,
					useNativeDriver: true,
				}).start();
				Animated.timing(slideAnimPrivacy, {
					toValue: -400,
					duration: 500,
					useNativeDriver: true,
				}).start();
				Animated.timing(slideAnimTerms, {
					toValue: -400,
					duration: 500,
					useNativeDriver: true,
				}).start();
				Animated.timing(slideAnimBlog, {
					toValue: -400,
					duration: 500,
					useNativeDriver: true,
				}).start();
				Animated.timing(slideAnimTutorials, {
					toValue: -400,
					duration: 500,
					useNativeDriver: true,
				}).start();
				Animated.timing(slideAnimContactUs, {
					toValue: -400,
					duration: 500,
					useNativeDriver: true,
				}).start();
			}
		}, 200); // Delay to allow mouse movement
	};

	// Animation values for buttons
	const scaleAnim = React.useRef(new Animated.Value(1)).current;

	const handlePressIn = () => {
		Animated.spring(scaleAnim, {
			toValue: 0.95,
			useNativeDriver: true,
		}).start();
	};

	const handlePressOut = () => {
		Animated.spring(scaleAnim, {
			toValue: 1,
			useNativeDriver: true,
		}).start();
	};

	const [hoveredButton, setHoveredButton] = useState(null); // Initialize state
	const [buttonPressed, setButtonPressed] = useState(false); // Add state for button press

	return (
		<View style={isBlurred ? styles.blurredContainer : null}>
			{isComingSoon && <ComingSoon />}
			{/* Mobile-only Get Started button */}
			{isMobile && (
				<Animated.View
					style={[
						{
							position: 'absolute',
							left: '5%',
							bottom: '16.5%',
							zIndex: 2,
							opacity: isBlurred ? 0 : fadeAnim.mobileButton, // Hide when popup is open
							transform: [{ translateX: slideAnim.mobileButton }],
							pointerEvents: isBlurred ? 'none' : 'auto'
						}
					]}
				>
					<Pressable
						style={[
							styles.getStartedButton,
							{
								paddingHorizontal: 20,
							}
						]}
						onPress={() => handleLinkPress("Register")}
					>
						<Text style={styles.buttonText}>Get Started</Text>
					</Pressable>
				</Animated.View>
			)}
			<ScrollView style={styles.body}>
				<View style={styles.section}>
					{isBlurred && (
						<View style={[
							styles.popupContainer, 
							isMobile && {
								backgroundColor: 'rgba(0,0,0,0.8)',
								position: 'absolute',
								top: 0,
								left: 0,
								right: 0,
								bottom: 0,
								zIndex: 99999,
								flex: 1,
								justifyContent: 'center',
								alignItems: 'center'
							}
						]}>
							<Popup style={styles.popup} type={popupType} onClose={toggleBlur} />
						</View>
					)}

					<ImageBackground 
						source={require("../assets/Landing Background.png")} 
						style={[
							styles.backgroundImage,
							isMobile && {
								pointerEvents: 'box-none', // Allow touches to pass through
							}
						]}
					>
						{isBlurred && (
							<View style={styles.overlayBlur} />
						)}
						<Animated.View 
							style={[
								styles.headerBar,
								{
									opacity: fadeAnim.header,
									transform: [{ translateY: slideAnim.header }]
								}
							]}
						>
							<View style={styles.headerBar}>
								<Pressable style={styles.logoArea} onPress={() => navigation.navigate("My Tassel - Landing")}>
									<Text style={styles.logoText}>MYTASSEL</Text>
								</Pressable>

								<View style={styles.loginArea}>
									<Pressable
										style={[
											styles.registerButton,
											hoveredButton === 'register' && styles.registerButtonHover
										]}
										onMouseEnter={() => setHoveredButton('register')}
										onMouseLeave={() => setHoveredButton(null)}
										onPress={() => handleLinkPress("Register")}
									>
										<Text style={styles.registerButtonText}>Registration</Text>
									</Pressable>
									<Pressable
										style={[
											styles.loginButton,
											hoveredButton === 'login' && styles.loginButtonHover
										]}
										onMouseEnter={() => setHoveredButton('login')}
										onMouseLeave={() => setHoveredButton(null)}
										onPress={() => handleLinkPress("Login")}
									>
										<Text style={styles.loginButtonText}>Login Now</Text>
									</Pressable>
								</View>
							</View>
						</Animated.View>

						<Animated.View 
							style={[
								styles.contentArea,
								{
									opacity: fadeAnim.content,
									transform: [{ translateX: slideAnim.content }]
								}
							]}
						>
							<Text style={styles.headerText}>Graduation Planning.{"\n"}Simplified.</Text>
							<Text style={styles.descriptionText}>Your One-Stop solution to create FREE Digital Graduation Announcements, Registries, and Share Events</Text>

							{/* Original Get Started button - only shown on non-mobile */}
							{!isMobile && (
								<Pressable
									style={[
										styles.getStartedButton,
										hoveredButton === 'getStarted' && styles.getStartedButtonHover
									]}
									onMouseEnter={() => setHoveredButton('getStarted')}
									onMouseLeave={() => setHoveredButton(null)}
									onPress={() => handleLinkPress("Register")}
									onPressIn={handlePressIn}
									onPressOut={handlePressOut}
									activeOpacity={0.8}
								>
									<Text style={styles.buttonText}>Get Started</Text>
								</Pressable>
							)}
						</Animated.View>

						<Animated.View 
							style={[
								styles.ribbonContainer,
								{
									opacity: fadeAnim.resources,
									transform: [{ translateY: slideAnim.resources }]
								}
							]}
							onMouseEnter={slideIn}
							onMouseLeave={slideOut}
							onPressIn={handlePressIn}
							onPressOut={handlePressOut}
						>
							{isMobile ? (
								<Pressable onPress={toggleResourcesOverlay} style={{width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center'}}>
									<Text style={{ ...styles.ribbonText, fontSize: isMobile ? 24 : undefined }}>⋯</Text>
								</Pressable>
							) : (
								<>
									<Text style={{ ...styles.ribbonText, width: 100, textAlign: 'center' }}>Resources</Text>
									<Animated.View style={{ ...styles.ribbon1, zIndex: 1001, bottom: slideAnimWhyMyTassel }}>
										<Pressable onPress={() => navigation.navigate("WhyMyTassel")}>
											<Text style={styles.ribbonText}>Why Choose MyTassel?</Text>
										</Pressable>
									</Animated.View>

									<Animated.View style={{ ...styles.ribbon1, bottom: slideAnimTutorials }}>
										<Pressable onPress={() => navigation.navigate("Tutorials")}>
											<Text style={styles.ribbonText}>Tutorials</Text>
										</Pressable>
									</Animated.View>

									<Animated.View style={{ ...styles.ribbon1, bottom: slideAnimBlog }}>
										<Pressable onPress={() => navigation.navigate("Blog")}>
											<Text style={styles.ribbonText}>Blog</Text>
										</Pressable>
									</Animated.View>

									<Animated.View style={{ ...styles.ribbon1, bottom: slideAnimChecklist }}>
										<Pressable
											onPress={() => navigation.navigate("Checklist", { type: "graduation" })}
										>
											<Text style={styles.ribbonText}>Checklists</Text>
										</Pressable>
									</Animated.View>

									<Animated.View style={{ ...styles.ribbon2, bottom: slideAnimApartment }}>
										<Pressable
											onPress={() => navigateToChecklist("apartment")}
										>
											<Text style={styles.ribbonText}>Apartment Checklist</Text>
										</Pressable>
									</Animated.View>

									<Animated.View style={{ ...styles.ribbon3, bottom: slideAnimGraduation }}>
										<Pressable
											onPress={() => navigateToChecklist("graduation")}
										>
											<Text style={styles.ribbonText}>Graduation Checklist</Text>
										</Pressable>
									</Animated.View>

									<Animated.View
										style={{ ...styles.ribbon4, bottom: slideAnimCatalogue }}
										onMouseEnter={() => {
											slideIn();
										}}
										onMouseLeave={() => {
											setIsMouseNearTabs(false);
											slideOut();
										}}
									>
										<Pressable
											onPress={navigateToCatalogue}
										>
											<Text style={styles.ribbonText}>Catalogue</Text>
										</Pressable>
									</Animated.View>

									<Animated.View style={{ ...styles.ribbon5, bottom: slideAnimPrivacy }}>
										<Pressable
											onPress={() => navigation.navigate("Privacy")}
										>
											<Text style={styles.ribbonText}>Privacy Policy</Text>
										</Pressable>
									</Animated.View>

									<Animated.View style={{ ...styles.ribbon6, zIndex: 1002, bottom: slideAnimTerms }}>
										<Pressable
											onPress={() => navigation.navigate("Terms")}
										>
											<Text style={styles.ribbonText}>Terms & Conditions</Text>
										</Pressable>
									</Animated.View>

									<Animated.View style={{ ...styles.ribbon6, zIndex: 1003, bottom: slideAnimContactUs }}>
										<Pressable
											onPress={() => navigation.navigate("ContactUs")}
										>
											<Text style={styles.ribbonText}>Contact Us</Text>
										</Pressable>
									</Animated.View>
								</>
							)}
						</Animated.View>
					</ImageBackground>
				</View>
			</ScrollView>

			{isMobile && showResourcesOverlay && (
				<Animated.View 
					style={[
						styles.resourcesOverlay,
						{
							transform: [{ 
								translateY: overlayAnim.interpolate({
									inputRange: [0, 1],
									outputRange: [height, 0]
								})
							}]
						}
					]}
				>
					<Pressable 
						style={styles.resourcesOverlayCloseButton}
						onPress={toggleResourcesOverlay}
					>
						<Text style={styles.resourcesOverlayCloseButtonText}>X</Text>
					</Pressable>
					<Animated.View 
						style={styles.resourcesOverlayContent}
					>
						<Text style={styles.resourcesOverlayTitle}>Resources</Text>
						
						<Pressable 
							style={styles.resourcesOverlayItem}
							onPress={() => {
								toggleResourcesOverlay();
								navigation.navigate("WhyMyTassel");
							}}
						>
							<Text style={styles.resourcesOverlayItemText}>Why Choose MyTassel?</Text>
						</Pressable>
						
						<Pressable 
							style={styles.resourcesOverlayItem}
							onPress={() => {
								toggleResourcesOverlay();
								navigation.navigate("Tutorials");
							}}
						>
							<Text style={styles.resourcesOverlayItemText}>Tutorials</Text>
						</Pressable>
						
						<Pressable 
							style={styles.resourcesOverlayItem}
							onPress={() => {
								toggleResourcesOverlay();
								navigation.navigate("Blog");
							}}
						>
							<Text style={styles.resourcesOverlayItemText}>Blog</Text>
						</Pressable>
						
						<Pressable 
							style={styles.resourcesOverlayItem}
							onPress={() => {
								toggleResourcesOverlay();
								navigation.navigate("Checklist", { type: "graduation" });
							}}
						>
							<Text style={styles.resourcesOverlayItemText}>Checklists</Text>
						</Pressable>

						<Pressable 
							style={styles.resourcesOverlayItem}
							onPress={() => {
								toggleResourcesOverlay();
								navigation.navigate("Catalogue");
							}}
						>
							<Text style={styles.resourcesOverlayItemText}>Catalogue</Text>
						</Pressable>
						
						<Pressable 
							style={styles.resourcesOverlayItem}
							onPress={() => {
								toggleResourcesOverlay();
								navigation.navigate("Privacy");
							}}
						>
							<Text style={styles.resourcesOverlayItemText}>Privacy Policy</Text>
						</Pressable>
						
						<Pressable 
							style={styles.resourcesOverlayItem}
							onPress={() => {
								toggleResourcesOverlay();
								navigation.navigate("Terms");
							}}
						>
							<Text style={styles.resourcesOverlayItemText}>Terms & Conditions</Text>
						</Pressable>
						
						<Pressable 
							style={styles.resourcesOverlayItem}
							onPress={() => {
								toggleResourcesOverlay();
								navigation.navigate("ContactUs");
							}}
						>
							<Text style={styles.resourcesOverlayItemText}>Contact Us</Text>
						</Pressable>
					</Animated.View>
				</Animated.View>
			)}
		</View>
	);
};

export default Landing;

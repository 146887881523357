import React from 'react';
import { View, Text, ScrollView, StyleSheet, Dimensions, ImageBackground, Image } from 'react-native';

const { width } = Dimensions.get('window');
const isMobile = width < 768;
const isTablet = width >= 768 && width < 1024;
const isSmallDesktop = width >= 1024 && width <= 1440;

const WhyMyTassel = () => {
 return (
  <ImageBackground source={require('../assets/Landing Background.png')} style={styles.container}>
   <ScrollView style={styles.scrollView}>
    <View style={styles.content}>
     <Text style={styles.title}>Why Choose MyTassel?</Text>

     <View style={styles.reasonsGrid}>
      <View style={styles.reasonCard}>
       <Text style={styles.checkmark}>✅</Text>
       <View style={styles.reasonContent}>
        <Text style={styles.reasonText}>Easy to Use</Text>
        <Text style={styles.description}>Create your graduation registry and announcements in minutes.</Text>
       </View>
      </View>
      
      <View style={styles.reasonCard}>
       <Text style={styles.checkmark}>✅</Text>
       <View style={styles.reasonContent}>
        <Text style={styles.reasonText}>Free to Create</Text>
        <Text style={styles.description}>All features, including the registry, virtual yearbook, and announcements, are completely free to set up.</Text>
       </View>
      </View>
      
      <View style={styles.reasonCard}>
       <Text style={styles.checkmark}>✅</Text>
       <View style={styles.reasonContent}>
        <Text style={styles.reasonText}>Keeps Everyone Connected</Text>
        <Text style={styles.description}>Send digital announcements and keep family and friends informed about your big day.</Text>
       </View>
      </View>
      
      <View style={styles.reasonCard}>
       <Text style={styles.checkmark}>✅</Text>
       <View style={styles.reasonContent}>
        <Text style={styles.reasonText}>Modern and Convenient</Text>
        <Text style={styles.description}>No more paper invitations or outdated processes—everything is digital and accessible.</Text>
       </View>
      </View>
      
      <View style={styles.reasonCard}>
       <Text style={styles.checkmark}>✅</Text>
       <View style={styles.reasonContent}>
        <Text style={styles.reasonText}>Personalized Features</Text>
        <Text style={styles.description}>Set goals, track gifts, and create a unique experience tailored to your graduation.</Text>
       </View>
      </View>
      
      <View style={styles.reasonCard}>
       <Text style={styles.checkmark}>✅</Text>
       <View style={styles.reasonContent}>
        <Text style={styles.reasonText}>Supports All Graduates</Text>
        <Text style={styles.description}>Whether high school, college, or trade school, MyTassel is here for everyone.</Text>
       </View>
      </View>
      
      <View style={styles.reasonCard}>
       <Text style={styles.checkmark}>✅</Text>
       <View style={styles.reasonContent}>
        <Text style={styles.reasonText}>Gift Registry Integration</Text>
        <Text style={styles.description}>Easily include items to help you start your next chapter.</Text>
       </View>
      </View>
      
      <View style={styles.reasonCard}>
       <Text style={styles.checkmark}>✅</Text>
       <View style={styles.reasonContent}>
        <Text style={styles.reasonText}>Environmentally Friendly</Text>
        <Text style={styles.description}>Digital announcements mean less waste and more sustainability.</Text>
       </View>
      </View>
      
      <View style={styles.reasonCard}>
       <Text style={styles.checkmark}>✅</Text>
       <View style={styles.reasonContent}>
        <Text style={styles.reasonText}>Celebrate Your Way</Text>
        <Text style={styles.description}>MyTassel empowers you to make your moment truly your own.</Text>
       </View>
      </View>
     </View>
     
     <View style={styles.reasonCard}>
       <Text style={styles.checkmark}>✅</Text>
       <View style={styles.reasonContent}>
        <Text style={styles.reasonText}>Still Not Convinced and Want to Order Paper Announcements?</Text>
        <Text style={styles.description}>Create your MyTassel page and include your unique URL on your paper announcement to direct friends and family to your digital announcement, registry, and cash goal.</Text>
        
        <View style={styles.reasonImageContainer}>
          <Image 
            source={require('../assets/WhyMyTasselImage.png')} 
            style={styles.whyMyTasselImage} 
            resizeMode="contain"
          />
        </View>
       </View>
     </View>
     
     <Text style={styles.footer}>Start your journey today with MyTassel—Your Moment, Your Way!</Text>
    </View>
    <View style={styles.overlayBlur}/>
   </ScrollView>
  </ImageBackground>
 );
};

const styles = StyleSheet.create({
 container: {
  flex: 1,
  zIndex: 0,
  minHeight: '100vh',
 },
 scrollView: {
  flex: 1,
  position: 'relative',
 },
 overlayBlur: {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backdropFilter: "blur(10px)",
  zIndex: 1,
  height: '100%',
 },
 content: {
  padding: isMobile ? 16 : isTablet ? 32 : 48,
  zIndex: 2,
  maxWidth: 1200,
  alignSelf: 'center',
  width: '100%',
  position: 'relative',
 },
 title: {
  fontSize: isMobile ? 28 : isTablet ? 36 : 44,
  fontWeight: '600',
  marginBottom: 40,
  color: '#4c956c',
  textAlign: 'center',
  zIndex: 2,
  fontFamily: 'Poppins-SemiBold',
  color: '#fefde2',
 },
 reasonsGrid: {
  display: 'grid',
  gridTemplateColumns: isMobile ? '1fr' : isTablet ? '1fr 1fr' : '1fr 1fr 1fr',
  gap: isMobile ? 16 : 24,
  zIndex: 2
 },
 reasonCard: {
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  borderRadius: 12,
  padding: 24,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  gap: 16,
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  transition: 'transform 0.2s ease',
  zIndex: 2,
  marginBottom: isMobile ? 16 : 24,
  backgroundColor: '#fefde2',
 },
 reasonContent: {
  flex: 1,
  gap: 8,
 },
 checkmark: {
  fontSize: 20,
  marginTop: 2,
 },
 reasonText: {
  fontSize: isMobile ? 18 : 20,
  fontWeight: '600',
  color: '#4c956c',
  fontFamily: 'Poppins-SemiBold',
 },
 description: {
  fontSize: isMobile ? 15 : 16,
  color: '#4A5568',
  fontFamily: 'Poppins-Regular',
 },
 footer: {
  fontSize: isMobile ? 18 : 20,
  fontWeight: '600',
  color: '#4c956c',
  textAlign: 'center',
  marginTop: 40,
  marginBottom: 20,
  zIndex: 2,
  fontFamily: 'Poppins-SemiBold',
  color: '#fefde2',
 },
 reasonImageContainer: {
  alignItems: 'center',
  marginTop: 15,
  width: '100%',
  zIndex: 2,
 },
 whyMyTasselImage: {
  width: '100%',
  height: isMobile ? 150 : isTablet ? 200 : 250,
  borderRadius: 8,
  marginTop: 10,
 },
 imageContainer: {
  alignItems: 'center',
  marginTop: 30,
  marginBottom: 20,
  width: '100%',
  zIndex: 2,
 },
});

export default WhyMyTassel; 
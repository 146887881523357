import { getAuth } from 'firebase/auth';
import { app } from '../firebase';
import { getFirestore, collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import axios from 'axios';

// Server URL configuration
const SERVER_URL = process.env.NODE_ENV === 'development' 
  ? 'http://localhost:3300' 
  : process.env.REACT_APP_AWS_SERVER_URL || 'https://api.mytassel.com';

// Firebase Analytics API connection
// Note: This uses the Firebase Admin SDK approach which requires proper setup
// For a production implementation, you would need to setup server-side functions to access this data

const auth = getAuth(app);
const db = getFirestore(app);

// Create an axios instance
const axiosInstance = axios.create({
  baseURL: SERVER_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Add a request interceptor to set the authorization header
axiosInstance.interceptors.request.use(async (config) => {
  try {
    const user = auth.currentUser;
    if (!user) {
      console.error('No authenticated user found');
      throw new Error('No authenticated user');
    }

    const token = await user.getIdToken(true); // Force token refresh
    console.log('Token obtained successfully');
    
    config.headers.Authorization = `Bearer ${token}`;
    console.log('Authorization header set:', config.headers.Authorization ? 'Bearer token present' : 'No bearer token');
    
    return config;
  } catch (error) {
    console.error('Auth interceptor error:', error);
    return Promise.reject(error);
  }
}, (error) => {
  console.error('Request interceptor error:', error);
  return Promise.reject(error);
});

// Add a response interceptor to handle errors
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      console.error('Response error:', {
        status: error.response.status,
        data: error.response.data,
        headers: error.response.headers
      });
      
      if (error.response.status === 401) {
        console.error('Authentication error - please ensure you are logged in');
      } else if (error.response.status === 403) {
        console.error('Authorization error - insufficient permissions');
      }
    } else if (error.request) {
      console.error('Request error - no response received:', error.request);
    } else {
      console.error('Error setting up request:', error.message);
    }
    return Promise.reject(error);
  }
);

// Get the Firebase project ID from the app config
const getProjectId = () => {
  try {
    return app.options.projectId || 'my-tassel';
  } catch (error) {
    console.error('Error getting project ID:', error);
    return 'my-tassel'; // Fallback to default project ID
  }
};

// Mock functions to demonstrate the structure
// In production, these would call your backend API that uses Firebase Admin SDK to fetch analytics
const getFirebaseAnalyticsData = {
  getTotalUserCount: async (startDate, endDate) => {
    try {
      const response = await fetch(`${SERVER_URL}/analytics/metrics`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          startDate,
          endDate,
          metrics: ['totalUsers']
        })
      });

      if (!response.ok) {
        if (response.status === 503) {
          console.warn('Analytics service temporarily unavailable');
          return 0;
        }
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return data.metrics?.totalUsers || 0;
    } catch (error) {
      console.error('Error fetching total users:', error);
      return 0;
    }
  },

  getNewUserCount: async (startDate, endDate) => {
    try {
      const response = await fetch(`${SERVER_URL}/analytics/metrics`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          startDate,
          endDate,
          metrics: ['newUsers']
        })
      });

      if (!response.ok) {
        if (response.status === 503) {
          console.warn('Analytics service temporarily unavailable');
          return 0;
        }
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return data.metrics?.newUsers || 0;
    } catch (error) {
      console.error('Error fetching new users:', error);
      return 0;
    }
  },

  getActiveUserCount: async (startDate, endDate) => {
    try {
      const response = await fetch(`${SERVER_URL}/analytics/metrics`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          startDate,
          endDate,
          metrics: ['activeUsers']
        })
      });
      const data = await response.json();
      return data.metrics?.activeUsers || 0;
    } catch (error) {
      console.error('Error fetching active users:', error);
      return 0;
    }
  },

  getPageViewCount: async (startDate, endDate) => {
    try {
      const response = await fetch(`${SERVER_URL}/analytics/metrics`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          startDate,
          endDate,
          metrics: ['screenPageViews']
        })
      });
      const data = await response.json();
      return data.metrics?.screenPageViews || 0;
    } catch (error) {
      console.error('Error fetching page views:', error);
      return 0;
    }
  },

  getAverageSessionDuration: async (startDate, endDate) => {
    try {
      const response = await fetch(`${SERVER_URL}/analytics/metrics`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          startDate,
          endDate,
          metrics: ['averageSessionDuration']
        })
      });
      const data = await response.json();
      return data.metrics?.averageSessionDuration || 0;
    } catch (error) {
      console.error('Error fetching average session duration:', error);
      return 0;
    }
  },

  getBounceRate: async (startDate, endDate) => {
    try {
      const response = await fetch(`${SERVER_URL}/analytics/metrics`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          startDate,
          endDate,
          metrics: ['bounceRate']
        })
      });
      const data = await response.json();
      return data.metrics?.bounceRate || 0;
    } catch (error) {
      console.error('Error fetching bounce rate:', error);
      return 0;
    }
  },

  getConversionRate: async (startDate, endDate) => {
    try {
      const response = await fetch(`${SERVER_URL}/analytics/metrics`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          startDate,
          endDate,
          metrics: ['conversionRate']
        })
      });
      const data = await response.json();
      return data.metrics?.conversionRate || 0;
    } catch (error) {
      console.error('Error fetching conversion rate:', error);
      return 0;
    }
  },

  getDailyStats: async (startDate, endDate) => {
    try {
      const response = await fetch(`${SERVER_URL}/analytics/metrics`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          startDate,
          endDate,
          metrics: ['activeUsers', 'newUsers', 'screenPageViews']
        })
      });
      const data = await response.json();
      
      // Create daily stats array from the response
      const dailyStats = [];
      const today = new Date();
      for (let i = 6; i >= 0; i--) {
        const date = new Date(today);
        date.setDate(date.getDate() - i);
        dailyStats.push({
          date: date.toISOString().split('T')[0],
          activeUserCount: data.metrics?.activeUsers || 0,
          newUserCount: data.metrics?.newUsers || 0,
          pageViews: data.metrics?.screenPageViews || 0
        });
      }
      return dailyStats;
    } catch (error) {
      console.error('Error fetching daily stats:', error);
      return [];
    }
  },

  // Get popular pages from GA4 analytics
  getPopularPages: async (startDate, endDate) => {
    try {
      const response = await fetch(`${SERVER_URL}/analytics/popular-pages`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          startDate,
          endDate,
          metrics: ['screenPageViews', 'averageEngagementTime']
        })
      });

      if (!response.ok) {
        if (response.status === 503) {
          console.warn('Analytics service temporarily unavailable');
          return [];
        }
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return data.pages || [];
    } catch (error) {
      console.error('Error getting popular pages:', error);
      return [];
    }
  },
  
  // Get most frequently added registry items with enhanced tracking
  getPopularRegistryItems: async (startDate, endDate) => {
    try {
      const usersRef = collection(db, 'users');
      const usersSnapshot = await getDocs(usersRef);
      const itemFrequency = new Map();
      
      // Iterate through all users
      for (const userDoc of usersSnapshot.docs) {
        const userData = userDoc.data();
        const registryItems = userData.pages?.registryPageConfig?.items || [];
        
        // For each registry item
        for (const item of registryItems) {
          // Create a unique identifier for the item
          const itemKey = `${item.name}-${item.url}`;
          
          if (!itemFrequency.has(itemKey)) {
            itemFrequency.set(itemKey, {
              name: item.name,
              category: item.category || 'Uncategorized',
              subCategory: item.subCategory || 'Uncategorized',
              url: item.url,
              image: item.image,
              price: item.price,
              frequency: 1,
              lastAdded: item.addedAt || new Date(),
              // New tracking data
              totalPurchases: item.purchased ? 1 : 0,
              purchaseHistory: item.purchased ? [{
                date: item.purchaseTime,
                purchaserType: item.purchaserType || 'unknown'
              }] : [],
              averageTimeToPurchase: item.purchased && item.addedAt && item.purchaseTime ? 
                (new Date(item.purchaseTime) - new Date(item.addedAt)) / (1000 * 60 * 60 * 24) : null, // in days
              organizations: new Set([userData.organization?.name || 'Unknown']),
              addedByGrads: item.addedByGrad ? 1 : 0,
              addedByParents: item.addedByParent ? 1 : 0,
              priceRange: {
                min: parseFloat(item.price) || 0,
                max: parseFloat(item.price) || 0
              },
              keywords: new Set(item.keywords || []),
              description: item.description,
              isAmazonItem: item.isAmazonItem || false
            });
          } else {
            const currentItem = itemFrequency.get(itemKey);
            currentItem.frequency += 1;
            
            // Update existing tracking data
            if (item.purchased) {
              currentItem.totalPurchases += 1;
              currentItem.purchaseHistory.push({
                date: item.purchaseTime,
                purchaserType: item.purchaserType || 'unknown'
              });
            }
            
            // Update time to purchase metrics
            if (item.purchased && item.addedAt && item.purchaseTime) {
              const daysToPurchase = (new Date(item.purchaseTime) - new Date(item.addedAt)) / (1000 * 60 * 60 * 24);
              const totalDays = (currentItem.averageTimeToPurchase || 0) * (currentItem.totalPurchases - 1) + daysToPurchase;
              currentItem.averageTimeToPurchase = totalDays / currentItem.totalPurchases;
            }

            // Update organization tracking
            currentItem.organizations.add(userData.organization?.name || 'Unknown');
            
            // Update user type tracking
            if (item.addedByGrad) currentItem.addedByGrads += 1;
            if (item.addedByParent) currentItem.addedByParents += 1;
            
            // Update price range
            const itemPrice = parseFloat(item.price) || 0;
            currentItem.priceRange.min = Math.min(currentItem.priceRange.min, itemPrice);
            currentItem.priceRange.max = Math.max(currentItem.priceRange.max, itemPrice);
            
            // Update keywords
            if (item.keywords) {
              item.keywords.forEach(keyword => currentItem.keywords.add(keyword));
            }
            
            // Update lastAdded if more recent
            if (item.addedAt && (!currentItem.lastAdded || new Date(item.addedAt) > new Date(currentItem.lastAdded))) {
              currentItem.lastAdded = item.addedAt;
            }
          }
        }
      }
      
      // Convert to array and process data
      const popularItems = Array.from(itemFrequency.values())
        .map(item => ({
          ...item,
          organizations: Array.from(item.organizations),
          keywords: Array.from(item.keywords),
          purchaseRate: item.totalPurchases / item.frequency,
          gradToParentRatio: item.addedByParents ? item.addedByGrads / item.addedByParents : item.addedByGrads,
          averagePurchaseTime: item.averageTimeToPurchase ? `${Math.round(item.averageTimeToPurchase)} days` : 'N/A'
        }))
        .filter(item => {
          if (item.lastAdded) {
            const itemDate = new Date(item.lastAdded);
            return itemDate >= new Date(startDate) && itemDate <= new Date(endDate);
          }
          return true;
        })
        .sort((a, b) => {
          // Primary sort by frequency
          if (b.frequency !== a.frequency) {
            return b.frequency - a.frequency;
          }
          // Secondary sort by purchase rate
          if (b.purchaseRate !== a.purchaseRate) {
            return b.purchaseRate - a.purchaseRate;
          }
          // Tertiary sort by recency
          return new Date(b.lastAdded) - new Date(a.lastAdded);
        })
        .slice(0, 10);
      
      return popularItems;
    } catch (error) {
      console.error('Error getting popular registry items:', error);
      return [];
    }
  }
};

export default getFirebaseAnalyticsData; 
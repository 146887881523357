import React from 'react';
import { Platform } from 'react-native';
import { Helmet } from 'react-helmet';

const SEOHead = ({ 
  title,
  description,
  image,
  url,
  type = 'website',
  keywords = [],
  author = 'MyTassel',
  publishedDate,
  modifiedDate,
  canonicalUrl,
  locale = 'en_US',
  siteName = 'MyTassel'
}) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      
      {/* Open Graph / Facebook */}
      <meta property="og:type" content={type} />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {image && <meta property="og:image" content={image} />}
      
      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={url} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      {image && <meta property="twitter:image" content={image} />}
      
      {/* Canonical link */}
      <link rel="canonical" href={url} />
    </Helmet>
  );
};

export default SEOHead; 
import React from 'react';
import { StyleSheet } from 'react-native';

const UserList = ({ users }) => {
  const handleNavigateToPage = (user, pageType) => {
    if (!user.pages || (!user.pages.events && !user.pages.registry)) {
      alert('This user has no pages set up yet.');
      return;
    }

    let url;
    if (pageType === 'events' && user.pages.events) {
      url = user.pages.events;
    } else if (pageType === 'registry' && user.pages.registry) {
      url = user.pages.registry;
    } else {
      alert(`No ${pageType} page available for this user.`);
      return;
    }

    // Append UIC if it exists
    if (user.uic) {
      url += `?uic=${user.uic}`;
    }

    // Open in new tab
    window.open(url, '_blank');
  };

  return (
    <div style={styles.userListContainer}>
      <h2>Users</h2>
      <table style={styles.userTable}>
        <thead>
          <tr>
            <th>Email</th>
            <th>Created</th>
            <th>Last Sign In</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.uid}>
              <td>{user.email}</td>
              <td>{new Date(user.creationTime).toLocaleDateString()}</td>
              <td>{new Date(user.lastSignInTime).toLocaleDateString()}</td>
              <td>
                <button 
                  onClick={() => handleNavigateToPage(user, 'events')}
                  style={styles.pageButton}
                >
                  Events
                </button>
                <button 
                  onClick={() => handleNavigateToPage(user, 'registry')}
                  style={styles.pageButton}
                >
                  Registry
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const styles = StyleSheet.create({
  userListContainer: {
    padding: 20,
    backgroundColor: '#fff',
    borderRadius: 8,
    margin: 20,
  },
  userTable: {
    width: '100%',
    borderCollapse: 'collapse',
    marginTop: 20,
  },
  pageButton: {
    backgroundColor: '#4c956c',
    color: '#fff',
    border: 'none',
    padding: '8px 16px',
    borderRadius: 4,
    marginRight: 8,
    cursor: 'pointer',
    transition: 'background-color 0.2s',
  },
  '@media (hover: hover)': {
    pageButton: {
      '&:hover': {
        backgroundColor: '#3a7254',
      },
    },
  },
});

export default UserList; 
import React, { useState } from 'react';
import { View, Text, StyleSheet, Pressable, Animated, TextInput } from 'react-native';
import { useNavigation } from "@react-navigation/native";
import { getFirestore, doc, updateDoc } from "firebase/firestore";
import { useUser } from "./UserContext";

const SMSConsentBox = ({ checked, onCheck, style, phoneNumber, onPhoneNumberChange }) => {
  const navigation = useNavigation();
  const { currentUser, currentUserData, setCurrentUserData } = useUser();
  const [expanded, setExpanded] = useState(false);

  const handleConsentChange = async () => {
    if (currentUserData) {
      const db = getFirestore();
      const userRef = doc(db, "users", currentUserData.email);
      
      const optInData = {
        smsOptIn: !checked,
        smsOptInTimestamp: new Date().toISOString(),
        smsOptInMethod: 'registration_checkbox',
        smsOptInVersion: '1.0',
        smsOptInLocation: window.location.href,
        smsOptInDetails: {
          consentType: 'explicit',
          consentMethod: 'web_form_checkbox',
          consentUrl: window.location.href,
          consentLanguageVersion: '1.0',
          userAgent: window.navigator.userAgent,
          platform: Platform.OS
        }
      };

      await updateDoc(userRef, optInData);
      setCurrentUserData({ 
        ...currentUserData, 
        ...optInData
      });
    }
    
    if (onCheck) {
      onCheck();
    }
  };

  return (
    <View style={[styles.container, style]}>
      <View style={styles.checkboxContainer}>
        <Pressable 
          style={[
            styles.checkbox,
            checked && styles.checkboxChecked
          ]}
          onPress={handleConsentChange}
        >
          {checked && <Text style={styles.checkmark}>✓</Text>}
        </Pressable>
        <Text style={styles.consentText}>
          I agree to receive marketing and account notification messaging from MyTassel at the phone number provided below. I understand I may receive up to 2 messages a month, data rates may apply, reply STOP to opt out at any time.
        </Text>
      </View>

      {checked && (
        <View style={styles.phoneInputContainer}>
          <TextInput
            style={styles.phoneInput}
            placeholder="Your phone number"
            value={phoneNumber}
            onChangeText={onPhoneNumberChange}
            keyboardType="phone-pad"
          />
        </View>
      )}
      
      <Pressable onPress={() => setExpanded(!expanded)} style={styles.readMoreButton}>
        <Text style={styles.readMoreText}>
          {expanded ? 'Show Less' : 'Read More'}
        </Text>
      </Pressable>

      {expanded && (
        <View style={styles.expandedContent}>
          <View style={styles.bulletPoints}>
            <Text style={styles.bulletText}>Messages may include:</Text>
            <Text style={styles.bulletText}>• Graduation announcements & events</Text>
            <Text style={styles.bulletText}>• Registry updates & contributions</Text>
            <Text style={styles.bulletText}>• Account notifications</Text>
          </View>
          <Text style={styles.termsText}>
            Message & data rates may apply. Reply STOP to opt out or email info@mytassel.com to manage preferences. By checking this box, I confirm I am 13+ years old, own this phone number, and agree to receive automated messages.{' '}
            <Text style={styles.link} onPress={() => navigation.navigate("Privacy")}>Privacy Policy</Text> and{' '}
            <Text style={styles.link} onPress={() => navigation.navigate("Terms")}>Terms of Service</Text>
          </Text>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    padding: 15,
    backgroundColor: 'rgba(254, 253, 226, 0.1)',
    borderRadius: 10,
    marginVertical: 10,
    borderWidth: 1,
    borderColor: 'rgba(76, 149, 108, 0.2)',
  },
  checkboxContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: 10,
  },
  checkbox: {
    width: 20,
    height: 20,
    borderWidth: 2,
    borderColor: '#4c956c',
    borderRadius: 4,
    marginRight: 10,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    marginTop: 3,
  },
  checkboxChecked: {
    backgroundColor: '#4c956c',
  },
  checkmark: {
    color: '#fefde2',
    fontSize: 14,
    fontWeight: 'bold',
  },
  consentText: {
    flex: 1,
    fontFamily: 'Orpheus Pro',
    fontSize: 15,
    color: '#333',
    lineHeight: 20,
  },
  phoneInputContainer: {
    marginTop: 10,
    marginBottom: 5,
    width: '100%',
  },
  phoneInput: {
    width: '100%',
    height: 40,
    backgroundColor: '#fefde2',
    borderRadius: 8,
    paddingHorizontal: 12,
    fontFamily: 'Orpheus Pro',
    fontSize: 16,
    color: '#333',
    borderWidth: 1,
    borderColor: 'rgba(76, 149, 108, 0.2)',
  },
  readMoreButton: {
    alignSelf: 'flex-start',
    marginTop: 5,
    marginLeft: 30,
  },
  readMoreText: {
    color: '#4c956c',
    fontSize: 13,
    fontWeight: '500',
    textDecorationLine: 'underline',
  },
  expandedContent: {
    marginTop: 10,
    paddingTop: 10,
    borderTopWidth: 1,
    borderTopColor: 'rgba(76, 149, 108, 0.1)',
  },
  bulletPoints: {
    marginLeft: 30,
    marginVertical: 8,
  },
  bulletText: {
    fontFamily: 'Orpheus Pro',
    fontSize: 14,
    color: '#555',
    marginVertical: 2,
    lineHeight: 18,
  },
  termsText: {
    fontFamily: 'Orpheus Pro',
    fontSize: 13,
    color: '#666',
    marginTop: 8,
    lineHeight: 18,
    paddingHorizontal: 5,
  },
  link: {
    color: '#4c956c',
    textDecorationLine: 'underline',
    fontWeight: '500',
  },
});

export default SMSConsentBox; 
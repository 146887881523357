import React, { useState, useEffect } from "react";
import { View, Text, TextInput, ScrollView, StyleSheet, Pressable, Dimensions, Alert, Platform, ImageBackground, ActivityIndicator, KeyboardAvoidingView, Keyboard } from "react-native";
import { Picker } from '@react-native-picker/picker';
import HeaderBar from '../Widgets/HeaderBar';
import { REACT_APP_AWS_SERVER_URL } from '@env';
import { useNavigation } from '@react-navigation/native';
import { useUser } from "../Widgets/UserContext";
import SEOHead from '../components/SEOHead';

const { width, height } = Dimensions.get('window');
const isMobile = width < 768;
const isTablet = width >= 768 && width < 1024;
const isSmallDesktop = width >= 1024 && width <= 1440;
const isLargeDesktop = width > 1440;

const ContactUs = () => {
  const navigation = useNavigation();
  const { currentUser, currentUserData } = useUser();
  
  // States for form fields
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    inquiryType: 'general',
    message: '',
    devEmailDestination: ''
  });
  
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [keyboardVisible, setKeyboardVisible] = useState(false);
  const [showDevOptions, setShowDevOptions] = useState(false);

  // Server URL setup
  let LOCAL_SERVER_URL = "http://localhost:3300";
  let AWS_SERVER_URL = REACT_APP_AWS_SERVER_URL;
  const isDevelopment = process.env.NODE_ENV === "development";
  const SERVER_URL = isDevelopment ? LOCAL_SERVER_URL : AWS_SERVER_URL;

  useEffect(() => {
    // Pre-fill user data if available
    if (currentUserData) {
      setFormData(prev => ({
        ...prev,
        name: currentUserData.firstName ? `${currentUserData.firstName} ${currentUserData.lastName || ''}` : '',
        email: currentUserData.email || ''
      }));
    }
    
    // Set development mode
    setShowDevOptions(isDevelopment);
    
    // Handle keyboard visibility for mobile
    if (Platform.OS !== 'web') {
      const keyboardDidShowListener = Keyboard.addListener(
        'keyboardDidShow',
        () => setKeyboardVisible(true)
      );
      const keyboardDidHideListener = Keyboard.addListener(
        'keyboardDidHide',
        () => setKeyboardVisible(false)
      );

      return () => {
        keyboardDidShowListener.remove();
        keyboardDidHideListener.remove();
      };
    }
  }, [currentUserData, isDevelopment]);

  const validate = () => {
    let tempErrors = {};
    
    if (!formData.name.trim()) tempErrors.name = 'Name is required';
    if (!formData.email.trim()) {
      tempErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      tempErrors.email = 'Email is invalid';
    }
    if (!formData.message.trim()) tempErrors.message = 'Message is required';
    
    // Validate dev email destination if provided
    if (showDevOptions && formData.devEmailDestination && !/\S+@\S+\.\S+/.test(formData.devEmailDestination)) {
      tempErrors.devEmailDestination = 'Invalid email format';
    }
    
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
    
    // Clear error when user starts typing
    if (errors[field]) {
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };

  const handleSubmit = async () => {
    if (!validate()) return;
    
    setIsSubmitting(true);
    
    try {
      const response = await fetch(`${SERVER_URL}/contact-us`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      
      const data = await response.json();
      
      if (response.ok) {
        setSubmitSuccess(true);
        setFormData({
          name: currentUserData ? `${currentUserData.firstName || ''} ${currentUserData.lastName || ''}` : '',
          email: currentUserData?.email || '',
          inquiryType: 'general',
          message: '',
          devEmailDestination: ''
        });
        
        if (data.isDevelopment) {
          console.log('Development mode: Email sent to', data.emailSentTo);
        }
      } else {
        Alert.alert('Error', data.error || 'Something went wrong. Please try again.');
      }
    } catch (error) {
      console.error('Contact form submission error:', error);
      Alert.alert('Error', 'Network error. Please check your connection and try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const resetForm = () => {
    setSubmitSuccess(false);
  };

  const inquiryTypes = [
    { label: 'General Question', value: 'general' },
    { label: 'Technical Support', value: 'technical' },
    { label: 'Feature Request', value: 'feature' },
    { label: 'Bug Report', value: 'bug' },
    { label: 'Feedback', value: 'feedback' },
    { label: 'Other', value: 'other' },
  ];

  return (
    <View style={styles.mainContainer}>
      <SEOHead
        title="Contact MyTassel | Reach Out to Our Support Team"
        description="Have questions about your graduation planning? Contact the MyTassel team for assistance with your graduation announcements, registry, or any other questions."
        image="[Your default MyTassel image URL]"
        url="https://mytassel.com/contact-us"
        type="website"
      />
      
      <ImageBackground 
        source={require("../assets/Landing Background.png")} 
        style={styles.backgroundImage}
        resizeMode="cover"
      >
        <HeaderBar />
        <KeyboardAvoidingView
          behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
          style={styles.keyboardAvoidingView}
          enabled={Platform.OS !== 'web'}
        >
          <ScrollView 
            contentContainerStyle={[
              styles.contentContainer, 
              isMobile && { paddingBottom: keyboardVisible ? 200 : 100 }
            ]}
          >
            <View style={styles.formContainer}>
              <Text style={styles.title}>Contact Us</Text>
              
              {submitSuccess ? (
                <View style={styles.successContainer}>
                  <Text style={styles.successTitle}>Thank You!</Text>
                  <Text style={styles.successText}>
                    Your message has been sent successfully. We'll get back to you as soon as possible.
                  </Text>
                  <Text style={styles.successText}>
                    A confirmation email has been sent to your email address.
                  </Text>
                  <Pressable
                    style={styles.submitButton}
                    onPress={resetForm}
                  >
                    <Text style={styles.submitButtonText}>Go Back</Text>
                  </Pressable>
                </View>
              ) : (
                <>
                  <Text style={styles.subtitle}>
                    Have a question or feedback? We'd love to hear from you. Fill out the form below and we'll get back to you as soon as possible.
                  </Text>
                  
                  <View style={styles.formField}>
                    <Text style={styles.label}>Your Name</Text>
                    <TextInput
                      style={[styles.input, errors.name && styles.inputError]}
                      value={formData.name}
                      onChangeText={(text) => handleChange('name', text)}
                      placeholder="Enter your name"
                      placeholderTextColor="#888"
                    />
                    {errors.name && <Text style={styles.errorText}>{errors.name}</Text>}
                  </View>
                  
                  <View style={styles.formField}>
                    <Text style={styles.label}>Email Address</Text>
                    <TextInput
                      style={[styles.input, errors.email && styles.inputError]}
                      value={formData.email}
                      onChangeText={(text) => handleChange('email', text)}
                      placeholder="Enter your email"
                      placeholderTextColor="#888"
                      keyboardType="email-address"
                      autoCapitalize="none"
                    />
                    {errors.email && <Text style={styles.errorText}>{errors.email}</Text>}
                  </View>
                  
                  <View style={styles.formField}>
                    <Text style={styles.label}>Inquiry Type</Text>
                    {Platform.OS === 'web' ? (
                      <View style={styles.selectContainer}>
                        <select
                          value={formData.inquiryType}
                          onChange={(e) => handleChange('inquiryType', e.target.value)}
                          style={styles.webSelect}
                        >
                          {inquiryTypes.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      </View>
                    ) : (
                      <Picker
                        selectedValue={formData.inquiryType}
                        onValueChange={(itemValue) => handleChange('inquiryType', itemValue)}
                        style={styles.picker}
                      >
                        {inquiryTypes.map((option) => (
                          <Picker.Item key={option.value} label={option.label} value={option.value} />
                        ))}
                      </Picker>
                    )}
                  </View>
                  
                  <View style={styles.formField}>
                    <Text style={styles.label}>Message</Text>
                    <TextInput
                      style={[styles.textArea, errors.message && styles.inputError]}
                      value={formData.message}
                      onChangeText={(text) => handleChange('message', text)}
                      placeholder="Type your message here..."
                      placeholderTextColor="#888"
                      multiline
                      numberOfLines={6}
                      textAlignVertical="top"
                    />
                    {errors.message && <Text style={styles.errorText}>{errors.message}</Text>}
                  </View>
                  
                  {showDevOptions && (
                    <View style={styles.formField}>
                      <Text style={[styles.label, styles.devLabel]}>Development Email Destination</Text>
                      <TextInput
                        style={[styles.input, errors.devEmailDestination && styles.inputError, styles.devInput]}
                        value={formData.devEmailDestination}
                        onChangeText={(text) => handleChange('devEmailDestination', text)}
                        placeholder="Send email to (development only)"
                        placeholderTextColor="#888"
                        keyboardType="email-address"
                        autoCapitalize="none"
                      />
                      {errors.devEmailDestination && (
                        <Text style={styles.errorText}>{errors.devEmailDestination}</Text>
                      )}
                      <Text style={styles.devHelpText}>
                        Leave blank to use the default destination (info@mytassel.com)
                      </Text>
                    </View>
                  )}
                  
                  <Pressable
                    style={[styles.submitButton, isSubmitting && styles.submitButtonDisabled]}
                    onPress={handleSubmit}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <ActivityIndicator color="#fefde2" size="small" />
                    ) : (
                      <Text style={styles.submitButtonText}>Submit</Text>
                    )}
                  </Pressable>
                  
                  <Text style={styles.contactInfo}>
                    You can also reach us directly at: info@mytassel.com
                  </Text>
                </>
              )}
            </View>
          </ScrollView>
        </KeyboardAvoidingView>
      </ImageBackground>
    </View>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: "#4c956c",
  },
  backgroundImage: {
    flex: 1,
    width: '100%',
    height: '100%',
  },
  keyboardAvoidingView: {
    flex: 1,
  },
  contentContainer: {
    flexGrow: 1,
    padding: isMobile ? 20 : 40,
    alignItems: "center",
    paddingTop: 20,
  },
  formContainer: {
    width: isMobile ? "100%" : isTablet ? "80%" : "60%",
    maxWidth: 800,
    backgroundColor: "#FEFCE0",
    borderRadius: 15,
    padding: isMobile ? 20 : 40,
    marginTop: 20,
    marginBottom: 50,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 5,
    elevation: 5,
  },
  title: {
    fontFamily: "Bodoni72Smallcaps",
    fontSize: isMobile ? 28 : 36,
    color: "#4c956c",
    textAlign: "center",
    marginBottom: 10,
  },
  subtitle: {
    fontFamily: "Orpheus Pro",
    fontSize: isMobile ? 16 : 18,
    color: "#555",
    textAlign: "center",
    marginBottom: 30,
  },
  formField: {
    marginBottom: 20,
  },
  label: {
    fontFamily: "Orpheus Pro",
    fontSize: 16,
    fontWeight: "bold",
    color: "#333",
    marginBottom: 8,
  },
  input: {
    height: 50,
    borderWidth: 1,
    borderColor: "#ddd",
    borderRadius: 8,
    paddingHorizontal: 15,
    backgroundColor: "#fff",
    fontFamily: "Orpheus Pro",
    fontSize: 16,
  },
  selectContainer: {
    borderWidth: 1,
    borderColor: "#ddd",
    borderRadius: 8,
    backgroundColor: "#fff",
    height: 50,
    justifyContent: "center",
  },
  webSelect: {
    width: "100%",
    height: "100%",
    border: "none",
    backgroundColor: "transparent",
    paddingLeft: 15,
    paddingRight: 15,
    fontFamily: "Orpheus Pro",
    fontSize: 16,
    color: "#333",
    outline: "none",
  },
  picker: {
    height: 50,
    backgroundColor: "#fff",
    borderWidth: 1,
    borderColor: "#ddd",
    borderRadius: 8,
  },
  textArea: {
    height: 150,
    borderWidth: 1,
    borderColor: "#ddd",
    borderRadius: 8,
    paddingHorizontal: 15,
    paddingTop: 15,
    backgroundColor: "#fff",
    fontFamily: "Orpheus Pro",
    fontSize: 16,
  },
  inputError: {
    borderColor: "#FF6B6B",
  },
  errorText: {
    color: "#FF6B6B",
    fontSize: 14,
    marginTop: 5,
    fontFamily: "Orpheus Pro",
  },
  submitButton: {
    backgroundColor: "#4c956c",
    height: 55,
    borderRadius: 30,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 10,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
    elevation: 3,
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
  submitButtonDisabled: {
    backgroundColor: "#9AC5E2",
  },
  submitButtonText: {
    fontFamily: "Orpheus Pro",
    fontSize: 18,
    fontWeight: "bold",
    color: "#fefde2",
    textShadowOffset: { width: 1, height: 1 },
    textShadowRadius: 2,
    textShadowColor: "rgba(0, 0, 0, 0.2)",
  },
  contactInfo: {
    fontFamily: "Orpheus Pro",
    fontSize: 16,
    color: "#555",
    textAlign: "center",
    marginTop: 30,
  },
  successContainer: {
    padding: 20,
    alignItems: "center",
  },
  successTitle: {
    fontFamily: "Bodoni72Smallcaps",
    fontSize: 24,
    color: "#4c956c",
    marginBottom: 15,
  },
  successText: {
    fontFamily: "Orpheus Pro",
    fontSize: 16,
    color: "#555",
    textAlign: "center",
    marginBottom: 15,
    lineHeight: 24,
  },
  devLabel: {
    color: "#3498db",
  },
  devInput: {
    borderColor: "#3498db",
    borderWidth: 1,
    borderStyle: "dashed",
    backgroundColor: "rgba(52, 152, 219, 0.05)",
  },
  devHelpText: {
    fontFamily: "Orpheus Pro",
    fontSize: 12,
    color: "#3498db",
    marginTop: 5,
    fontStyle: "italic",
  }
});

export default ContactUs; 
import React, { createContext, useState, useEffect, useContext, useRef } from "react";
import { View, Text, TextInput, ScrollView, ImageBackground, Animated, Alert, Modal, Button, Pressable, Image, TouchableOpacity, Dimensions } from "react-native";
import { getAuth, signInAnonymously, createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, signOut } from "firebase/auth";
import { getFirestore, doc, getDoc, setDoc, collection, getDocs, query, where } from "firebase/firestore";
import { app } from "../firebase";
import { useUser } from "../Widgets/UserContext";
import { useNavigation } from "@react-navigation/native";
import styles from "../Styles/HubStyles";
import Friends from "../Widgets/Friends";
import friendsIcon from "../assets/friends.png"; // Added import for friends icon
import friendsNotificationIcon from "../assets/friends-notification.png"; // Added import for notification icon
import PromptSignUp from "../Widgets/PromptSignUp";
import HeaderBar from "../Widgets/HeaderBar";
import AsyncStorage from '@react-native-async-storage/async-storage'; // Import AsyncStorage
import { TutorialPopup } from '../Screens/Helpers/Tutorials';
import { useTutorial } from '../Screens/Helpers/Tutorials'; // Ensure this import is present
import { REACT_APP_AWS_SERVER_URL } from '@env';	
import SEOHead from '../components/SEOHead';
import { useButtonAnimation } from '../Animations/animations';

const { width, height } = Dimensions.get('window');
const isMobile = width < 768;
const isTablet = width >= 768 && width < 1024;
const isSmallDesktop = width >= 1024 && width < 1440;
const isLargeDesktop = width >= 1440;

let LOCAL_SERVER_URL = "http://localhost:3300";
let AWS_SERVER_URL = REACT_APP_AWS_SERVER_URL;

const isDevelopment = process.env.NODE_ENV === "development";
const SERVER_URL = isDevelopment ? LOCAL_SERVER_URL : AWS_SERVER_URL;

const fontFamilies = {
	Bodoni72Smallcaps: "Bodoni72Smallcaps",
};

const Hub = ({ route }) => {
	const { showRegistryModal } = route.params || {};
	const navigation = useNavigation();
	const { 
		setShowTutorial, 
		setActivePopupId, 
		showTutorial, 
		isLoading,
		tutorialsToShow,
		setClosedTutorials,
		setTutorialsToShow,
		tutorialSteps,
	} = useTutorial();
	// Initialize Firestore
	const db = getFirestore(app);
	const { currentUser, currentUserData, setCurrentUser, setCurrentUserData } = useUser();
	const [showFriends, setShowFriends] = useState(false);
	const auth = getAuth(app);

	let username;

	if (currentUserData) {
		// console.warn("currentUserData:", currentUserData);
		username = currentUserData.uid;
	}

	const [showLogoutModal, setShowLogoutModal] = React.useState(false);
	const [showEventPagePrompt, setShowEventPagePrompt] = useState(false);
	const [showRegistryPagePrompt, setShowRegistryPagePrompt] = useState(false);
	const [isDaytime, setIsDaytime] = useState(true);
	const [showNewAccountPopup, setShowNewAccountPopup] = useState(false);
	const [pageUpdated, setPageUpdated] = useState(false);
	const [friendRequests, setFriendRequests] = useState([])
	const [showPromptSignUp, setShowPromptSignUp] = useState(false);
	const [hoveredButtons, setHoveredButtons] = useState({});
	const [buttonAnimations, setButtonAnimations] = useState({});

	// State for resources overlay on mobile
	const [showResourcesOverlay, setShowResourcesOverlay] = useState(false);

	// Animation value for overlay
	const overlayAnim = useRef(new Animated.Value(0)).current;

	// Animate overlay in when it becomes visible
	useEffect(() => {
		if (showResourcesOverlay) {
			Animated.timing(overlayAnim, {
				toValue: 1,
				duration: 300,
				useNativeDriver: true,
			}).start();
		}
	}, [showResourcesOverlay]);

	const getButtonAnimation = (buttonId) => {
		if (!buttonAnimations[buttonId]) {
			const newAnimation = useButtonAnimation();
			setButtonAnimations(prev => ({
				...prev,
				[buttonId]: newAnimation
			}));
			return newAnimation;
		}
		return buttonAnimations[buttonId];
	};

	const buttonStyle = (buttonId) => {
		const animation = getButtonAnimation(buttonId);
		return [
			{
				backgroundColor: "#4c956c",
				borderRadius: 40,
				alignItems: "center",
				justifyContent: "center",
				width: isMobile ? 284 : isTablet ? 284 : isSmallDesktop ? 300 : 400,
				height: isMobile ? 55 : isTablet ? 55 : isSmallDesktop ? 60 : 75,
				marginBottom: isMobile ? 20 : 0,
				shadowColor: "#000",
				shadowOffset: {
					width: 0,
					height: 4,
				},
				shadowOpacity: 0.3,
				shadowRadius: 8,
				elevation: 8,
			},
			animation.buttonStyle
		];
	};

	// #region - Testing Login
	useEffect(() => {
		if (isDevelopment) {
			const testLogin = async () => {
				try {
					const testEmails = [
						"Tjhawk27@gmail.com", // Trevor
						"trevorhawkinsofficial@gmail.com", // Olivia Nichols
						"trevorhawkins@makenoisemedia.io", // Sam Minix
						"info@dsignable.com" // Tristan
					];
					const password = "Newtjhawk27";
					const lowerCaseEmail = testEmails[3].toLowerCase(); // Default to Jennifer

					const userCredential = await signInWithEmailAndPassword(auth, lowerCaseEmail, password);
					const user = userCredential.user;

					const userDocRef = doc(db, "users", lowerCaseEmail);
					const userDocSnap = await getDoc(userDocRef);

					if (userDocSnap.exists()) {
						const userData = userDocSnap.data();
						setCurrentUser(user); // Set the auth user
						setCurrentUserData(userData); // Set the Firestore user data
					} else {
						console.error("No such user data!");
					}
				} catch (error) {
					console.error("Error logging in with test account", error);
				}
			};
			// testLogin();
		}
	}, []);
	// #endregion

	const navigateToChecklist = (type) => {
		navigation.navigate("Checklist", { type });
	};

	//#region - General Functions
	const handleLinkPress = (pageName, params = {}) => {
		// console.log(`Navigating to ${pageName}`);
		if (pageName === "Registry") {
			// Updated to navigate without query parameter
			navigation.navigate("Registry", { username: params.username ? params.username : currentUserData.uid });
		} else {
			navigation.navigate(pageName, params);
		}
	};

	// Get Current Time
	useEffect(() => {
		const currentHour = new Date().getHours();
		setIsDaytime(currentHour >= 5 && currentHour < 17);
	}, []);

	// Show new account popup
	useEffect(() => {
		const checkNewAccountPopup = async () => {
			try {
				// console.log("currentUser:", currentUser);

				if (currentUser && !currentUser.isAnonymous) {
					const userDocRef = doc(db, "users", currentUser.email);
					const userDocSnap = await getDoc(userDocRef);

					if (userDocSnap.exists()) {
						const userData = userDocSnap.data();
						// console.log("Fetched userData:", userData);

						if (userData.newAccount) {
							if (userData.pages?.events || userData.pages?.registry) {
								// Update newAccount to false since they already have pages
								await setDoc(userDocRef, { newAccount: false }, { merge: true });
								setCurrentUserData({ ...userData, newAccount: false });
								return;
							}
							setTimeout(() => {
								setShowNewAccountPopup(true);
							}, 1000); // Show the popup after 1 second
						} else {
							// console.log("Not showing new account popup");
							setShowNewAccountPopup(false); // Ensure popup is not shown otherwise
						}
					} else {
						console.error("No such user data!");
					}
				}
			} catch (error) {
				console.error("Error checking new account popup:", error);
			}
		};
		checkNewAccountPopup();
	}, [currentUser]);

	// Inside your component or function where you call generateUniqueUid
	useEffect(() => {
		if (currentUserData?.newAccount) {
			if (currentUserData.pages?.events || currentUserData.pages?.registry) {
				// console.log("Pages already exist, skipping uid generation");
				return;
			}
			generateUniqueUid(db)
				.then((uid) => {
					// console.log("Unique UID generated:", uid);
					// Additional logic to handle the newly generated UID
				})
				.catch((error) => {
					console.error("Error generating unique UID:", error);
				});
		} else if (currentUserData) {
			// sendGenericWelcomeEmail(currentUserData);
			// sendGraduateWelcomeEmail(currentUserData);
		}
	}, [currentUserData, db, pageUpdated]); // Ensure 'db' is included if it's a dependency

	// Then, when calling generateUniqueUid, pass db explicitly
	const handleUserResponseYes = async () => {
		try {
			if (currentUser) {
				// console.log("User responded YES:", currentUser.email);
				const uniqueUid = await generateUniqueUid(db);
				const userDocRef = doc(db, "users", currentUser.email);

				// Log the data being set
				// console.log("Setting Firestore document with data:", {
				// 	accountType: "Graduate",
				// 	uid: uniqueUid,
				// 	newAccount: false,
				// 	isFriendsListPrivate: true
				// });

				await setDoc(userDocRef, {
					accountType: "Graduate",
					uid: uniqueUid,
					newAccount: false,
					isFriendsListPrivate: true // Ensure this is set to true
				}, { merge: true });

				// Fetch the updated user data
				const userDocSnap = await getDoc(userDocRef);
				if (userDocSnap.exists()) {
					const updatedData = userDocSnap.data();
					// console.log("Updated Firestore document data:", updatedData);
					setCurrentUserData(updatedData);
				} else {
					console.error("Document does not exist after update.");
				}

				alert("Your account has been updated. You can change this at any time in the profile menu.");
			}

			setShowNewAccountPopup(false);
			setPageUpdated(true);
			sendGraduateWelcomeEmail(currentUserData);
		} catch (error) {
			console.error("Error handling user response YES:", error);
		}
	};

	const sendGraduateWelcomeEmail = async (currentUserData) => {
		let userEmail = currentUserData.email;
		let firstName = currentUserData.firstName;
		try {
			const response = await fetch(`${SERVER_URL}/send-graduate-welcome-email`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ userEmail, firstName }),
			});

			const responseData = await response.text(); // or .json() if your server responds with JSON
			// console.log("Welcome email response:", responseData);
		} catch (error) {
			console.error("Error sending welcome email:", error);
		}
	};


	const sendGenericWelcomeEmail = async (currentUserData) => {
		let userEmail = currentUserData.email;
		let firstName = currentUserData.firstName;
		try {
			const response = await fetch(`${SERVER_URL}/send-generic-welcome-email`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ userEmail, firstName }),
			});

			const responseData = await response.text(); // or .json() if your server responds with JSON
			// console.log("Welcome email response:", responseData);
		} catch (error) {
			console.error("Error sending welcome email:", error);
		}
	};

	const handleUserResponseNo = async () => {
		try {
			if (currentUser) {
				// console.log("User responded NO:", currentUser.email);
				const userDocRef = doc(db, "users", currentUser.email);
				await setDoc(userDocRef, { newAccount: false }, { merge: true });

				// Fetch the updated user data
				const userDocSnap = await getDoc(userDocRef);
				if (userDocSnap.exists()) {
					const updatedData = userDocSnap.data();
					setCurrentUserData(updatedData);
				}

				alert("You can change your account details at any time in the profile menu.");
			}

			setShowNewAccountPopup(false);
			sendGenericWelcomeEmail(currentUserData);
		} catch (error) {
			console.error("Error handling user response NO:", error);
		}
	};



	// Your generateUniqueUid function remains the same, just ensure db is passed correctly
	const generateUniqueUid = async (db) => {
		let isUnique = false;
		let uid;

		while (!isUnique) {
			// Generate a random 5-digit number and prepend 'G'
			uid =
				"G" +
				Math.floor(Math.random() * 100000)
					.toString()
					.padStart(5, "0");

			// Attempt to fetch documents from the 'users' collection where 'uid' matches the generated uid
			const q = query(collection(db, "users"), where("uid", "==", uid));
			const querySnapshot = await getDocs(q);

			// Check if the generated uid is unique
			isUnique = querySnapshot.empty;
		}

		// console.log("Generated unique UID:", uid);
		return uid;
	};

	// Determine greeting based on current time
	const getGreeting = () => {
		const currentHour = new Date().getHours();
		if (currentHour < 12) {
			return "Good Morning";
		} else if (currentHour < 18) {
			return "Good Afternoon";
		} else {
			return "Good Evening";
		}
	};

	const handleLogout = async () => {
		try {
			await signOut(auth);
			setCurrentUser(null);
			setCurrentUserData(null);
			navigation.navigate("My Tassel - Welcome");
		} catch (error) {
			console.error("Error during logout:", error);
		}
	};

	// Function to toggle between Login and Logout button based on currentUserData state
	const renderLoginOrLogoutButton = () => {
		if (currentUserData) {
			return (
				<Pressable style={styles.logoutButton} onPress={() => setShowLogoutModal(true)}>
					<Text style={styles.loginButtonText}>Logout</Text>
				</Pressable>
			);
		} else {
			return (
				<Pressable style={styles.loginButton} onPress={() => navigation.navigate("My Tassel - Welcome", { popupType: "Login" })}>
					<Text style={styles.loginButtonText}>Login</Text>
				</Pressable>
			);
		}
	};
	//#endregion

	//#region - Event Page Creation
	const checkAndPromptEventPageCreation = async () => {
		if (currentUser && currentUserData && currentUserData.uid.startsWith("G")) {
			const userDocRef = doc(db, "users", currentUserData.email);
			const docSnap = await getDoc(userDocRef);

			if (docSnap.exists()) {
				const userData = docSnap.data();
				const eventsPageUrl = userData.pages?.events;

				if (eventsPageUrl) {
					// If an events page URL exists, navigate to it
					navigation.navigate("Announcements", { username: currentUserData.uid });
				} else {
					promptCreateEventPage();
				}
			} else {
				alert("User data not found.");
			}
		} else {
			alert("Please set your account type to 'Graduate' in the Profile menu before attempting to create an Announcements page");
		}
	};

	const promptCreateEventPage = () => {
		setShowEventPagePrompt(true);
	};

	const createEventPage = async () => {
		const eventsPageUrl = `https://mytassel.com/events/${currentUserData.uid}`;
		const userDocRef = doc(db, "users", currentUserData.email);

		await setDoc(userDocRef, { pages: { events: eventsPageUrl } }, { merge: true });
		setShowEventPagePrompt(false);
		// Navigate to the new event page
		navigation.navigate("Announcements", { username: currentUserData.uid });
	};

	//#endregion

	//#region - Registry Page Creation
	const checkAndPromptRegistryPageCreation = async () => {
		if (currentUser && currentUserData && currentUserData.uid.startsWith("G")) {
			const userDocRef = doc(db, "users", currentUserData.email);
			const docSnap = await getDoc(userDocRef);

			if (docSnap.exists()) {
				const userData = docSnap.data();
				const registryPageUrl = userData.pages?.registry;

				if (registryPageUrl) {
					// If a registry page URL exists, navigate to it
					handleLinkPress("Registry", { username: currentUserData.uid });
				} else {
					promptCreateRegistryPage();
				}
			} else {
				alert("User data not found.");
			}
		} else {
			alert("Please set your account type to 'Graduate' in the Profile menu before attempting to create a Registry page");
		}
	};

	const promptCreateRegistryPage = () => {
		setShowRegistryPagePrompt(true);
	};

	// Function to actually create the registry page
	const createRegistryPage = async () => {
		if (!currentUserData) return; // Ensure there's current currentUserData data

		const registryPageUrl = `https://mytassel.com/registry/${currentUserData.uid}`;
		const userDocRef = doc(db, "users", currentUserData.email);

		try {
			// Update Firestore with the new registry page URL
			await setDoc(userDocRef, { pages: { registry: registryPageUrl } }, { merge: true });

			// Close the prompt
			setShowRegistryPagePrompt(false);

			// Navigate to the newly created registry page
			handleLinkPress("Registry", { username: currentUserData.uid });
		} catch (error) {
			console.error("Failed to create registry page:", error);
			alert("An error occurred while creating the registry page. Please try again.");
		}
	};
	//#endregion

	// #region - Resources
		const slideAnimDorm = React.useRef(new Animated.Value(-100)).current;
	const slideAnimApartment = React.useRef(new Animated.Value(-200)).current;
	const slideAnimGraduation = React.useRef(new Animated.Value(-300)).current;
	const slideAnimCatalogue = React.useRef(new Animated.Value(-400)).current;
	const slideAnimPrivacy = React.useRef(new Animated.Value(-400)).current;
	const slideAnimTerms = React.useRef(new Animated.Value(-400)).current;
	const slideAnimBlog = React.useRef(new Animated.Value(-400)).current;
	const slideAnimTutorials = React.useRef(new Animated.Value(-400)).current;
	const slideAnimWhyMyTassel = React.useRef(new Animated.Value(-400)).current;
	const slideAnimChecklist = React.useRef(new Animated.Value(-400)).current;
	const slideAnimContactUs = React.useRef(new Animated.Value(-400)).current;

	const slideIn = () => {
		Animated.timing(slideAnimWhyMyTassel, {
			toValue: 300,
			duration: 500,
			useNativeDriver: true,
		}).start();
		Animated.timing(slideAnimTutorials, {
			toValue: 250,
			duration: 500,
			useNativeDriver: true,
		}).start();
		Animated.timing(slideAnimBlog, {
			toValue: 200,
			duration: 500,
			useNativeDriver: true,
		}).start();
		Animated.timing(slideAnimChecklist, {
			toValue: 150,
			duration: 500,
			useNativeDriver: true,
		}).start();
		Animated.timing(slideAnimCatalogue, {
			toValue: 100,
			duration: 500,
			useNativeDriver: true,
		}).start();
		Animated.timing(slideAnimPrivacy, {
			toValue: 50,
			duration: 500,
			useNativeDriver: true,
		}).start();
		Animated.timing(slideAnimTerms, {
			toValue: 0,
			duration: 500,
			useNativeDriver: true,
		}).start();
		Animated.timing(slideAnimContactUs, {
			toValue: -50,
			duration: 500,
			useNativeDriver: true,
		}).start();
	};

	const slideOut = () => {
		Animated.timing(slideAnimWhyMyTassel, {
			toValue: -400,
			duration: 500,
			useNativeDriver: true,
		}).start();
		Animated.timing(slideAnimDorm, {
			toValue: -100,
			duration: 500,
			useNativeDriver: true,
		}).start();
		Animated.timing(slideAnimApartment, {
			toValue: -200,
			duration: 500,
			useNativeDriver: true,
		}).start();
		Animated.timing(slideAnimChecklist, {
			toValue: -250,
			duration: 500,
			useNativeDriver: true,
		}).start();
		Animated.timing(slideAnimGraduation, {
			toValue: -300,
			duration: 500,
			useNativeDriver: true,
		}).start();
		Animated.timing(slideAnimCatalogue, {
			toValue: -400,
			duration: 500,
			useNativeDriver: true,
		}).start();
		Animated.timing(slideAnimPrivacy, {
			toValue: -400,
			duration: 500,
			useNativeDriver: true,
		}).start();
		Animated.timing(slideAnimTerms, {
			toValue: -400,
			duration: 500,
			useNativeDriver: true,
		}).start();
		Animated.timing(slideAnimBlog, {
			toValue: -400,
			duration: 500,
			useNativeDriver: true,
		}).start();
		Animated.timing(slideAnimTutorials, {
			toValue: -400,
			duration: 500,
			useNativeDriver: true,
		}).start();
		Animated.timing(slideAnimContactUs, {
			toValue: -400,
			duration: 500,
			useNativeDriver: true,
		}).start();
	};

	const navigateToCatalogue = () => {
		navigation.navigate("Catalogue", { isFromHub: true });
	};

	useEffect(() => {
		if (showRegistryModal) {
			setShowRegistryPagePrompt(true);
		}
	}, [showRegistryModal]);

	// Define the total number of tutorials on this page
	const totalTutorials = Object.keys(tutorialSteps).length;

	useEffect(() => {
		if (!isLoading && !showNewAccountPopup) {
			// Don't initialize tutorials on mobile
			if (!isMobile) {
				setShowTutorial(true);
				if (tutorialsToShow.includes('welcome')) {
					setActivePopupId("welcome");
				}
			}
		}
	}, [isLoading, showNewAccountPopup, tutorialsToShow]);

	useEffect(() => {
		const fetchCompletedTutorials = async () => {
			// Don't fetch tutorials on mobile
			if (isMobile || !currentUserData?.email) return;

			try {
				const userDoc = doc(db, "users", currentUserData.email);
				const userDocSnap = await getDoc(userDoc);
				if (userDocSnap.exists()) {
					const userData = userDocSnap.data();
					setClosedTutorials(userData.completedTutorials || {});
				}
			} catch (error) {
				console.error("Error fetching completed tutorials:", error);
			}
		};

		fetchCompletedTutorials();
	}, [currentUserData]);

	// Handle initial tutorial setup after loading
	useEffect(() => {
		if (!isLoading && !showNewAccountPopup && !isMobile) {
			setShowTutorial(true);
			setActivePopupId("welcome");
		}
	}, [isLoading, showNewAccountPopup]);

	// Create multiple animation values for different elements
	const fadeAnim = {
		welcome: useRef(new Animated.Value(0)).current,
		description: useRef(new Animated.Value(0)).current,
		buttons: useRef(new Animated.Value(0)).current,
		resources: useRef(new Animated.Value(0)).current
	};

	const slideAnim = {
		welcome: useRef(new Animated.Value(-50)).current,
		description: useRef(new Animated.Value(50)).current,
		buttons: useRef(new Animated.Value(100)).current,
		resources: useRef(new Animated.Value(200)).current
	};

	useEffect(() => {
		const animations = [
			// Welcome text animations (first)
			Animated.parallel([
				Animated.timing(fadeAnim.welcome, {
					toValue: 1,
					duration: 800,
					useNativeDriver: true,
				}),
				Animated.timing(slideAnim.welcome, {
					toValue: 0,
					duration: 800,
					useNativeDriver: true,
				}),
			]),
			// Description animations (second)
			Animated.parallel([
				Animated.timing(fadeAnim.description, {
					toValue: 1,
					duration: 800,
					useNativeDriver: true,
				}),
				Animated.timing(slideAnim.description, {
					toValue: 0,
					duration: 800,
					useNativeDriver: true,
				}),
			]),
			// Buttons animations (third)
			Animated.parallel([
				Animated.timing(fadeAnim.buttons, {
					toValue: 1,
					duration: 800,
					useNativeDriver: true,
				}),
				Animated.timing(slideAnim.buttons, {
					toValue: 0,
					duration: 800,
					useNativeDriver: true,
				}),
			]),
			// Resources animations (last)
			Animated.parallel([
				Animated.timing(fadeAnim.resources, {
					toValue: 1,
					duration: 800,
					useNativeDriver: true,
				}),
				Animated.timing(slideAnim.resources, {
					toValue: 0,
					duration: 800,
					useNativeDriver: true,
				}),
			]),
		];

		Animated.stagger(300, animations).start();
	}, []);

	// Toggle resources overlay for mobile
	const toggleResourcesOverlay = () => {
		if (isMobile) {
			if (!showResourcesOverlay) {
				setShowResourcesOverlay(true);
				// Animation will be handled in the useEffect
			} else {
				// Animate out before hiding
				Animated.timing(overlayAnim, {
					toValue: 0,
					duration: 300,
					useNativeDriver: true,
				}).start(() => {
					setShowResourcesOverlay(false);
					// Reset animation value for next time
					overlayAnim.setValue(0);
				});
			}
		}
	};

	// Separate loading state from render logic
	if (isLoading) {
		return (
			<View style={styles.body}>
				<HeaderBar />
				{/* You might want to add a loading indicator here */}
			</View>
		);
	}

	return (
		<>
			<SEOHead
				title="MyTassel Dashboard | Graduation Planning Made Simple"
				description="Access your graduation announcements, registry, and planning tools all in one place"
				image="[Your default MyTassel image URL]"
				url="https://mytassel.com/hub"
				type="website"
			/>
			<View style={styles.body}>
				<ImageBackground source={isDaytime ? require("../assets/Popup Background.png") : require("../assets/Hub Background.png")} style={styles.backgroundImage}>

					<PromptSignUp isVisible={showPromptSignUp} onClose={() => setShowPromptSignUp(false)} redirectPage="Profile" />

					<div style={{ ...styles.overlayBlur }} />
					{/* Header Bar */}
					<HeaderBar />


					<View style={styles.section}>
						{/* Welcome Text */}
						<TutorialPopup
								id="welcome"
								steps={tutorialSteps.welcome}
								position={{ top: isSmallDesktop ? 75 : 90, left: isSmallDesktop ? 200 : 250 }}
								title="Welcome"
								openDirection="bottomRight"
								totalTutorials={totalTutorials} // Pass the total number of tutorials
							/>
						<Animated.View 
							style={[
								styles.welcomeArea,
								{
									opacity: fadeAnim.welcome,
									transform: [{ translateX: slideAnim.welcome }]
								}
							]}
						>
							<Text style={styles.welcomeText}>
								{getGreeting()}
								{currentUserData ? ", " + currentUserData.firstName + "!" : "!"}
							</Text>
						</Animated.View>

						<Animated.View 
							style={[
								styles.descriptionArea,
								{
									opacity: fadeAnim.description,
									transform: [{ translateY: slideAnim.description }]
								}
							]}
						>
							<Text style={styles.descriptionText}>
								{currentUserData && currentUserData.accountType === "Graduate" ?
									`Welcome Graduate! \nGet started creating your personal registry or announcement page for friends and family to visit` :
									currentUserData && currentUserData.accountType === "Friends & Family" ?
										`Welcome to MyTassel! \nYou can visit your graduate's registry and announcement page and get updates about their events` :
										currentUserData && currentUserData.accountType === "admin" ?
											`Welcome Admin! \nManage users and oversee the platform.` :
											`Welcome to MyTassel! \nPlease sign up or log in to access all features.`}
							</Text>
						</Animated.View>


						<Animated.View 
							style={[
								styles.buttonsContainer,
								{
									opacity: fadeAnim.buttons,
									transform: [{ translateY: slideAnim.buttons }]
								}
							]}
						>
							<View style={styles.buttonArea}>
								<TutorialPopup
									id="profile"
									steps={tutorialSteps.profile}
									position={{ display: isMobile ? 'none' : 'block', top: isSmallDesktop ? -15 : -15, right: isSmallDesktop ? -15 : -15 }}
									title="Profile"
									openDirection="topLeft"
									totalTutorials={totalTutorials} // Pass the total number of tutorials
								/>
								<Animated.View style={buttonStyle('profile')}>
									<Pressable
										onPress={() => {
											if (currentUserData) {
												handleLinkPress("Profile");
											} else {
												setShowPromptSignUp(true);
											}
										}}
										onMouseEnter={() => buttonAnimations['profile']?.animateIn()}
										onMouseLeave={() => buttonAnimations['profile']?.animateOut()}
										style={{ width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}
									>
										<Text style={styles.buttonText}>My Profile</Text>
									</Pressable>
								</Animated.View>
								{!isMobile && (
									<Text style={styles.buttonDescription}>Create or edit your profile & preferences</Text>
								)}
							</View>

							{currentUserData?.accountType === "admin" ?
								<View style={styles.buttonArea}>
									<Pressable
										style={({ pressed }) => buttonStyle(pressed, 'admin')}
										onPress={() => navigation.navigate("Admin")}
										onMouseEnter={() => buttonAnimations['admin']?.animateIn()}
										onMouseLeave={() => buttonAnimations['admin']?.animateOut()}
									>
										<Text style={styles.buttonText}>Admin Page</Text>
									</Pressable>
									{!isMobile && (
										<Text style={styles.buttonDescription}>Manage users and oversee the platform</Text>
									)}
								</View>
								:
								<>
									<View style={styles.buttonArea}>
										<TutorialPopup
											id="announcement"
											steps={tutorialSteps.announcement}
											position={{ display: isMobile ? 'none' : 'block', top: isSmallDesktop ? -15 : -15, right: isSmallDesktop ? -15 : -15 }}
											title="Announcement"
											openDirection="topLeft"
											totalTutorials={totalTutorials} // Pass the total number of tutorials
										/>
										<Animated.View style={buttonStyle('announcement')}>
											<Pressable
												onPress={checkAndPromptEventPageCreation}
												onMouseEnter={() => buttonAnimations['announcement']?.animateIn()}
												onMouseLeave={() => buttonAnimations['announcement']?.animateOut()}
												style={{ width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}
											>
												<Text style={styles.buttonText}>My Announcement</Text>
											</Pressable>
										</Animated.View>
										{!isMobile && (
											<Text style={styles.buttonDescription}>Create or edit your announcement page, add events, & invite friends & family</Text>
										)}
									</View>

									<View style={styles.buttonArea}>
										<TutorialPopup
											id="registry"
											steps={tutorialSteps.registry}
											position={{ display: isMobile ? 'none' : 'block', top: isSmallDesktop ? -15 : -15, right: isSmallDesktop ? -15 : -15 }}
											title="Registry"
											openDirection="topLeft"
											totalTutorials={totalTutorials} // Pass the total number of tutorials
										/>
										<Animated.View style={buttonStyle('registry')}>
											<Pressable
												onPress={checkAndPromptRegistryPageCreation}
												onMouseEnter={() => buttonAnimations['registry']?.animateIn()}
												onMouseLeave={() => buttonAnimations['registry']?.animateOut()}
												style={{ width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}
											>
												<Text style={styles.buttonText}>My Registry</Text>
											</Pressable>
										</Animated.View>
										{!isMobile && (
											<Text style={styles.buttonDescription}>Create or view your graduation registry</Text>
										)}
									</View>

									{/* <View style={styles.buttonArea}>
										<Pressable
											style={({ pressed }) => buttonStyle(pressed, 'blog')}
											onPress={() => navigation.navigate("Blog")}
											onMouseEnter={() => getButtonAnimation('blog').animateIn()}
											onMouseLeave={() => getButtonAnimation('blog').animateOut()}
										>
											<Text style={styles.buttonText}>Blog</Text>
										</Pressable>
										{!isMobile && (
											<Text style={styles.buttonDescription}>Read helpful articles and tips</Text>
										)}
									</View> */}
								</>
							}
						</Animated.View>
					</View>

					{/* Custom Modal for Event Page Creation */}
					<Modal animationType="slide" transparent={true} visible={showEventPagePrompt} onRequestClose={() => setShowEventPagePrompt(false)}>
						<View style={styles.centeredView}>
							<View style={styles.modalView}>
								<Text style={styles.modalText}>Create Announcement Page</Text>
								<Text>Would you like to create your announcement page?</Text>

								<Pressable style={[styles.button, styles.buttonOpen]} onPress={createEventPage}>
									<Text style={styles.textStyle}>Yes</Text>
								</Pressable>

								<Pressable style={[styles.button, styles.buttonClose]} onPress={() => setShowEventPagePrompt(false)}>
									<Text style={styles.textStyle}>No</Text>
								</Pressable>
							</View>
						</View>
					</Modal>

					{/* Custom Modal for Registry Page Creation */}
					<Modal animationType="slide" transparent={true} visible={showRegistryPagePrompt} onRequestClose={() => setShowRegistryPagePrompt(false)}>
						<View style={styles.centeredView}>
							<View style={styles.modalView}>
								<Text style={styles.modalText}>Create Registry</Text>
								<Text>Would you like to create your registry?</Text>

								<Pressable style={[styles.button, styles.buttonOpen]} onPress={createRegistryPage}>
									<Text style={styles.textStyle}>Yes</Text>
								</Pressable>

								<Pressable style={[styles.button, styles.buttonClose]} onPress={() => setShowRegistryPagePrompt(false)}>
									<Text style={styles.textStyle}>No</Text>
								</Pressable>
							</View>
						</View>
					</Modal>
					{/* New Account Modal */}
					<Modal style={styles.blurredContainer} animationType="slide" transparent={true} visible={showNewAccountPopup} onRequestClose={() => setShowNewAccountPopup(false)}>
						<View style={styles.centeredView}>
							<View style={styles.modalView}>
								<Text style={styles.modalText}>Welcome to My Tassel!</Text>
								<Text style={styles.modalText}>Do you need to create an announcement, event, and/or registry?</Text>
								<Pressable style={[styles.button, styles.buttonGreen]} onPress={handleUserResponseYes}>
									<Text style={styles.textStyle}>Yes</Text>
								</Pressable>
								<Pressable style={[styles.button, styles.buttonRed]} onPress={handleUserResponseNo}>
									<Text style={styles.textStyle}>No</Text>
								</Pressable>
							</View>
						</View>
					</Modal>

					{isMobile && showResourcesOverlay && (
						<Animated.View 
							style={[
								styles.resourcesOverlay,
								{
									transform: [{ 
										translateY: overlayAnim.interpolate({
											inputRange: [0, 1],
											outputRange: [height, 0]
										})
									}]
								}
							]}
						>
							<Pressable 
								style={styles.resourcesOverlayCloseButton}
								onPress={toggleResourcesOverlay}
							>
								<Text style={styles.resourcesOverlayCloseButtonText}>X</Text>
							</Pressable>
							<Animated.View 
								style={styles.resourcesOverlayContent}
							>
								<Text style={styles.resourcesOverlayTitle}>Resources</Text>
								
								<Pressable 
									style={styles.resourcesOverlayItem}
									onPress={() => {
										toggleResourcesOverlay();
										navigation.navigate("WhyMyTassel");
									}}
								>
									<Text style={styles.resourcesOverlayItemText}>Why Choose MyTassel?</Text>
								</Pressable>
								
								<Pressable 
									style={styles.resourcesOverlayItem}
									onPress={() => {
										toggleResourcesOverlay();
										navigation.navigate("Tutorials");
									}}
								>
									<Text style={styles.resourcesOverlayItemText}>Tutorials</Text>
								</Pressable>
								
								<Pressable 
									style={styles.resourcesOverlayItem}
									onPress={() => {
										toggleResourcesOverlay();
										navigation.navigate("Blog");
									}}
								>
									<Text style={styles.resourcesOverlayItemText}>Blog</Text>
								</Pressable>
								
								<Pressable 
									style={styles.resourcesOverlayItem}
									onPress={() => {
										toggleResourcesOverlay();
										navigation.navigate("Checklist", { type: "graduation" });
									}}
								>
									<Text style={styles.resourcesOverlayItemText}>Checklists</Text>
								</Pressable>
								
								<Pressable 
									style={styles.resourcesOverlayItem}
									onPress={() => {
										toggleResourcesOverlay();
										navigation.navigate("Catalogue");
									}}
								>
									<Text style={styles.resourcesOverlayItemText}>Catalogue</Text>
								</Pressable>
								
								<Pressable 
									style={styles.resourcesOverlayItem}
									onPress={() => {
										toggleResourcesOverlay();
										navigation.navigate("Privacy");
									}}
								>
									<Text style={styles.resourcesOverlayItemText}>Privacy Policy</Text>
								</Pressable>
								
								<Pressable 
									style={styles.resourcesOverlayItem}
									onPress={() => {
										toggleResourcesOverlay();
										navigation.navigate("Terms");
									}}
								>
									<Text style={styles.resourcesOverlayItemText}>Terms & Conditions</Text>
								</Pressable>
								
								<Pressable 
									style={styles.resourcesOverlayItem}
									onPress={() => {
										toggleResourcesOverlay();
										navigation.navigate("ContactUs");
									}}
								>
									<Text style={styles.resourcesOverlayItemText}>Contact Us</Text>
								</Pressable>
							</Animated.View>
						</Animated.View>
					)}

					<Animated.View 
						style={[
							styles.ribbonContainer,
							{
								opacity: fadeAnim.resources,
								transform: [{ translateY: slideAnim.resources }]
							}
						]}
						onMouseEnter={slideIn}
						onMouseLeave={slideOut}
						onPress={isMobile ? toggleResourcesOverlay : undefined}
					>
						<TutorialPopup
							id="resources"
							steps={tutorialSteps.resources}
							position={{
								top: isSmallDesktop ? -15 : -15,
								right: isSmallDesktop ? -25 : -20
							}}
							title="Resources"
							openDirection="topLeft"
						/>
						{isMobile ? (
							<Pressable onPress={toggleResourcesOverlay} style={{width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center'}}>
								<Text style={{ ...styles.ribbonText, fontSize: isMobile ? 24 : undefined }}>⋯</Text>
							</Pressable>
						) : (
							<>
								<Text style={{ ...styles.ribbonText, width: 100, zIndex: 1001 }}>Resources</Text>
								<Animated.View style={{ ...styles.ribbon1, bottom: slideAnimWhyMyTassel, zIndex: 9999 }}>
									<Pressable style={styles.ribbonButton} onPress={() => navigation.navigate("WhyMyTassel")}>
										<Text style={styles.ribbonText}>Why Choose MyTassel?</Text>
									</Pressable>
								</Animated.View>
								<Animated.View style={{ ...styles.ribbon1, bottom: slideAnimTutorials, zIndex: 9999 }}>
									<Pressable style={styles.ribbonButton} onPress={() => navigation.navigate("Tutorials")}>
										<Text style={styles.ribbonText}>Tutorials</Text>
									</Pressable>
								</Animated.View>
								<Animated.View style={{ ...styles.ribbon1, bottom: slideAnimBlog, zIndex: 9999 }}>
									<Pressable style={styles.ribbonButton} onPress={() => navigation.navigate("Blog")}>
										<Text style={styles.ribbonText}>Blog</Text>
									</Pressable>
								</Animated.View>
								<Animated.View style={{ ...styles.ribbon1, bottom: slideAnimChecklist, zIndex: 9999 }}>
									<Pressable style={styles.ribbonButton} onPress={() => navigation.navigate("Checklist", { type: "graduation" })}>
										<Text style={styles.ribbonText}>Checklists</Text>
									</Pressable>
								</Animated.View>
								<Animated.View style={{ ...styles.ribbon1, bottom: slideAnimCatalogue, zIndex: 9999 }}>
									<Pressable style={styles.ribbonButton} onPress={() => navigation.navigate("Catalogue")}>
										<Text style={styles.ribbonText}>Catalogue</Text>
									</Pressable>
								</Animated.View>

								<Animated.View style={{ ...styles.ribbon2, bottom: slideAnimPrivacy }}>
									<Pressable style={styles.ribbonButton} onPress={() => navigation.navigate("Privacy")}>
										<Text style={styles.ribbonText}>Privacy Policy</Text>
									</Pressable>
								</Animated.View>

								<Animated.View style={{ ...styles.ribbon6, zIndex: 1002, bottom: slideAnimTerms }}>
									<Pressable style={styles.ribbonButton} onPress={() => navigation.navigate("Terms")}>
										<Text style={{ ...styles.ribbonText, zIndex: 1002 }}>Terms & Conditions</Text>
									</Pressable>
								</Animated.View>

								<Animated.View style={{ ...styles.ribbon6, zIndex: 1002, bottom: slideAnimContactUs }}>
									<Pressable style={styles.ribbonButton} onPress={() => navigation.navigate("ContactUs")}>
										<Text style={{ ...styles.ribbonText, zIndex: 1002 }}>Contact Us</Text>
									</Pressable>
								</Animated.View>
							</>
						)}
					</Animated.View>
				</ImageBackground>
			</View>
		</>
	);
};

export default Hub;
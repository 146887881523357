import React from 'react';
import { View, Text, Pressable } from 'react-native';
import ReactDOM from 'react-dom';

const SharedModal = ({ isVisible, onClose, title, message, confirmText, onConfirm, cancelText = "Cancel" }) => {
  if (!isVisible) return null;

  // Create portal content
  const modalContent = (
    <div style={styles.modalOverlay}>
      <div style={styles.modalContent}>
        <div style={styles.modalTitle}>{title}</div>
        {typeof message === 'string' ? (
          <div style={styles.modalText}>{message}</div>
        ) : React.isValidElement(message) ? (
          <div style={styles.modalText}>
            <div style={{
              width: '100%',
              marginBottom: '15px',
            }}>
              {message}
            </div>
          </div>
        ) : (
          <div style={styles.modalText}>
            <div style={{
              width: '100%',
              marginBottom: '15px',
            }}>
              <input
                style={{
                  width: '100%',
                  padding: '12px',
                  marginBottom: '15px',
                  borderRadius: '8px',
                  border: '1px solid #ddd',
                  fontSize: '16px',
                  fontFamily: 'Orpheus Pro, serif',
                  backgroundColor: '#fff',
                }}
                placeholder="First Name"
                value={message.firstName || ''}
                onChange={(e) => message.onChangeText && message.onChangeText('firstName', e.target.value)}
              />
              <input
                style={{
                  width: '100%',
                  padding: '12px',
                  marginBottom: '15px',
                  borderRadius: '8px',
                  border: '1px solid #ddd',
                  fontSize: '16px',
                  fontFamily: 'Orpheus Pro, serif',
                  backgroundColor: '#fff',
                }}
                placeholder="Last Name"
                value={message.lastName || ''}
                onChange={(e) => message.onChangeText && message.onChangeText('lastName', e.target.value)}
              />
              <input
                style={{
                  width: '100%',
                  padding: '12px',
                  marginBottom: '15px',
                  borderRadius: '8px',
                  border: '1px solid #ddd',
                  fontSize: '16px',
                  fontFamily: 'Orpheus Pro, serif',
                  backgroundColor: '#fff',
                }}
                placeholder="Email"
                type="email"
                value={message.email || ''}
                onChange={(e) => message.onChangeText && message.onChangeText('email', e.target.value)}
              />
            </div>
          </div>
        )}
        <div style={styles.modalButtons}>
          <button 
            style={styles.modalButton} 
            onClick={onConfirm}
          >
            {confirmText}
          </button>
          <button 
            style={styles.modalButton} 
            onClick={onClose}
          >
            {cancelText}
          </button>
        </div>
      </div>
    </div>
  );

  // Use portal to render at root level
  return ReactDOM.createPortal(
    modalContent,
    document.body
  );
};

const styles = {
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    backdropFilter: 'blur(10px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 999999,
  },
  modalContent: {
    backgroundColor: '#fefde2',
    padding: '30px',
    borderRadius: '15px',
    maxWidth: '500px',
    width: '90%',
    textAlign: 'center',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    position: 'fixed',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  modalTitle: {
    color: '#4c956c',
    marginBottom: '20px',
    fontSize: '24px',
    fontWeight: 'bold',
    fontFamily: 'Orpheus Pro, serif',
  },
  modalText: {
    color: '#333',
    marginBottom: '25px',
    lineHeight: '1.5',
    fontSize: '16px',
  },
  modalButtons: {
    display: 'flex',
    justifyContent: 'center',
    gap: '15px',
  },
  modalButton: {
    padding: '12px 25px',
    borderRadius: '40px',
    cursor: 'pointer',
    backgroundColor: '#4c956c',
    border: 'none',
    minWidth: '120px',
    color: '#fefde2',
    fontSize: '16px',
    fontWeight: '500',
    fontFamily: 'Orpheus Pro, serif',
    transition: 'all 0.3s ease',
  },
};

export default SharedModal; 